import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  // language resources
  resources: {
    en: {
      translation: {
        publications: 'Publications',
        theses: 'Theses',
        partner: 'Partners',
        patent: 'Patent',

        headerContributions: 'Contributions',
        headerTheme: 'Theme',
        headerTitle:
          'A research and development project to boost competitiveness',
        homeTitleDescription:
          'The AIDA project aims to develop a combined approach to several AIs to inject them into the heart of operational systems, with complete confidence.',
        homeAIDAProject: 'The AIDA project',
        homeContentTitle1:
          'combines algorithmic research into the hybridisation of AI methods derived from knowledge-based symbolic AI and data-driven machine learning, and the development of modelling, decision automation, optimisation, explainability and security platforms to enhance the value of this research and increase confidence in AI.',
        homeContentTop1:
          'In 2018, the idea of creating a platform based on the integration of several Artificial Intelligences (AI) emerged, with the aim of helping companies improve their performance by integrating AI into their day-to-day operations. AI and automation were already priorities for companies looking to improve customer experience and grow their businesses.',
        homeContentTop2:
          "Against this backdrop, the AIDA project has been launched. Its aim is to develop a combined approach to securely integrating AI at the heart of companies' operational systems. By combining AI and automation, this project aims to enable companies to :",
        homeContentTop3:
          '1. Find new automation opportunities to increase productivity.',
        homeContentTop4:
          '2. Improve the efficiency of their automated systems, particularly in terms of control.',
        homeContentTop5:
          '3. Make the best possible decisions and recommendations.',
        homeContentTop6:
          'In this way, the AIDA project represents a response to market trends and the growing need for companies to use artificial intelligence and automation to remain competitive and innovative.',
        homeContentTop7:
          '*5 "formal" partners (IBM, Université Paris-Saclay, DecisionBrain, Softeam, STET), 5 UPS research labs: LISN (UPS/CNRS), L2S (Centrale-Supelec), MICS (Centrale-Supelec), ISP-CMLA (ENS), LIST (CEA)',
        homeContentTop8:
          '**filed patents',
        homeContentActorsTitle: 'The AIDA players',
        homeContentActorsNext: 'Next videos : ',
        homeContentActorsIBM:
          "For IBM France, which is playing the role of project leader, the AIDA project is a collective innovation generator in France, bringing together researchers, software specialists and business experts. Co-innovation projects are one of IBM France's preferred ways of building a relationship of mutual trust with its customers, and the AIDA project provides an opportunity to do so with partners of excellence and passion, on a larger scale, over a longer period of time, and of course on a particularly topical subject",

        homeContentBottomTitle:
          'Artificial intelligence (AI) has entered our daily lives and is having an impact on a growing number of activities.',
        homeContentBottomLeft:
          'The potential of its impact on corporate operational systems is, however, still far from being fully realized. Important questions remain unresolved today, which relate in particular to control and trust - including bias management; the suitability of technologies; flexibility and implementation methodology; the skills required... Injecting intelligence into the automation of corporate operations therefore remains a strategic imperative in order to reduce costs, improve performance and the use of resources, including human resources, and offer personalized, differentiating services in ever-increasing competition, particularly vis-à-vis new, purely digital players, with data and algorithms at the heart of their strategy. ',
        homeContentBottomRight1Title: 'Central objectif',
        homeContentBottomRight1:
          "The central objective and challenge of the AIDA project is the operationalization of AI, i.e. the implementation of artificial intelligence algorithms at the heart of companies' operational systems, which support their most critical business processes on a day-to-day basis. AIDA's ambition is to provide virtual employees endowed with Artificial Intelligence to assist each business operational in his functions",
        homeContentBottomRight2Title: 'Blocking points',
        homeContentBottomRight21:
          'There are currently scientific and technological barriers to the adoption of AI within companies, which mean that AI is disconnected from operations and difficult to implement in critical systems:',
        homeContentBottomRight22:
          "· There's a major gap between data scientists, who focus on data and the construction of machine learning and statistical models, and the operational staff in charge of transactional systems that support business functions",
        homeContentBottomRight23:
          '· The relatively short relevance of the data and the significant risk engendered by the use of models that are difficult to explain and dependent on the quality of the data',
        homeContentBottomRight24:
          '· The dynamics of the business and the agility companies need to adapt constantly to fluctuating market conditions make it difficult to use this technology, which is fundamentally based on using past data to anticipate the future',
        homeContentBottomRight25:
          '· AI is being adopted in pockets but without large-scale application, and most machine learning projects in operations focus only on one task or domain.',
        homeContentBottomRight3Title: 'Opportunities',
        homeContentBottomRight31:
          'The AIDA project aims to create an ecosystem conducive to the development of new opportunities for the consortium as a whole. These opportunities include strengthening the market leadership position of the industrial partners, advancing the research work of the Université Paris-Saclay in areas that are essential for businesses, creating jobs in research and development, and establishing IBM Research in France on the Saclay plateau.',
        homeContentBottomRight32:
          "In addition, this project will enable them to conquer new markets, increase their sales force, and position themselves internationally. Thanks to IBM's presence, partners will have the opportunity to compete with major international groups, thus promoting the growth of the ecosystem. By creating an open catalog of products and services for businesses, the partners will also contribute to the emergence of SMEs and start-ups in new markets, both nationally and internationally. This project heralds a promising future for the consortium as a whole, and its ability to innovate and prosper.",
        homeContentThemeTitle: 'AIDA themes',

        homeContentChallengeTitle: "AIDA challenges",

        theme1: 'Learning symbolic knowledge',
        theme2: 'Applying a trusted AI for all players',
        theme3: 'Supporting Hybrid Human and AI Organizations',
        theme4: 'Assisting with personal data management',
        theme5: 'Transparent decision-making',
        theme6: 'Securing AI',
        theme7: 'Explaining AI',
        theme8: 'Measuring organisations in real time',
        theme9: 'Optimising organisations',
        theme10: 'Protecting against fraud',

        more: 'Read more',
        join: 'Join the community',
        contact: 'Contacts :',

        nextTheme: 'Next theme',
        previousTheme: 'Previous theme',

        disclaimer:
          'This website is not a production version and is still under construction.',

        advances: 'AIDA advances',
        video: 'Video',
      },
    },
    fr: {
      translation: {
        publications: 'Publications',
        theses: 'Thèses',
        partner: 'Partenaires',
        patent: 'Brevets',

        headerContributions: 'Contributions',
        headerTheme: 'Thème',
        headerTitle:
          'Un projet de recherche et développement structurant pour la compétitivité',
        homeTitleDescription:
          'Le projet AIDA vise à développer une approche combinée de plusieurs IAs pour les "injecter" au cœur des systèmes opérationnels, en toute confiance.',
        homeAIDAProject: 'Le projet AIDA',
        homeContentTitle1:
          'combine des recherches algorithmiques en matière d’hybridation des méthodes d’IA issues de l’IA symbolique fondée sur les connaissances et l’apprentissage automatique exploitant les données, et le développement de plateformes de modélisation, d‘automatisation des decisions, d’optimisation, d’explicabilité, et de sécurisation permettant de valoriser ces recherches et d’augmenter la confiance dans l’IA.',
        homeContentTop1:
          "En 2018, l'idée de créer une plateforme basée sur l'intégration de plusieurs Intelligences Artificielles (IA) a émergé, avec pour objectif d'aider les entreprises à améliorer leur performance en intégrant l'IA dans leurs opérations quotidiennes.  l'IA et l'automatisation étaient déjà  des priorités pour les entreprises souhaitant améliorer l'expérience client et développer leurs activités.",
        homeContentTop2:
          "Dans ce contexte, le projet AIDA a été lancé. Son objectif est de développer une approche combinée visant à intégrer de manière sécurisée l'IA au cœur des systèmes opérationnels des entreprises. En combinant l'IA et l'automatisation, ce projet vise à permettre aux entreprises de :",
        homeContentTop3:
          "1. Trouver de nouvelles opportunités d'automatisation pour augmenter leur productivité.",
        homeContentTop4:
          "2. Améliorer l'efficacité de leurs systèmes automatisés, notamment en matière de contrôle.",
        homeContentTop5:
          '3. Prendre les meilleures décisions possible et formuler de meilleures recommandations.',
        homeContentTop6:
          "Ainsi, le projet AIDA représente une réponse à l'évolution du marché et aux besoins croissants des entreprises en matière d'intelligence artificielle et d'automatisation pour rester compétitives et innovantes.",
        homeContentTop7:
          '*5 partenaires “formels” (IBM, Université Paris-Saclay, DecisionBrain, Softeam, STET), 5 Laboratoires de Recherche de l’UPS LISN (UPS/CNRS), L2S (Centrale-Supelec), MICS (Centrale-Supelec), ISP-CMLA (ENS), LIST (CEA)',
        homeContentTop8:
          '**brevets déposés',

        homeContentActorsTitle: 'Les acteurs d’AIDA',
        homeContentActorsNext: 'Vidéos suivantes :',
        homeContentActorsIBM:
          "Pour IBM France, qui joue le rôle de chef de file du projet, le projet AIDA est un générateur d'innovations collectives en France rassemblant chercheurs, spécialistes du logiciel et experts métier. Les projets de co-innovation sont l’une des pratiques préférées d’IBM France pour construire une relation de confiance réciproque avec ses clients, et le projet AIDA donne l’occasion de le faire avec des partenaires d’excellence et passionnés, à plus grande échelle, sur une durée plus longue et bien entendu sur un sujet particulièrement actuel.",
        homeContentBottomTitle:
          "L'intelligence artificielle (IA) est entrée dans notre vie quotidienne et impacte un nombre croissant d'activités.",
        homeContentBottomLeft:
          "Le potentiel de son impact dans les systèmes opérationnels des entreprises est cependant encore loin d’être complètement réalisé. Des questions importantes ne sont pas résolues aujourd'hui. Elles concernent en particulier le contrôle et la confiance – y compris la gestion des biais ; l’adéquation des technologies ; la flexibilité et la méthodologie de mise en œuvre ; les compétences requises… L'injection d'intelligence dans l’automatisation des opérations des entreprises reste donc un impératif stratégique pour réduire les coûts, améliorer les performances et l’utilisation des ressources, y compris humaines, et offrir des services personnalisés et différenciants dans une compétition toujours plus grande, notamment vis-à-vis de nouveaux acteurs purement numériques, ayant les données et les algorithmes au cœur de leur stratégie.",
        homeContentBottomRight1Title: 'Objectif central',
        homeContentBottomRight1:
          "L'objectif central et l’enjeu du projet AIDA sont l’opérationnalisation de l'IA, c’est-à-dire la mise en œuvre des algorithmes d'intelligence artificielle au cœur des systèmes opérationnels des entreprises, qui soutiennent au jour le jour leurs processus métiers les plus critiques. L’ambition d’AIDA est de fournir des employés virtuels dotés d’Intelligence Artificielle à chaque opérationnel métier pour l’assister dans ses fonctions.",
        homeContentBottomRight2Title: 'Points bloquants',
        homeContentBottomRight21:
          'Il existe en effet aujourd’hui des verrous scientifiques et technologiques pour l’adoption de l’IA au sein des entreprises, qui font que l’IA est déconnectée des opérations et difficile à être mise en œuvre dans les systèmes critiques :',
        homeContentBottomRight22:
          "· Un fossé important existe entre, d’une part, les experts en mégadonnées (« data scientists ») centrés sur les données et la construction de modèles d'apprentissage (Machine Learning) et statistiques, et, d’autre part, les opérationnels en charge des systèmes transactionnels supportant les fonctions métier.",
        homeContentBottomRight23:
          "· La pertinence relativement courte des données et le risque important engendré par l'utilisation de modèles difficilement explicables et dépendant de la qualité des données.",
        homeContentBottomRight24:
          "· La dynamique du métier et l'agilité que doivent avoir les entreprises pour s'adapter constamment à des conditions de marché fluctuantes rendent difficile l'utilisation de cette technologie fondamentalement basée sur l'utilisation des données du passé pour anticiper l'avenir.",
        homeContentBottomRight25:
          '· L’IA est adoptée par poches mais sans application à grande échelle, et la plupart des projets d’apprentissage automatique dans le domaine des opérations se concentrent seulement sur une tâche ou domaine.',
        homeContentBottomRight3Title: 'Opportunités',
        homeContentBottomRight31:
          "Le projet AIDA vise à créer un écosystème propice au développement de nouvelles opportunités pour l'ensemble du consortium. Ces opportunités incluent le renforcement de la position de leadership des partenaires industriels sur le marché, l'avancement des travaux de recherche de l'Université Paris-Saclay dans des domaines essentiels pour les entreprises, la création d'emplois dans la recherche et le développement, et l'installation d'IBM Research en France sur le plateau de Saclay.",
        homeContentBottomRight32:
          "De plus, ce projet permettra de conquérir de nouveaux marchés, d'augmenter la force de vente, et de se positionner à l'international. Grâce à la présence d'IBM, les partenaires auront la possibilité de rivaliser avec de grands groupes internationaux, favorisant ainsi la croissance de l'écosystème. En créant un catalogue ouvert de produits et de services destinés aux entreprises, les partenaires contribueront également à l'émergence de PME et de start-ups sur de nouveaux marchés, tant au niveau national qu'international. Ce projet annonce un avenir prometteur pour l'ensemble du consortium et sa capacité à innover et à prospérer.",
        homeContentThemeTitle: "Les thèmes d'AIDA",

        homeContentChallengeTitle: "Les défis d'AIDA",

        theme1: 'Apprendre des connaissances symboliques',
        theme2: 'Appliquer une IA de confiance pour tous les acteurs',
        theme3: 'Accompagner les organisations hybrides humaines et IA',
        theme4: 'Assister la gestion des données personnelles',
        theme5: 'Décider en transparence',
        theme6: 'Sécuriser l’IA',
        theme7: 'Expliquer l’IA',
        theme8: 'Mesurer en temps réel les organisations',
        theme9: 'Optimiser les organisations',
        theme10: 'Protéger contre la fraude',

        more: 'En savoir plus',
        join: 'Rejoindre la communauté',
        contact: 'Contacts :',

        nextTheme: 'Thème suivant',
        previousTheme: 'Thème précédent',

        disclaimer:
          "Ce site web n'est pas encore la version finale, il est toujours en cours de construction.",

        advances: "Les avancées d'AIDA",
        video: 'Vidéo',
      },
    },
  },
});

export default i18n;