import styles from './Home.module.scss';
import {
  Theme,
  Grid,
  Column,
  Accordion,
  AccordionItem
} from '@carbon/react';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { persons_en, persons_fr } from '../../Data/person';

const Home = () => {
  const { t, i18n } = useTranslation();
  const persons = i18n.language === 'en' ? persons_en : persons_fr;
  const [currentPersonIndex, setCurrentPersonIndex] = useState(0);
  const [isVideoLoaded, setIsVideoLoaded] = useState(true); // Initialement true
  const actors_photos = [
    '/images/persons/bea.jpeg',
    '/images/persons/estelle_iocaona.jpeg',
    '/images/persons/Oliver_nicolas.jpeg',
    '/images/persons/Filippo-Focacci.png',
    '/images/persons/Rodolphe-Meyer.png',
  ];
  
  useEffect(() => {
    setIsVideoLoaded(true); // Mettre à jour l'état pour charger la vidéo
  }, [currentPersonIndex]);

  useEffect(() => {
    setIsVideoLoaded(true);
   }, [i18n.language]);

   //         <p className={styles.disclaimer}>{t('disclaimer')}</p>
   
  return (
    <div className={styles.home}>
      <Theme theme="g100">

        <Grid className={styles.title} fullWidth>
          <Grid className={styles.titleText} fullWidth>
            <Column lg={4} md={8} sm={4}>
              <h1>AI for Digital</h1>
              <h1>Automation</h1>
              <br />
              <p className={styles.textP}>{t('homeTitleDescription')}</p>
            </Column>
          </Grid>
        </Grid>
      </Theme>
      <Grid className={styles.topDescription} fullWidth>
        <Column lg={16} md={8} sm={4}>
          <h3>
            <span> {t('homeAIDAProject')} </span> {t('homeContentTitle1')}
          </h3>
        </Column>
      </Grid>
      <Grid className={styles.presentationParts} fullWidth>
        <Column lg={6} md={8} sm={4}>
          <p className={styles.textP}>{t('homeContentTop1')}</p>
          <br />
          <p>{t('homeContentTop2')}</p>
          <br />
          <p>{t('homeContentTop3')}</p>
          <p>{t('homeContentTop4')}</p>
          <p>{t('homeContentTop5')}</p>
          <br />
          <p>{t('homeContentTop6')}</p>
          <br />
          <p className={styles.legend}>{t('homeContentTop7')}</p>
          <p className={styles.legend}>{t('homeContentTop8')}</p>
        </Column>
        <Column lg={1} md={0} sm={0}></Column>
        <Column lg={2} md={2} sm={2} className={styles.flexBox}>
          <p className={styles.reallyBigBlueNumber}>40</p>
          <p className={styles.reallyBigBlueNumberLegend}>
            {t('publications')}
          </p>
        </Column>
        <Column lg={2} md={2} sm={2} className={styles.flexBox}>
          <p className={styles.reallyBigBlueNumber}>10</p>
          <p className={styles.reallyBigBlueNumberLegend}>{t('partner')}*</p>
        </Column>
        <Column lg={2} md={2} sm={2} className={styles.flexBox}>
          <p className={styles.reallyBigBlueNumber}>9</p>
          <p className={styles.reallyBigBlueNumberLegend}>{t('theses')}</p>
        </Column>
        <Column lg={2} md={2} sm={2} className={styles.flexBox}>
          <p className={styles.reallyBigBlueNumber}>3</p>
          <p className={styles.reallyBigBlueNumberLegend}>{t('patent')}**</p>
        </Column>
      </Grid>
      <Theme theme="g10" className={styles.actorsParts}>
        <Grid fullWidth>
          <Column lg={5} md={4} sm={4} className={styles.actors}>
            <div className={styles.grayText}>{t('homeContentActorsTitle')}</div>
            <h3>{persons[currentPersonIndex].name}</h3>
            <h4>
              <span>{persons[currentPersonIndex].title}</span>
            </h4>
            <br />
            <div className={styles.actorDescription}>
              {persons[currentPersonIndex].description.map((d, i) => (
                <p key={i}>{d}</p>
              ))}
            </div>
            <br />
            <div className={styles.grayText}>{t('homeContentActorsNext')}</div>
            <br />
            {actors_photos.map((p, i) => (
              <>
                <img
                  key={i}
                  src={p}
                  height="80px"
                  alt={p}
                  className={
                    i === currentPersonIndex
                      ? styles.imageSelected
                      : styles.imageNotSelected
                  }
                  onClick={() => setCurrentPersonIndex(i)}
                />
              </>
            ))}
          </Column>
          <Column lg={11} md={4} sm={4}>
            {isVideoLoaded && (
            <iframe
              src={persons[currentPersonIndex].videoLink}
              title="video"
              width="100%"
              height="100%"
              allow="fullscreen"
              autoPlay={false} 
            ></iframe>
            )}
          </Column>
        </Grid>
      </Theme>

      <Grid fullWidth className={styles.defis}>
        <Column lg={16} md={8} sm={4}>
          <h3>
          {t('homeContentChallengeTitle')}
          </h3>
          <br />
        </Column>

        <Column lg={8} md={4} sm={4}>
          <p>{t('homeContentBottomTitle')}</p>
          <br />
          <p>{t('homeContentBottomLeft')}</p>
          <br />
        </Column>
        <Column lg={8} md={4} sm={4}>
          <Accordion>
            <AccordionItem title={t('homeContentBottomRight1Title')}>
              <p>{t('homeContentBottomRight1')}</p>
            </AccordionItem>
            <AccordionItem title={t('homeContentBottomRight2Title')}>
              <p>{t('homeContentBottomRight21')}</p>
              <br />
              <ul className={styles.homeContentBottomRight}>
                <li>{t('homeContentBottomRight22')}</li>
                <br />
                <li>{t('homeContentBottomRight23')}</li>
                <br />
                <li>{t('homeContentBottomRight24')}</li>
                <br />
                <li>{t('homeContentBottomRight25')}</li>
              </ul>
            </AccordionItem>
            <AccordionItem title={t('homeContentBottomRight3Title')}>
              <p>{t('homeContentBottomRight31')}</p>
              <br />
              <p>{t('homeContentBottomRight32')}</p>
            </AccordionItem>
          </Accordion>
        </Column>
      </Grid>

      <Theme theme="g100">
        <Grid className={styles.challenges}>
          <Column lg={3} md={2} sm={2} className={styles.challengeBox}>
            <p className={styles.challengeBoxTitle}>{t('theme1')}</p>
            <div className={styles.challengeBoxBottom}>
              <p className={styles.blueNumber}>1</p>
              <a href="/theme1">
                <div className={styles.challengeBoxPlus}>
                  <p>{t('more')}</p>
                  <img
                    alt="arrow"
                    src="/images/icons/arrow.png"
                    className={styles.arrowIcon}
                  />
                </div>
              </a>
            </div>
          </Column>

          <Column lg={3} md={2} sm={2} className={styles.challengeBox}>
            <p className={styles.challengeBoxTitle}>{t('theme2')}</p>
            <div className={styles.challengeBoxBottom}>
              <p className={styles.blueNumber}>2</p>
              <a href="/theme2">
                <div className={styles.challengeBoxPlus}>
                  <p>{t('more')}</p>
                  <img
                    alt="arrow"
                    src="/images/icons/arrow.png"
                    className={styles.arrowIcon}
                  />
                </div>
              </a>
            </div>
          </Column>

          <Column lg={3} md={2} sm={2} className={styles.challengeBox}>
            <p className={styles.challengeBoxTitle}>{t('theme3')}</p>
            <div className={styles.challengeBoxBottom}>
              <p className={styles.blueNumber}>3</p>
              <a href="/theme3">
                <div className={styles.challengeBoxPlus}>
                  <p>{t('more')}</p>
                  <img
                    alt="arrow"
                    src="/images/icons/arrow.png"
                    className={styles.arrowIcon}
                  />
                </div>
              </a>
            </div>
          </Column>

          <Column lg={3} md={2} sm={2} className={styles.challengeBox}>
            <p className={styles.challengeBoxTitle}>{t('theme4')}</p>
            <div className={styles.challengeBoxBottom}>
              <p className={styles.blueNumber}>4</p>
              <a href="/theme4">
                <div className={styles.challengeBoxPlus}>
                  <p>{t('more')}</p>
                  <img
                    alt="arrow"
                    src="/images/icons/arrow.png"
                    className={styles.arrowIcon}
                  />
                </div>
              </a>
            </div>
          </Column>

          <Column lg={3} md={2} sm={2} className={styles.challengeBox}>
            <p className={styles.challengeBoxTitle}>{t('theme5')}</p>
            <div className={styles.challengeBoxBottom}>
              <p className={styles.blueNumber}>5</p>
              <a href="/theme5">
                <div className={styles.challengeBoxPlus}>
                  <p>{t('more')}</p>
                  <img
                    alt="arrow"
                    src="/images/icons/arrow.png"
                    className={styles.arrowIcon}
                  />
                </div>
              </a>
            </div>
          </Column>

          <Column lg={3} md={2} sm={2} className={styles.challengeBox}>
            <p className={styles.challengeBoxTitle}>{t('theme6')}</p>
            <div className={styles.challengeBoxBottom}>
              <p className={styles.blueNumber}>6</p>
              <a href="/theme6">
                <div className={styles.challengeBoxPlus}>
                  <p>{t('more')}</p>
                  <img
                    alt="arrow"
                    src="/images/icons/arrow.png"
                    className={styles.arrowIcon}
                  />
                </div>
              </a>
            </div>
          </Column>

          <Column lg={3} md={2} sm={2} className={styles.challengeBox}>
            <p className={styles.challengeBoxTitle}>{t('theme7')}</p>
            <div className={styles.challengeBoxBottom}>
              <p className={styles.blueNumber}>7</p>
              <a href="/theme7">
                <div className={styles.challengeBoxPlus}>
                  <p>{t('more')}</p>
                  <img
                    alt="arrow"
                    src="/images/icons/arrow.png"
                    className={styles.arrowIcon}
                  />
                </div>
              </a>
            </div>
          </Column>

          <Column lg={3} md={2} sm={2} className={styles.challengeBox}>
            <p className={styles.challengeBoxTitle}>{t('theme8')}</p>
            <div className={styles.challengeBoxBottom}>
              <p className={styles.blueNumber}>8</p>
              <a href="/theme8">
                <div className={styles.challengeBoxPlus}>
                  <p>{t('more')}</p>
                  <img
                    alt="arrow"
                    src="/images/icons/arrow.png"
                    className={styles.arrowIcon}
                  />
                </div>
              </a>
            </div>
          </Column>

          <Column lg={3} md={2} sm={2} className={styles.challengeBox}>
            <p className={styles.challengeBoxTitle}>{t('theme9')}</p>
            <div className={styles.challengeBoxBottom}>
              <p className={styles.blueNumber}>9</p>
              <a href="/theme9">
                <div className={styles.challengeBoxPlus}>
                  <p>{t('more')}</p>
                  <img
                    alt="arrow"
                    src="/images/icons/arrow.png"
                    className={styles.arrowIcon}
                  />
                </div>
              </a>
            </div>
          </Column>

          <Column lg={3} md={2} sm={2} className={styles.challengeBox}>
            <p className={styles.challengeBoxTitle}>{t('theme10')}</p>
            <div className={styles.challengeBoxBottom}>
              <p className={styles.blueNumber}>10</p>
              <a href="/theme10">
                <div className={styles.challengeBoxPlus}>
                  <p>{t('more')}</p>
                  <img
                    alt="arrow"
                    src="/images/icons/arrow.png"
                    className={styles.arrowIcon}
                  />
                </div>
              </a>
            </div>
          </Column>
        </Grid>
      </Theme>
    </div>
  );
};

export default Home;
