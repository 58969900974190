import styles from './Theme.module.scss';
import { Theme, Grid, Column, Link, Button, SideNav, SideNavItems, SideNavLink } from '@carbon/react';
import PoinIBM from '../../Components/PointIBM/PointIBM';
import { theme7_fr, theme7_en } from '../../Data/theme7';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';

const Theme7 = () => {
  const { t, i18n } = useTranslation();
  const theme = i18n.language === "en" ? theme7_en : theme7_fr;
  const [currentTitle, setCurrentTitle] = useState(0);
  const [sizeArray, setSizeArray] = useState([]);

  useEffect(() => {
    try {
      let tempSizeArray = [(document.getElementById('title-theme-7').clientHeight + document.getElementById('0').clientHeight)];

      theme.forEach((elem, index) => {
        tempSizeArray.push(tempSizeArray[index] + document.getElementById(index).clientHeight);
      })
      setSizeArray(tempSizeArray);

    } catch (error) {
      console.log(error);
    }
  }, [currentTitle, theme]);

  useEffect(() => {
    const handleScroll = (event) => {

      sizeArray.forEach((elem, index) => {
        if (index >= 1) {
          if ((sizeArray[index - 1] < window.scrollY) && (window.scrollY < elem)) {
            setCurrentTitle(index);
            return;
          }
        } else if (window.scrollY < elem) {
          setCurrentTitle(index);
          return;
        }
      })
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sizeArray, theme]);

  // For videos - Uncertainly useful:-)
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [i18n.language]); // Dependency array with language, changes when language changes

  // For videos - Works on theme change due to language switch
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [theme]);

  return (
    <div className={styles.theme}>
      {/* Bar en noire */}
      <Theme theme="g100">
        <Grid className={styles.title} fullWidth id="title-theme-7">
          <Column lg={5} md={8} sm={4}>
            <h1>{t('theme7')}</h1>
            <br />
          </Column>
          <Column lg={7} md={8} sm={4}>
            {i18n.language === 'en' ? (
              <p>
                In recent years, we have witnessed the emergence of new questions and concerns regarding AI-based systems. A new field known as "eXplainable AI (XAI)" has arisen with the mission of enlightening end-users about how these systems operate and providing answers to the "why" question. 
                Specifically, the DARPA, the originator of this buzzword, provides the following definition: "to provide users with explanations that allow them to understand the strengths and weaknesses of the system as a whole, to make them understand how it will behave in future or different situations, and perhaps allow users to correct errors in the system."
                Furthermore, the growing need to explain AI has also prompted governments to introduce new regulations. The most well-known is the General Data Protection Regulation (GDPR), which was introduced by the European Union in 2016 and has been enforced since 2018. 
                <br /><br />Since then, various works have been dedicated to analyzing this requirement from a legal perspective. Finally, even though we are witnessing a proliferation of works addressing this issue of explainability, especially in the field of Machine Learning, this question is not entirely new and dates back to expert systems. 
                Since then, numerous works have emerged. These works cover various questions, such as the generation of explanations and the identification of desirable characteristics of an explanation from the recipient's point of view.
                The theme of "Explaining AI" fits within the perspective of XAI and aims to meet the need for trust in AI systems and their results by constructing algorithms to automatically produce explanations. 
                <br /><br />In addition to innovation in the production of explained decisions, the originality of the work lies in the ambition to equip an AI system with multiple types of explanations, depending on the type of model used, namely:
                <br />- A system based on <span>optimization</span> models: Optimization models offer effective means and tools for finding solutions to decision problems, such as planning and scheduling. However, optimization solvers are often inexplicable black boxes whose solutions are inaccessible to users. To our knowledge, there is very little work in this field. The goal of this project is to advance the state of the art in the automatic construction of explanations for optimization models.
                <br />- A system based on <span>multicriteria</span> models: Multicriteria analysis places user preferences and judgments at the heart of reasoning. 
                It relies on a parametric family of functions that synthesize all preferences expressed on criteria (often conflicting) into a decision problem solution. These models are well-founded from the perspective of decision theory. 
                In this context, very few works have addressed the question of generating explanations for the results (recommendations) of a decision model, even though the multicriteria aspect of decision problems is widely recognized in real-world applications. In this context, the difficulty of generating explanations arises from the fact that different criteria are at play, and the user may not be able to fully evaluate their importance or understand how they interact.
                <br />- A system based on <span>Machine Learning</span> models: Machine Learning, or automatic learning, is based on statistical approaches whose objective is to "learn" predictive models from observed data. Many learning algorithms have been proposed in the literature, performing well in forecasting and capable of scaling to large amounts of heterogeneous data, with widely used open-source implementations. 
                <br /><br />However, these models are generally not inherently interpretable, nor are they accompanied by an easy way to explain the predictions they provide. 
                They are often presented as "black box" tools performing complex and non-intuitive operations on their inputs, which can be a problem in many applications where the explanation or justification of the model's decision can have greater added value than the decision itself. In the context of this project, we propose to go further and design solutions that allow for the interpretation of models, prediction by prediction, without compromising their performance.
              </p>
            ) : (
              <p>
              Ces dernières années, nous avons assisté à l'émergence de nouvelles questions et préoccupations concernant les systèmes basés sur l'IA. Un nouveau domaine sous le nom de  "<span>eXplainable AI</span> (XAI)"" a émergé avec la mission d'éclairer les utilisateurs finaux sur le fonctionnement de ces systèmes et de fournir des réponses à la question du "pourquoi". 
              Plus précisément, la DARPA, à l'origine de ce buzz word,  donne la définition suivante :   “fournir aux utilisateurs des explications qui leur permettent de comprendre l'ensemble des forces et des faiblesses du système, de faire comprendre comment il se comportera dans des situations futures ou différentes, et peut-être permettre aux utilisateurs de corriger les erreurs du système”.
              <br /><br />
              En outre, le besoin croissant d'expliquer l'IA a également incité les gouvernements à introduire de nouvelles réglementations. 
              La plus connue est le règlement général sur la protection des données (<span>RGPD</span>), qui a été introduit par l'Union européenne en 2016 et qui est appliqué depuis 2018. 
              <br /><br />Depuis lors, différents travaux ont été consacrés à l'analyse de cette exigence d'un point de vue juridique. Enfin, même si l'on assiste à une explosion des travaux s'intéressant à cette question de l'explicabilité, notamment dans le domaine du Machine Learning, cette question n'est pas entièrement nouvelle et remonte aux systèmes experts, et depuis lors, de nombreux travaux ont vu le jour. 
              Ces travaux portent sur diverses questions, telles que la génération d'explications; et l'identification des caractéristiques souhaitables d'une explication du point de vue de son destinataire.
              <br />
              <br />Le projet vise à renforcer la confiance dans l'IA en développant des algorithmes pour générer automatiquement des explications, adaptées à différents types de modèles : <span>optimisation</span> (pour des problèmes décisionnels), <span>multicritère</span> (intégrant les préférences des utilisateurs dans les décisions) et <span>Machine Learning</span> (pour interpréter des prédictions tout en maintenant les performances).
              </p>
          )}
          <br />
          {i18n.language === 'en' ? (
              <video
                src="https://aida-videos-challenges.s3.eu-de.cloud-object-storage.appdomain.cloud/Theme%207%20-%20Lot%20F%20-%20Challenge%20-%20expliquer%20lIA_720p_EN.mp4"
                title="video"
                width="100%"
                height="400px"
                allow="fullscreen"
                controls
              ></video>
              ) : (
              <video
                src="https://aida-videos-challenges.s3.eu-de.cloud-object-storage.appdomain.cloud/Theme%207%20-%20Lot%20F%20-%20Challenge%20-%20expliquer%20l'IA%20-%20720p.mp4"
                title="video"
                width="100%"
                height="400px"
                allow="fullscreen"
                controls
              ></video>
            )}
        </Column>
        <Column lg={3} md={7} sm={3}></Column>
        <Column lg={1} md={1} sm={1} className={styles.numberBox}>
          <div className={styles.blueNumber}>7</div>
        </Column>
      </Grid>
    </Theme>

    {/* Les avancées d'AIDA */}
    <Theme theme="white">
      <Grid className={styles.navigationBox} fullWidth>
        <Column lg={13} md={4} sm={4}>
          {theme.map((th, index) => (
            <div className={styles.avanceBox} id={index} key={index}>
              <Grid fullWidth>
                <Column lg={16} md={8} sm={4}>
                  <span>{t('advances')}</span>
                </Column>
              </Grid>
              <Grid fullWidth>
                <Column lg={3} md={2} sm={4}>
                  <h4>
                    {index + 1} - {th.title}
                  </h4>
                  <br />
                </Column>
                <Column lg={10} md={6} sm={4}>
                  {th.texte.map((texte, index2) => (
                    <p key={`p-text-${index2}`}>{texte}</p>
                  ))}
                  {th.demoTitle.length !== 0 ? (
                    <>
                      <br />
                      <h6>{th.demoTitle}</h6>
                      <p>
                        {th.demoDes}
                        <br />
                      </p>
                      <br />
                      <video
                          src={th.demoLink}
                          title="video"
                          width="100%"
                          height="400px"
                          allow="fullscreen"
                          controls
                        ></video>
                      <br />
                    </>
                  ) : (
                    <></>
                  )}

                  {th.publicationsTitles.length !== 0 ? (
                    <>
                      <br />
                      <br />
                      <h6>Publications</h6>
                    </>
                  ) : (
                    <></>
                  )}
                  <br />
                  {th.publicationsTitles.map((linkTitle, index3) => (
                    <div key={`link-${index3}`}>
                      <Link href={th.publicationsLinks[index3]}>
                        {linkTitle} -{'>'}
                      </Link>
                      <br />
                      <span>{th.publicationAuthors[index3]}</span>
                      <br />
                      <br />
                    </div>
                  ))}
                  {th.perspectivesAuthor !== '' ? (
                      <>
                        <br />
                        <h6>Perspectives - {th.perspectivesAuthor}</h6>
                        <p>
                          {th.perspectivesText}
                          <br />
                        </p>
                        <br />
                        <br />
                      </>
                    ) : (
                      <></>
                    )}
                  {th.pointIBM ? <PoinIBM /> : <></>}
                </Column>
              </Grid>
            </div>
          ))}
        </Column>

        <Column lg={3} md={4} sm={4}>
          <SideNav
            isFixedNav
            expanded={true}
            isChildOfHeader={false}
            aria-label="Side navigation"
          >
            <SideNavItems>
              {theme.map((t, index4) => (
                <SideNavLink href={'#' + index4} key={`side-link-${index4}`}>
                  <p
                    className={
                      currentTitle === index4
                        ? styles.currentTitle
                        : styles.notCurrentTitle
                    }
                  >
                    {index4 + 1} - {t.title.slice(0, 25)} ...
                  </p>
                </SideNavLink>
              ))}
            </SideNavItems>
          </SideNav>
        </Column>
      </Grid>

      {/* Nav pages */}
      <Grid className={styles.navigationBox} fullWidth>
           <Column lg={8} md={4} sm={4}>
             <a href="/theme6">
               <Button kind="tertiary" className={styles.navButton}>
                 <img
                   alt="arrow"
                   src="/images/icons/arrow--left.svg"
                   className={styles.arrowIcon}
                 />
                 {t('previousTheme')} : <br />
                 {t('theme6')}
               </Button>
             </a>
           </Column>
           <Column lg={8} md={4} sm={4} className={styles.nextNav}>
             <a href="/theme8">
               <Button kind="tertiary" className={styles.navButton}>
                 {t('nextTheme')} : <br />
                 {t('theme8')}
                 <img
                   alt="arrow"
                   src="/images/icons/arrow--right.svg"
                   className={styles.arrowIcon}
                 />
               </Button>
             </a>
           </Column>
         </Grid>
       </Theme>
     </div>
   );
 };

export default Theme7;
