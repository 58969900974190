export const persons_fr = [

  {
    name: 'Beatrice Kosowski',
    title: 'IBM France CEO',
    description: [
      'Pour IBM France, qui joue le rôle de chef de file du projet, le projet AIDA est un générateur innovations collectives en France rassemblant chercheurs, spécialistes du logiciel et experts métier. Les projets de co-innovation sont l’une des pratiques préférées d’IBM France pour construire une relation de confiance réciproque avec ses clients, et le projet AIDA donne l’occasion de le faire avec des partenaires d’excellence et passionnés, à plus grande échelle, sur une durée plus longue et bien entendu sur un sujet particulièrement actuel.',
    ],
    videoLink:
      'https://aida-acteurs.s3.eu-de.cloud-object-storage.appdomain.cloud/Video%20AIDA%20-%20IBM%20-%20Beatrice-Kosowski.mp4',
  },
  {
    name: 'Estelle Lacona',
    title: "Présidente de l'Université Paris-Saclay",
    description: [''],
    videoLink:
      'https://aida-acteurs.s3.eu-de.cloud-object-storage.appdomain.cloud/Video%20E.%20Iacona%20pour%20BPI.mp4',
  },
  {
    name: 'Olivier Nicolas',
    title: 'Softeam',
    description: [
      'Softeam, une filiale du groupe Docaposte, est le référent de la confiance numérique en France. Nous accompagnons les entreprises et les institutions publiques dans leur trajectoire numérique. Fondée en 1989, avec plus 2000 experts aujourd’hui, Softeam Docaposte a participé depuis 2005 à plus de 30 projets collaboratifs de niveau national ou européen dans des sujets variés tels que les systèmes cyber-physiques, les systèmes de temps-réel critiques et embarqués, le cloud computing, la cybersécurité ou le génie logiciel. ',
      "Les avancées technologiques telles que l'intelligence artificielle et l'automatisation numérique sont en train de révolutionner les organisations. Chez Softeam Docaposte, nous comprenons l'importance de saisir cette opportunité, pour plusieurs raisons. D’une part, nous pouvons aider les organisations à réaliser une transformation complète pilotée par l'intelligence artificielle. Nous savons en effet que la clé du succès réside dans la capacité à repenser les processus métier, à réorganiser les systèmes d'information et à optimiser l'infrastructure technologique. Avec notre produit Modelio d'architecture d'entreprise, nous pouvons fournir des capacités de modélisation et d'analyse avancées qui permettent aux entreprises de planifier et d'exécuter de manière efficace leurs initiatives de transformation. ",
      "D’autre part, nous sommes persuadés que la gouvernance et la conformité dans le domaine de l'intelligence artificielle doit être au cœur des préoccupations. Les entreprises doivent être en mesure de garantir que leurs systèmes d'intelligence artificielle respectent les normes légales et éthiques, en particulier le respect du Règlement Général sur la Protection des Données (le RGPD). Nous avons les capacités et les moyens d’aider nos clients à relever ces défis de gouvernance et de conformité, en veillant à ce qu'ils restent alignés avec les réglementations en vigueur.",
      "Chez Softeam Docaposte, nous sommes fiers d'appartenir au consortium AIDA, qui rassemble des chercheurs de haut niveau de l'Université Paris-Saclay et des industriels de taille mondiale tels qu’IBM. Cette collaboration nous permet de travailler main dans la main avec des experts et des leaders du domaine de l'intelligence artificielle, et de bénéficier de leur expertise et de leur savoir-faire pour développer des usages innovants pour Modelio. Forts de cette expérience enrichissante, nous espérons collaborer de nouveau ensemble pour façonner l'avenir de l'intelligence artificielle et de l'automatisation numérique. ",
    ],
    videoLink:
      'https://aida-acteurs.s3.eu-de.cloud-object-storage.appdomain.cloud/SOFTEAM@AIDA%20-%20Olivier%20Nicolas%20-%20Director%20BU%20Software.mp4',
  },

  {
    name: 'Filippo Focacci',
    title: 'Co-fondateur et PDG de DecisionBrain',
    description: [
      "DecisionBrain est spécialisée dans le développement de systèmes d’aide à la décision basés sur des méthodes d’optimisation mathématique et d’apprentissage automatique. Notre mission est d'aider nos clients à améliorer leurs opérations afin d'obtenir un avantage compétitif durable. Nous avons une longue histoire de collaboration avec les universités et les organisations scientifiques. Par exemple, plus de 30 % des membres de nos équipes sont titulaires d’un doctorat.",
      "Le projet AIDA a été pour nous une excellente occasion de collaborer avec d'autres partenaires innovants qui partagent nos passions pour l'application pratique et l'industrialisation de la recherche scientifique dans le domaine de l'intelligence artificielle.",
      'Ce projet avait, pour nous, deux objectifs principaux.',
      "D'un point de vue scientifique, nous avons cherché à découvrir des synergies entre les différents domaines de l'IA et aussi à trouver des complémentarités à nos compétences d’IA, qui sont principalement l’optimisation et l’apprentissage automatique et à les intégrer avec, par exemple, l'IA symbolique et l'IA générative.",
      "D'un point de vue développement commercial, nous avons vu le projet AIDA comme l’opportunité d'étendre notre offre d'optimisation des ressources humaines vers le domaine de l’administratif et de l'intégrer à la plateforme IBM AI en ciblant des segments de l'industrie que DecisionBrain ne couvre pas aujourd'hui, comme, par exemple, les secteurs de l'assurance et la banque.",
      "Grâce au projet AIDA, nous avons fait d'énormes progrès dans le domaine de l'optimisation des ressources humaines. Ces progrès sont démontrés par deux nouveaux contrats que nous avons remportés grâce à la R&D effectuée dans le cadre de ce projet, dont l'un avec le gouvernement de Singapour.",
      'Je tiens donc à remercier IBM, chef de file du projet, et la BPI de nous avoir fait confiance et de nous avoir permis de travailler dans un écosystème aussi stimulant pour l’innovation.',
    ],
    videoLink:
      'https://aida-acteurs.s3.eu-de.cloud-object-storage.appdomain.cloud/Aida%20Video_Filippo%20Focacci%20NOV%202023.mp4',
  },
  {
    name: 'Rodolphe Meyer',
    title: 'Directeur pour le dévelopment de STET',
    description: [ 
      "STET est le leader européen de la gestion des paiements SEPA et des cartes, fournissant une gamme de services essentiels aux banques partenaires.",
      "Notre entreprise, axée sur la coopération, l'exemplarité, la reconnaissance et la cohésion, joue un rôle crucial dans un secteur des paiements en constante évolution.",
      "Notre engagement majeur est de lutter efficacement contre la fraude financière. Grâce à une approche innovante, nous sommes au cœur des révolutions du domaine des paiements.",
      "Nous nous engageons à créer un environnement de travail inclusif et équitable pour favoriser la créativité et le développement professionnel, renforçant ainsi la confiance de nos équipes et de nos clients.",     
    ],
    videoLink: 
      'https://aida-acteurs.s3.eu-de.cloud-object-storage.appdomain.cloud/STET-Rodolphe-Meyer.mp4',
  },
];

export const persons_en = [
  {
    name: 'Beatrice Kosowski',
    title: 'IBM France CEO',
    description: [
      "For IBM France, which is playing the role of project leader, the AIDA project is a collective innovation generator in France, bringing together researchers, software specialists and business experts. Co-innovation projects are one of IBM France's preferred ways of building a relationship of mutual trust with its customers, and the AIDA project provides an opportunity to do this with partners of excellence and passion, on a larger scale, over a longer period and, of course, on a particularly topical subject.",
    ],
    videoLink:
      'https://aida-acteurs.s3.eu-de.cloud-object-storage.appdomain.cloud/Vide%CC%81o%20AIDA%20-%20IBM%20-%20Beatrice-Kosowski_EN.mp4',
  },
  {
    name: 'Estelle Lacona',
    title: 'President of Paris-Saclay University',
    description: [''],
    videoLink:
      'https://aida-acteurs.s3.eu-de.cloud-object-storage.appdomain.cloud/Video%20E.%20Iacona%20pour%20BPI_EN.mp4',
  },
  {
    name: 'Olivier Nicolas',
    title: 'Softeam',
    description: [
      'Softeam, a subsidiary of the Docaposte group, is the benchmark for digital trust in France. We support companies and public institutions on their digital journey. Founded in 1989 and now with more than 2,000 experts, Softeam Docaposte has been involved in more than 30 collaborative projects at national and European level since 2005, covering a wide range of subjects such as cyber-physical systems, real-time critical and embedded systems, cloud computing, cybersecurity and software engineering. ',
      'Technological advances such as artificial intelligence and digital automation are revolutionizing organizations. At Softeam Docapost, we understand the importance of seizing this opportunity for several reasons. Firstly, we can help organisations achieve a complete transformation driven by artificial intelligence. We know that the key to success lies in the ability to rethink business processes, reorganize information systems and optimize the technological infrastructure. With our Modelio enterprise architecture product, we can provide advanced modelling and analysis capabilities that enable companies to effectively plan and execute their transformation initiatives. ',
      'On the other hand, we are convinced that governance and compliance in the field of artificial intelligence must be at the heart of concerns. Companies must be able to ensure that their artificial intelligence systems comply with legal and ethical standards, in particular compliance with the General Data Protection Regulation (the GDPR). We have the capabilities and resources to help our customers meet these governance and compliance challenges, ensuring that they remain aligned with current regulations',
      'At Softeam Docaposte, we are proud to be part of the AIDA consortium, which brings together top-level researchers from the Université Paris-Saclay and world-class manufacturers such as IBM. This collaboration enables us to work hand in hand with experts and leaders in the field of artificial intelligence, and to benefit from their expertise and know-how to develop innovative uses for Modelio. On the strength of this enriching experience, we look forward to working together again to shape the future of artificial intelligence and digital automation. ',
    ],
    videoLink:
      'https://aida-acteurs.s3.eu-de.cloud-object-storage.appdomain.cloud/SOFTEAMAIDA%20-%20Olivier%20Nicolas%20-%20Director%20BU%20Software-EN.mp4',
  },

  {
    name: 'Filippo Focacci',
    title: 'Co-founder and CEO of DecisionBrain',
    description: [
      'DecisionBrain specialises in the development of decision support systems based on mathematical optimisation and machine learning methods. Our mission is to help our customers improve their operations in order to gain a sustainable competitive advantage. We have a long history of collaboration with universities and scientific organisations. For example, more than 30% of our team members hold a PhD',
      'The AIDA project has been an excellent opportunity for us to collaborate with other innovative partners who share our passion for the practical application and industrialisation of scientific research in the field of artificial intelligence',
      'For us, this project had two main objectives,',
      'From a scientific point of view, we sought to discover synergies between the different areas of AI and also to find complementarities to our AI skills, which are mainly optimisation and machine learning, and to integrate them with, for example, symbolic AI and generative AI.',
      'From a business development point of view, we saw the AIDA project as an opportunity to extend our human resources optimisation offering into the field of administration and to integrate it with the IBM AI platform by targeting industry segments that DecisionBrain does not currently cover, such as the insurance and banking sectors, for example',
      'Thanks to the AIDA project, we have made enormous progress in the field of human resources optimisation. This progress is demonstrated by two new contracts that we have won thanks to the R&D carried out as part of this project, including one with the Singapore government',
      'I would therefore like to thank IBM, the project leader, and the BPI for their confidence in us and for enabling us to work in such a stimulating ecosystem for innovation',
    ],
    videoLink:
      'https://aida-acteurs.s3.eu-de.cloud-object-storage.appdomain.cloud/Aida%20Video_Filippo%20Focacci%20NOV%202023_EN.mp4',
  },
  {
    name: 'Rodolphe Meyer',
    title: 'Business Development Director for STET',
    description: [ 
      "STET is the European leader in SEPA payment and card management, providing a range of essential services to partner banks.",
      "Our company, focused on cooperation, exemplarity, recognition, and cohesion, plays a crucial role in the constantly evolving payments sector.",
      "Our major commitment is to fight financial fraud effectively. Thanks to an innovative approach, we are at the heart of the revolutions in the payment field.",
      "We are committed to creating an inclusive and equitable work environment to foster creativity and professional development, thus strengthening the trust of our teams and our clients."  ],
    videoLink: 
      'https://aida-acteurs.s3.eu-de.cloud-object-storage.appdomain.cloud/Video%20AIDA%20-%20IBM%20-%20Rodolphe%20Meyer_EN.mp4',
  },
];