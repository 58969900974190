import styles from './Theme.module.scss';
import { Theme, Grid, Column, Link, Button, SideNav, SideNavItems, SideNavLink } from '@carbon/react';
import PointIBM2 from '../../Components/PointIBM2/PointIBM2';
import { theme2_fr, theme2_en } from '../../Data/theme2';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const Theme2 = () => {
  const { t, i18n } = useTranslation();
  const theme = i18n.language === 'en' ? theme2_en : theme2_fr;
  const [currentTitle, setCurrentTitle] = useState(0);
  const [sizeArray, setSizeArray] = useState([]);

  useEffect(() => {
    try {
      let tempSizeArray = [
        document.getElementById('title-theme-2').clientHeight +
        document.getElementById('0').clientHeight,
      ];

      theme.forEach((elem, index) => {
        tempSizeArray.push(
          tempSizeArray[index] + document.getElementById(index).clientHeight
        );
      });
      setSizeArray(tempSizeArray);
    } catch (error) {
      console.log(error);
    }
  }, [currentTitle, theme]);

  useEffect(() => {
    const handleScroll = (event) => {
      sizeArray.forEach((elem, index) => {
        if (index >= 1) {
          if (sizeArray[index - 1] < window.scrollY && window.scrollY < elem) {
            setCurrentTitle(index);
            return;
          }
        } else if (window.scrollY < elem) {
          setCurrentTitle(index);
          return;
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sizeArray, theme]);

  // For videos - Uncertainly useful:-)
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [i18n.language]); // Dependency array with language, changes when language changes

  // For videos - Works on theme change due to language switch
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [theme]);

  return (
    <div className={styles.theme}>
      <Theme theme="g100">
        <Grid className={styles.title} fullWidth id="title-theme-2">
          <Column lg={5} md={8} sm={4}>
            <h1>{t('theme2')}</h1>
            <br />
          </Column>
          <Column lg={7} md={8} sm={4}>
            {i18n.language === 'en' ? (
              <p>
                Artificial intelligence and robotics present society with three
                major challenges:  <br />
                <br />- trusting technologies that challenge control
                and risk management processes,
                <br />- balancing compliance with legal
                regulations (such as the RGPD and the AI Act) with the pursuit
                of innovation to remain competitive,
                <br />- and delivering goods and
                services ethically and efficiently.
                These challenges raise the
                question of how best to use AI, humans, or their combination to
                improve decision-making.
              </p>
            ) : (
              <p>
                L'intelligence artificielle et la robotique présentent trois
                défis majeurs à la société : <br />
                <br />- accorder sa confiance à des technologies qui remettent en question les processus de
                contrôle et de gestion des risques,
                <br />- trouver un équilibre entre le respect des réglementations légales (telles que le RGPD et
                l'AI Act)
                <br />- et la poursuite de l'innovation pour rester
                compétitif, et fournir des biens et services de manière éthique
                et efficiente. Ces défis soulèvent la question de l'utilisation
                optimale de l'IA, de l'humain, ou de leur combinaison pour
                améliorer la prise de décision.
              </p>
            )}
            <br />
            {i18n.language === 'en' ? (
              <video
                src="https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/Theme%202%20-%20T%20METHODOLOGY%20TOOL%20KIT%20FOR%20USERS_EN.mp4"
                title="video"
                width="100%"
                height="400px"
                allow="fullscreen"
                controls
              ></video>
            ) : (
              <video
                src="https://aida-videos-challenges.s3.eu-de.cloud-object-storage.appdomain.cloud/Lot-T-Challenge-fusion.mp4"
                title="video"
                width="100%"
                height="400px"
                allow="fullscreen"
                controls
              ></video>
            )}
          </Column>
          <Column lg={3} md={7} sm={3}></Column>
          <Column lg={1} md={1} sm={1} className={styles.numberBox}>
            <div className={styles.blueNumber}>2</div>
          </Column>
        </Grid>
      </Theme>

      <Theme theme="white">
        <Grid className={styles.navigationBox} fullWidth>
          <Column lg={13} md={4} sm={4}>
            {theme.map((th, index) => (
              <div className={styles.avanceBox} id={index}>
                <Grid fullWidth>
                  <Column lg={16} md={8} sm={4}>
                    <span>{t('advances')}</span>
                  </Column>
                </Grid>
                <Grid fullWidth>
                  <Column lg={3} md={2} sm={4}>
                    <h4>
                      {index + 1} - {th.title}
                    </h4>
                    <br />
                  </Column>
                  <Column lg={10} md={6} sm={4}>
                    {th.texte.map((texte) => (
                      <p>{texte}</p>
                    ))}
                    <br />
                    <h6>{th.demoTitle}</h6>

                    <p>
                      {th.demoDes}
                      <br />
                    </p>
                    {th.demoLink.length !== 0 ? (
                      <>
                        <br />
                        <video
                          src={th.demoLink}
                          title="video"
                          width="100%"
                          height="400px"
                          allow="fullscreen"
                          controls
                        ></video>
                      </>
                    ) : (
                      <></>
                    )}

                    {th.publicationsTitles.length !== 0 ? (
                      <>
                        <br />
                        <br />
                        <h6>Publications</h6>
                      </>
                    ) : (
                      <></>
                    )}
                    <br />
                    {th.publicationsTitles.map((linkTitle, index) => (
                      <>
                        <Link href={th.publicationsLinks[index]}>
                          {linkTitle} -{'>'}
                        </Link>
                        <br />
                        <span>{th.publicationAuthors[index]}</span>
                        <br />
                        <br />
                      </>
                    ))}
                    {th.perspectivesAuthor !== '' ? (
                      <>
                        <br />
                        <h6>Perspectives - {th.perspectivesAuthor}</h6>
                        <p>
                          {th.perspectivesText}
                          <br />
                        </p>
                        <br />
                        <br />
                      </>
                    ) : (
                      <></>
                    )}
                    {th.pointIBM ? <PointIBM2 /> : <></>}
                  </Column>
                </Grid>
              </div>
            ))}
          </Column>

          <Column lg={3} md={4} sm={4}>
            <SideNav
              isFixedNav
              expanded={true}
              isChildOfHeader={false}
              aria-label="Side navigation"
            >
              <SideNavItems>
                {theme.map((t, index4) => (
                  <SideNavLink href={'#' + index4} key={`side-link-${index4}`}>
                    <p
                      className={
                        currentTitle === index4
                          ? styles.currentTitle
                          : styles.notCurrentTitle
                      }
                    >
                      {index4 + 1} - {t.title.slice(0, 25)} ...
                    </p>
                  </SideNavLink>
                ))}
              </SideNavItems>
            </SideNav>
          </Column>
        </Grid>

        {/* Nav pages */}
        <Grid className={styles.navigationBox} fullWidth>
          <Column lg={8} md={4} sm={4}>
            <a href="/theme1">
              <Button kind="tertiary" className={styles.navButton}>
                <img
                  alt="arrow"
                  src="/images/icons/arrow--left.svg"
                  className={styles.arrowIcon}
                />
                {t('previousTheme')} : <br />
                {t('theme1')}
              </Button>
            </a>
          </Column>
          <Column lg={8} md={4} sm={4} className={styles.nextNav}>
            <a href="/theme3">
              <Button kind="tertiary" className={styles.navButton}>
                {t('nextTheme')} : <br />
                {t('theme3')}
                <img
                  alt="arrow"
                  src="/images/icons/arrow--right.svg"
                  className={styles.arrowIcon}
                />
              </Button>
            </a>
          </Column>
        </Grid>
      </Theme>
    </div>
  );
};

export default Theme2;
