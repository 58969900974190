export const theme4_fr = [
  {
    title:
      'Génération de chartes d’utilisateur RGPD',
    texte: [
    "Cette fonctionnalité permet aux utilisateurs de créer des chartes d'utilisation personnalisées pour chaque individu, en fonction d'un sous-ensemble de processus qu'ils ont choisi d'utiliser.",
    "En combinant le modèle de politique de données, le modèle GDPR et le modèle de transformation du système d'information (modèle BPM/DCM), il devient possible de générer des chartes utilisateur ainsi qu'un ensemble de documents contractuels GDPR. ",
    "Ces documents servent à justifier l'utilisation des données personnelles au sein des processus métier facilités par la plateforme AIDA.",
    "Il est important de souligner que ces chartes d'utilisation ne contiennent que les demandes de consentement pertinentes pour les services spécifiquement demandés par l'utilisateur final, évitant ainsi toute inclusion inutile.inal, évitant ainsi toute inclusion inutile."    
  ],
  demoTitle: 'Démonstrateur 4.2  [SOFTEAM] : RGPD Discovery, génération de chartes RGPD d’utilisateur',
  demoDes:
    '[SOFTEAM] : ',
  demoLink:
      'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/SOFTEAM%20-%20GDPR%20User%20charter%20generation.mp4',
    publicationsTitles: [
      '',
    ],
    publicationsLinks: [''],
    publicationAuthors: [],
    perspectivesAuthor: '',
    perspectivesText: [
      '',
    ]
  },
  {
    title: 'Atelier de Gestion de Données Personnelles',
    texte: [
      "Le module \"Discovery RGPD\" intégré au Crawler de Fichiers Personnels est spécialement conçu pour simplifier l'identification de l'utilisation des données personnelles au sein des fichiers d'une organisation. Cela s'avère essentiel pour aider les entreprises à se conformer au Règlement Général sur la Protection des Données (RGPD). Le module utilise des techniques avancées de recherche et d'analyse pour détecter des informations personnelles sensibles, telles que les adresses e-mail et les numéros de téléphone, au sein des fichiers situés dans un répertoire désigné. Il propose deux interfaces utilisateur : une interface graphique intuitive utilisant Swing et une autre via des instructions en ligne de commande. Cette approche à double interface offre aux utilisateurs la flexibilité de choisir entre une interaction graphique conviviale et une exécution en ligne de commande, répondant ainsi à diverses préférences et scénarios d'utilisation.",
    ],
    demoTitle: 'Génération de chartes utilisateur RGPD',
    demoDes:
      'Démonstrateur 4.1 [SOFTEAM] : Atelier de gestion de données personnelles et   ',
    demoLink:
      'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/SOFTEAM%20-%20RGPD%20Discovery.mp4',
    publicationsTitles: [],
    publicationsLinks: [],
    publicationAuthors: [],
    perspectivesAuthor: 'Softeam',
    perspectivesText: [
      "L'atelier de gestion de données personnelles, avec les fonctionnalités décrites, présente un niveau de maturité élevé qui permet déjà d'entamer la qualification du prototype réalisé. Il est actuellement présenté aux clients de Softeam qui ont exprimé le besoin d'un support outillé pour gérer leur mise en conformité au RGPD.",
    ]
    },
];

export const theme4_en = [
  {
    title:
    'Generation of GDPR User Charters',
    texte: [
      "This feature allows users to create customized usage charters for each individual, based on a subset of processes they have chosen to use.",
      "By combining the data policy model, the GDPR model, and the information system transformation model (BPM/DCM model), it becomes possible to generate user charters as well as a set of GDPR contractual documents.",
      "These documents serve to justify the use of personal data within business processes facilitated by the AIDA platform.",
      "It is important to note that these usage charters only contain consent requests relevant to the services specifically requested by the end-user, thereby avoiding any unnecessary inclusion."
    ],
    demoTitle: 'RGPD user charter generator',
    demoDes:
      'Démonstrator 4.1 : [SOFTEAM]  : RGPD Discovery, detection tool of personal data',
    demoLink:
      'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/SOFTEAM%20-%20GDPR%20User%20charter%20generation_EN.mp4',
    publicationsTitles: [],
    publicationsLinks: [],
    publicationAuthors: [],
    perspectivesAuthor: '',
    perspectivesText: [
      ''
    ]
  }, 
  {
    title: 'Personal Data Management Workshop',
    texte: [
      "The 'GDPR Discovery' module, integrated into the Personal File Crawler, is specifically designed to simplify the identification of personal data usage within an organization's files. ",
      "This is crucial for assisting companies in complying with the General Data Protection Regulation (GDPR). ",
      "The module employs advanced search and analysis techniques to detect sensitive personal information, such as email addresses and phone numbers, within files located in a designated directory. ",
      "It offers two user interfaces: an intuitive graphical interface using Swing and another through command-line instructions. ",
      "This dual-interface approach provides users with the flexibility to choose between a user-friendly graphical interaction and command-line execution, thus accommodating various preferences and usage scenarios."    
    ],
    demoTitle: 'Demonstrator 4.2',
    demoDes:
      '[SOFTEAM] : Workshop on managing of personal data and generation of RGPD charters user charters',
    demoLink:
      'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/SOFTEAM%20-%20RGPD%20Discovery_EN.mp4',
    publicationsTitles: [],
    publicationsLinks: [],
    publicationAuthors: [],
    perspectivesAuthor: '',
    perspectivesText: [
      ''
    ]
  },
];
