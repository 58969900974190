import {
  Header,
  HeaderName,
  Theme,
  HeaderGlobalBar,
  HeaderMenu,
  HeaderMenuItem,
  HeaderNavigation,
} from '@carbon/react';
import { useTranslation } from "react-i18next";
import styles from './Header.module.scss';
import { useNavigate} from "react-router-dom";

function HeaderBar() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()
  const handleTrans = (code) => {
    i18n.changeLanguage(code);
    console.log("post changement :", i18n)
  };

  return (
    <Theme theme="g90">
      <Header aria-label="" theme="g100" id="header">
        <HeaderName href="/" prefix="">
          PSPC AIDA (AI for Digital Automation)
        </HeaderName>
        <HeaderName href="/" prefix={t('headerTitle')}></HeaderName>
        <HeaderGlobalBar>
          <HeaderNavigation aria-label="">
            <HeaderMenu aria-label="" menuLinkName={`${t('headerTheme')}s`}>
            <HeaderMenuItem onClick={(e) => { e.preventDefault(); navigate('/theme1'); }}>1 - {t('theme1')} </HeaderMenuItem>
            <HeaderMenuItem onClick={(e) => { e.preventDefault(); navigate('/theme2'); }}>2 - {t('theme2')} </HeaderMenuItem>

              <HeaderMenuItem onClick={(e) => { e.preventDefault(); navigate('/theme3'); }}>3 - {t('theme3')} </HeaderMenuItem>
              <HeaderMenuItem onClick={(e) => { e.preventDefault(); navigate('/theme4'); }}>4 - {t('theme4')} </HeaderMenuItem>
              <HeaderMenuItem onClick={(e) => { e.preventDefault(); navigate('/theme5'); }}>5 - {t('theme5')} </HeaderMenuItem>
              <HeaderMenuItem onClick={(e) => { e.preventDefault(); navigate('/theme6'); }}>6 - {t('theme6')} </HeaderMenuItem>
              <HeaderMenuItem onClick={(e) => { e.preventDefault(); navigate('theme7'); }}>7 - {t('theme7')} </HeaderMenuItem>
              <HeaderMenuItem onClick={(e) => { e.preventDefault(); navigate('/theme8'); }}>8 - {t('theme8')} </HeaderMenuItem>
              <HeaderMenuItem onClick={(e) => { e.preventDefault(); navigate('/theme9'); }}>9 - {t('theme9')} </HeaderMenuItem>
              <HeaderMenuItem onClick={(e) => { e.preventDefault(); navigate('/theme10'); }}>
                10 - {t('theme10')}
              </HeaderMenuItem>
            </HeaderMenu>
          </HeaderNavigation>
        </HeaderGlobalBar>

        <button
          className={`${styles.languageButton} ${styles.languageButtonFr}`}
          onClick={() => handleTrans('fr')}
        >
          FR
        </button>
        <button
          className={`${styles.languageButton} ${styles.languageButtonEn}`}
          onClick={() => handleTrans('en')}
        >
          EN
        </button>
      </Header>
    </Theme>
  );
}

export default HeaderBar;
