export const theme8_fr = [
  {
    title:
      'Capturer les évènements operationnels',
    texte: [
      "Plateforme intégrée de capture, stockage, traitement de données et d’événements",
      "Le premier résultat est la mise au point d’un kit de développement logiciel qui permet de formater des événements à format fixe à partir des données métiers, puis de configurer la connexion et l’envoi des événements formatés à un bus d’événement.",
      "Le second résultat est la mise au point d’un moteur de gestion d’événements avec les caractéristiques suivantes : ",
      "A. Pouvoir utiliser un fichier de configuration pour construire un moteur de traitement d’événements, ",
      "B. Bénéficier d’une technologie intégrée permettant d’augmenter les performances d’émission des événements, de vérifier l’intégrité des données reçues et de limiter l’encombrement réseau.",
      "Le troisième résultat est la mise au point d’un moteur de capture d’événements qui va permettre à la plateforme AIDA de se synchroniser avec de multiples sources d’événements, selon les standards compatibles avec des cas intensifs en production de données et permettant de rassembler ces données dans un lac, pour les traiter en différé.",
      "L’architecture du moteur fournit un moyen de faire des requêtes complexes sur une base de traces de transactions potentiellement considérable, à l’aide d’outils d’extraction d’information de documents non structurés, et cela soit au fil de l’eau, soit en différé depuis le lac de données.",
    ],
    demoTitle: '',
    demoDes:
      '',
    demoLink:
      '',
    publicationsTitles: [
      'Doc générale',
      'Traitement des évenements métier',
    ],
    publicationsLinks: [
      'https://www.ibm.com/docs/fr/cloud-paks/cp-biz-automation/23.0.2?topic=automation-business-insights',
      'https://www.ibm.com/docs/fr/cloud-paks/cp-biz-automation/23.0.2?topic=insights-processing-events',
    ],
    publicationAuthors: [],
    perspectivesAuthor: '',
    perspectivesText: [],
  },
  {
    title:
      'Aggréger et corréler les évènements operationnels',
    texte: [
      "Le résultat technologique est un moteur générique permettant la connexion à une source de données, d’agréger ou corréler des événements connexes afin de générer des séries chronologiques (« time series ») et des agrégats synthétiques (« summaries ») d’une part, et de calculer des métriques et des indicateurs de performance d’autre part ;",
      "Ayant les exigences technologiques en matière de performance, passage à l’échelle, en matière de flexibilité de la configuration pour les environnements Kubernetes et Docker compose (IBM BAI for a server), etc.",
      "Devant permettre de réaliser l’agrégation d’événements en flux continu, en adressant les processus Apache Flink à configuration dynamique reposant sur une API Java déclarative pour le calcul de métriques et d’indicateurs de performance – encore à l’état de prototype.",
      "Les performances obtenues sont au niveau d'un usage industriel comme souhaité.",
    ],
    demoTitle: '',
    demoDes:
      '',
    demoLink:
      '',
    publicationsTitles: [
      "Architecture générale d'IBM Business Automation Insights",
    ],
    publicationsLinks: ['https://www.ibm.com/docs/fr/cloud-paks/cp-biz-automation/23.0.2?topic=insights-general-architecture-design',],
    publicationAuthors: [],
    perspectivesAuthor: '',
    perspectivesText: [],
  },
  {
    title:
      'Suivre et représenter les indicateurs opérationnels',
    texte: [
      "L’objectif est de mettre au point un moteur de suivi d’indicateurs de performance, pour enrichir un artefact de suivi (représentable par un tableau de bord), et déclencher des alertes spécifiables aussi dynamiquement que possible a été atteint et dépasse l’état de l’art sur deux points saillants :",
      "Convergence entre l’approche BAM et l’approche Complex Event Processing par la réalisation probatoire d’un environnement générique de monitoring qui capture les données métier en quasi-temps réel et permet de visualiser les activités et processus, mais qui se conforme à toutes les caractéristiques industrielles des moteurs de capture, stockage, agrégation d’événements qui ont été définies et appliquées précédemment ;",
      "Capacité à doter l’utilisateur final non technique des moyens de définir les indicateurs de performance métier et de constituer les tableaux de bord métier dynamiquement.",
      "Le contenu des tableaux de bord lui-même est à l’état de l’art : les visualisations sont représentées dans des graphiques dont les valeurs des mesures peuvent être des nombres simples ou des agrégations (opérations mathématiques). Ces mesures agrègent les données sur un intervalle de temps ou montrent l’évolution dans le temps en intervalles. La capacité de suivi des alertes est intégrée aux tableaux de bord et ne requiert pas de configuration par défaut. ",
      "Pour un dashboard représentatif, les tests de montée en charge ont été effectués  jusqu’à 50 millions d’événements donnent une bonne linéarité et une bonne performance, et valident l’approche proposée.",
    ],
    demoTitle: '',
    demoDes:
      '',
    demoLink:
      '',
    publicationsTitles: [
      'Visualisation des données', 
      'Exemples de visualisations de données operationnelles',
    ],
    publicationsLinks: [
      'https://www.ibm.com/docs/fr/cloud-paks/cp-biz-automation/23.0.2?topic=insights-visualizing-data',
      'https://www.ibm.com/docs/fr/cloud-paks/cp-biz-automation/23.0.2?topic=insights-sample-dashboards',
    ],
    publicationAuthors: [],
    perspectivesAuthor: '',
    perspectivesText: [],
  },
];

export const theme8_en = [
  {
    title: 'Capturing Operational Events',
    texte: [
      "Integrated platform for capturing, storing, processing data and events",
      "The first outcome is the development of a software development kit that allows for formatting fixed-format events from business data, then configuring the connection and sending the formatted events to an event bus.",
      "The second outcome is the development of an event management engine with the following features: ",
      "A. The ability to use a configuration file to build an event processing engine, ",
      "B. The benefit of integrated technology that enhances the performance of event emission, verifies the integrity of received data, and limits network congestion.",
      "The third outcome is the development of an event capture engine that will enable the AIDA platform to synchronize with multiple event sources, following standards compatible with data-intensive production cases and allowing for the aggregation of this data into a lake, to be processed later.",
      "The engine's architecture provides a means to make complex queries on a potentially substantial transaction trace database, using tools for extracting information from unstructured documents, either in real-time or retrospectively from the data lake.",
    ],
    demoTitle: '',
    demoDes:
      '',
    demoLink:
      '',
    publicationsTitles: [
      'Product documentation entry',
      'Traitement des évenements métier',
    ],
    publicationsLinks: [
      'https://www.ibm.com/docs/en/cloud-paks/cp-biz-automation/23.0.2?topic=automation-business-insights',
      'https://www.ibm.com/docs/en/cloud-paks/cp-biz-automation/23.0.2?topic=insights-processing-events',
    ],
    publicationAuthors: [],
    perspectivesAuthor: '',
    perspectivesText: [],
  },
  {
    title:
      'Aggregate and Correlate Operational Events',
    texte: [
      "The technological result is a generic engine allowing connection to a data source, aggregating or correlating related events to generate time series and synthetic summaries on one hand, and calculating metrics and performance indicators on the other;",
      "Meeting technological requirements in terms of performance, scalability, configuration flexibility for Kubernetes and Docker Compose environments (IBM BAI for a server), etc.",
      "Designed to enable continuous flow event aggregation, addressing dynamic configuration Apache Flink processes based on a declarative Java API for calculating metrics and performance indicators – still at the prototype stage.",
      "The performance achieved is at the level of industrial use as desired.",
    ],
    demoTitle: '',
    demoDes:
      '',
    demoLink:
      '',
    publicationsTitles: [
        "IBM Business Automation Insights general architecture",
    ],
    publicationsLinks: ['https://www.ibm.com/docs/en/cloud-paks/cp-biz-automation/23.0.2?topic=insights-general-architecture-design',],
    publicationAuthors: [],
    perspectivesAuthor: '',
    perspectivesText: [],
  },
  {
    title:
    'Tracking and Representing Operational Indicators',
    texte: [
    "The goal is to develop a performance indicator tracking engine to enrich a tracking artifact (representable by a dashboard) and to trigger alerts as dynamically as possible. This goal has been achieved and surpasses the state of the art in two prominent aspects:",
    "Convergence between the BAM approach and the Complex Event Processing approach through the trial implementation of a generic monitoring environment that captures business data in near-real-time and allows visualization of activities and processes, but also conforms to all the industrial features of the capture, storage, aggregation engines that have been defined and applied previously;",
    "Ability to provide non-technical end-users with the means to define business performance indicators and dynamically create business dashboards.",
    "The content of the dashboards themselves is state of the art: visualizations are represented in charts where the measure values can be simple numbers or aggregations (mathematical operations). These measures aggregate data over a time interval or show evolution over time in intervals. The capability to track alerts is integrated into the dashboards and does not require default configuration.",
    "For a representative dashboard, load testing has been conducted up to 50 million events showing good linearity and performance, validating the proposed approach.",
    ],
    demoTitle: '',
    demoDes:
      '',
    demoLink:
      '',
    publicationsTitles: [
      'Data visualization', 'Samples of operational data visualization',
    ],
    publicationsLinks: [
      'https://www.ibm.com/docs/en/cloud-paks/cp-biz-automation/23.0.2?topic=insights-visualizing-data',
      'https://www.ibm.com/docs/en/cloud-paks/cp-biz-automation/23.0.2?topic=insights-sample-dashboards',
    ],
    publicationAuthors: [],
    perspectivesAuthor: '',
    perspectivesText: [],
  },
];