export const theme6_fr = [
  {
    title: 'Out-Of-Distribution détection',
    texte: [
      "Mise au point de nouvelles méthodes pour détecter avant leur exécution, les cas où les données d'inférence peuvent ne pas être appropriées au modèle de ML (« Out-Of-Distribution détection » (OOD)).",
      'Ces nouvelles méthodes OOD:',
      '- Proposent une utilisation de la géométrie de l’information à travers des mesures de distance qui donne des résultats particulièrement saillants (voir publications);',
      '- Donnent des résultats au-delà des scores basés sur la mesure de Mahalanobis pour la détection des OOD textuels;',
      '- Proposent une perspective fonctionnelle sur la détection multicouche de OOD;',
      '- Ont fait l’objet d’évaluations empiriques sur des données d’images et textuelles.',
    ],
    demoTitle:
      'Démonstrateur Out of Distribution - Découverte de données hors distribution [CentraleSupelec-L2S]',
    demoDes: '',
    demoLink:
      "https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/Demonstrateur_lot_C_OOD_detection-compressed.mp4",
    publicationsTitles: [
      '“Beyond Mahalanobis distance for textual OOD detection,” in Advances in Neural Information Processing Systems, S. Koyejo, S. Mohamed, A. Agarwal, D. Belgrave, K. Cho, and A. Oh, Eds., vol. 35. Curran Associates, Inc., 2022, pp. 17 744–17 759',
      '"A Functional Data Perspective and Baseline On Multi-Layer Out-of-Distribution Detection", 2023 (under review)',
      '“PACMAN: pac-style bounds accounting for the mismatch between accuracy and negative log-loss,” Information and Inference: A Journal of the IMA, 2021, (to appear)',
      '"A Data-Driven Measure of Relative Uncertainty for Misclassification Detection", 2023 (under review) ',
      '“A halfspace-mass depth-based method for adversarial attack detection,” Transactions on Machine Learning Research, 2023.',
    ],
    publicationsLinks: [
      'https://arxiv.org/abs/2211.13527',
      'https://arxiv.org/abs/2112.05547',
      'https://arxiv.org/pdf/2306.01710',
      'https://openreview.net/pdf?id=YtU0nDb5e8',
    ],
    publicationAuthors: [
      'P. Colombo, E. Dadalto, G. Staerman, N. Noiry, and P. Piantanida',
      'E. Dadalto, P. Colombo, G. Staerman, N. Noiry, P. Piantanida,',
      'M. Vera, L. R. Vega, and P. Piantanida',
      'E. Dadalto, M. Romanelli, G. Pichler, P. Piantanida',
      'M. Picot, F. Granese, G. Staerman, M. Romanelli, F. Messina, P. Piantanida, and P. Colombo',
    ],
    perspectivesAuthor: '',
    perspectivesText: [],
  },
];

export const theme6_en = [
  {
    title: 'Out-Of-Distribution detection',
    texte: [
      "Development of new methods to detect, prior to their execution, cases where inference data may not be appropriate for the ML model ('Out-Of-Distribution detection' (OOD)).",
      'These new OOD methods ',
      '- Propose a use of information geometry through distance measures that gives particularly salient results (see publications);',
      '- Give results beyond scores based on the Mahalanobis measure for the detection of textual OODs;',
      '- Provide a functional perspective on multilayer OOD detection;',
      '- have been empirically evaluated on image and text data',
    ],
    demoTitle:
      'Démonstrateur Out of Distribution - Découverte de données hors distribution [CentraleSupelec-L2S]',
    demoDes: '',
    demoLink:
      "https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/Demonstrateur_lot_C_OOD_detection-compressed_EN.mp4",
    publicationsTitles: [
      '“Beyond Mahalanobis distance for textual OOD detection,” in Advances in Neural Information Processing Systems, S. Koyejo, S. Mohamed, A. Agarwal, D. Belgrave, K. Cho, and A. Oh, Eds., vol. 35. Curran Associates, Inc., 2022, pp. 17 744–17 759',
      '"A Functional Data Perspective and Baseline On Multi-Layer Out-of-Distribution Detection", 2023 (under review)',
      '“PACMAN: pac-style bounds accounting for the mismatch between accuracy and negative log-loss,” Information and Inference: A Journal of the IMA, 2021, (to appear)',
      '"A Data-Driven Measure of Relative Uncertainty for Misclassification Detection", 2023 (under review) ',
      '“A halfspace-mass depth-based method for adversarial attack detection,” Transactions on Machine Learning Research, 2023.',
    ],
    publicationsLinks: [
      'https://arxiv.org/abs/2211.13527',
      'https://arxiv.org/abs/2112.05547',
      'https://arxiv.org/pdf/2306.01710',
      'https://openreview.net/pdf?id=YtU0nDb5e8',
    ],
    publicationAuthors: [
      'P. Colombo, E. Dadalto, G. Staerman, N. Noiry, and P. Piantanida',
      'E. Dadalto, P. Colombo, G. Staerman, N. Noiry, P. Piantanida,',
      'M. Vera, L. R. Vega, and P. Piantanida',
      'E. Dadalto, M. Romanelli, G. Pichler, P. Piantanida',
      'M. Picot, F. Granese, G. Staerman, M. Romanelli, F. Messina, P. Piantanida, and P. Colombo',
    ],
    perspectivesAuthor: '',
    perspectivesText: [],
  },
];
