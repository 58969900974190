import styles from './PoinIBM2.module.scss';
import { useTranslation } from 'react-i18next';

function PoinIBM() {
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.pointIBM}>
      {i18n.language === 'en' ? (
        <>
          <h5>
            <span className={styles.blueText}>IBM</span>'s view on research
            progress and application horizon
          </h5>
          <strong>Methodology and support tools deployed</strong>
          <br />
          <strong>Horizon: already commercially available.</strong>
        </>
      ) : (
        <>
          <h5>
            Point de vue d’<span className={styles.blueText}>IBM</span> sur
            l’avancement des recherches et l’horizon d’application
          </h5>
          <strong>Méthodologie et outils supports déployés </strong>
          <br />
          <strong>Horizon : déjà disponible commercialement.</strong>
        </>
      )}
    </div>
  );
}

export default PoinIBM;
