import styles from './Theme.module.scss';
import { Theme, Grid, Column, Link, Button, SideNav, SideNavItems, SideNavLink } from '@carbon/react';
import PointIBM2 from '../../Components/PointIBM2/PointIBM2';
import { theme5_fr, theme5_en } from '../../Data/theme5';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

 const Theme5 = () => {
   const { t, i18n } = useTranslation();
   const theme = i18n.language === 'en' ? theme5_en : theme5_fr;
   const [currentTitle, setCurrentTitle] = useState(0);
   const [sizeArray, setSizeArray] = useState([]);

   useEffect(() => {
     try {
       let tempSizeArray = [
         document.getElementById('title-theme-5').clientHeight +
           document.getElementById('0').clientHeight,
       ];

       theme.forEach((elem, index) => {
         tempSizeArray.push(
           tempSizeArray[index] + document.getElementById(index).clientHeight
         );
       });
       setSizeArray(tempSizeArray);
     } catch (error) {
       console.log(error);
     }
   }, [currentTitle, theme]);

   useEffect(() => {
     const handleScroll = (event) => {
       sizeArray.forEach((elem, index) => {
         if (index >= 1) {
           if (sizeArray[index - 1] < window.scrollY && window.scrollY < elem) {
             setCurrentTitle(index);
             return;
           }
         } else if (window.scrollY < elem) {
           setCurrentTitle(index);
           return;
         }
       });
     };

     window.addEventListener('scroll', handleScroll);

     return () => {
       window.removeEventListener('scroll', handleScroll);
     };
   }, [sizeArray, theme]);

  // For videos - Uncertainly useful:-)
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [i18n.language]); // Dependency array with language, changes when language changes

  // For videos - Works on theme change due to language switch
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [theme]);

   return (
     <div className={styles.theme}>
       <Theme theme="g100">
         <Grid className={styles.title} fullWidth id="title-theme-5">
           <Column lg={5} md={8} sm={4}>
             <h1>{t('theme5')}</h1>
             <br />
           </Column>
           <Column lg={7} md={8} sm={4}>
            {i18n.language === 'en' ? (
              <p>
                The automation of decision-making in business, incorporating both prescriptive and data-learned models, represents a major challenge. This approach aims to combine the agility of data learning models with the transparency of logical rules. It raises scientific and technological integration challenges, conditions for the democratization of new decision-making tools in which users inject both their expertise and their data to automate their critical business decisions with trust and transparency.
                <br /><br />
                The AIDA project enables improvements in automated decision-making through major advances in research and development:<br />
                <br />1 - Hybrid Modeling of Decisions with Multiple AIs:
                AIDA integrates <span>prescriptive</span> and <span>predictive</span> models in decision-making, offering impressive versatility for the creation and management of decision models.<br />
                <br />2 - Induction of Rules:
                IBM has contributed to the <span>induction</span> of <span>business rules</span> in AIDA, enhancing the interpretability of models and enabling the import of machine learning models as rules.<br />
                <br />3 - Life Cycle and Governance of Decisions:
                AIDA offers collaborative <span>governance</span> and complete management of the decision services lifecycle, providing unique flexibility for business users and integrators.<br />
                <br />4 - Simulation and Comparison of Decisions:
                AIDA allows for the simulation and comparison of decisions, strengthening trust in automated decisions.<br />
                <br />5 - Composite Explanations of Decisions:
                AIDA provides composite explanations for decisions by combining expert and learned knowledge, improving <span>transparency</span> and understanding.
              </p>
            ) : (
              <p>L'automatisation des décisions en entreprise, intégrant à la fois des modèles prescriptifs et des modèles appris depuis les données représente un défi majeur.
                Cette approche a pour ambition de combiner l'agilité des modèles d'apprentissage sur les données avec la transparence des règles logiques.
                Elle soulève des défis d'intégration scientifique et technologique, conditions pour la démocratisation de nouveaux outils décisionnels dans lesquels les utilisateurs injectent à la fois leur expertise et leur données pour automatiser dans la confiance et la transparence leurs decisions critiques à leur métier.

                <br /><br />Le projet AIDA permet d'améliorer la prise de décision automatisée par des principales avancées en recherche et développement:<br />
                <br />1 - Modélisation hybride des Décisions avec des IA multiples :
                AIDA intègre des <span>modèles prescriptifs</span> et <span>prédictifs</span> dans la prise de décision, offrant une polyvalence impressionnante pour la création et la gestion de modèles de décision.<br />
                <br />2 - Induction de Règles:
                IBM a contribué à l'<span>induction</span> de <span>règles métier</span> dans AIDA, améliorant l'interprétabilité des modèles et permettant d'importer des modèles d'apprentissage automatique sous forme de règles.<br />
                <br />3 - Cycle de Vie et Gouvernance des Décisions :
                AIDA propose une <span>gouvernance</span> collaborative et une gestion complète du cycle de vie des services décisionnels, offrant une flexibilité unique pour les utilisateurs métier et intégrateurs.<br />
                <br />4 - Simulation et Comparaison des Décisions :
                AIDA permet la simulation et la comparaison des décisions, renforçant la confiance dans les décisions automatisées.<br />
                <br />5 - Explications Composites des Décisions :
                AIDA offre des explications composites pour les décisions en combinant la connaissance experte et apprise, améliorant la <span>transparence</span> et la compréhension.
              </p>
            )}
            {i18n.language === 'en' ? (
              <video
                src="https://aida-videos-challenges.s3.eu-de.cloud-object-storage.appdomain.cloud/IBM%20-%20Transparent%20Decisions.EN.mp4"
                title="video"
                width="100%"
                height="400px"
                allow="fullscreen"
                controls
              ></video>
              ) : (
                <video
                src="https://aida-videos-challenges.s3.eu-de.cloud-object-storage.appdomain.cloud/IBM%20-%20Transparent%20Decisions.FR.mp4"
                title="video"
                width="100%"
                height="400px"
                allow="fullscreen"
                controls
              ></video>
              )}
          </Column>
           <Column lg={3} md={7} sm={3}></Column>
           <Column lg={1} md={1} sm={1} className={styles.numberBox}>
             <div className={styles.blueNumber}>5</div>
           </Column>
         </Grid>
       </Theme>

       <Theme theme="white">
         <Grid className={styles.navigationBox} fullWidth>
           <Column lg={13} md={4} sm={4}>
             {theme.map((th, index) => (
               <div className={styles.avanceBox} id={index}>
                 <Grid fullWidth>
                   <Column lg={16} md={8} sm={4}>
                     <span>{t('advances')}</span>
                   </Column>
                 </Grid>
                 <Grid fullWidth>
                   <Column lg={3} md={2} sm={4}>
                     <h4>
                       {index + 1} - {th.title}
                     </h4>
                     <br />
                   </Column>
                   <Column lg={10} md={6} sm={4}>
                     {th.texte.map((texte) => (
                       <p>{texte}</p>
                     ))}
                     <br />
                     <h6>{th.demoTitle}</h6>

                     <p>
                       {th.demoDes}
                       <br />
                     </p>
                     {th.demoLink.length !== 0 ? (
                       <>
                         <br />
                         <video
                          src={th.demoLink}
                          title="video"
                          width="100%"
                          height="400px"
                          allow="fullscreen"
                          controls
                        ></video>
                       </>
                     ) : (
                       <></>
                     )}

                     {th.publicationsTitles.length !== 0 ? (
                       <>
                         <br />
                         <br />
                         <h6>Publications</h6>
                       </>
                     ) : (
                       <></>
                     )}
                     <br />
                     {th.publicationsTitles.map((linkTitle, index) => (
                       <>
                         <Link href={th.publicationsLinks[index]}>
                           {linkTitle} -{'>'}
                         </Link>
                         <br />
                         <span>{th.publicationAuthors[index]}</span>
                         <br />
                         <br />
                       </>
                     ))}
                      {th.perspectivesAuthor !== '' ? (
                      <>
                        <br />
                        <h6>Perspectives - {th.perspectivesAuthor}</h6>
                        <p>
                          {th.perspectivesText}
                          <br />
                        </p>
                        <br />
                        <br />
                      </>
                    ) : (
                      <></>
                    )}
                     {th.pointIBM ? <PointIBM2 /> : <></>}
                   </Column>
                 </Grid>
               </div>
             ))}
           </Column>

           <Column lg={3} md={4} sm={4}>
             <SideNav
               isFixedNav
               expanded={true}
               isChildOfHeader={false}
               aria-label="Side navigation"
             >
               <SideNavItems>
                 {theme.map((t, index4) => (
                   <SideNavLink href={'#' + index4} key={`side-link-${index4}`}>
                     <p
                       className={
                         currentTitle === index4
                           ? styles.currentTitle
                           : styles.notCurrentTitle
                       }
                     >
                       {index4 + 1} - {t.title.slice(0, 25)} ...
                     </p>
                   </SideNavLink>
                 ))}
               </SideNavItems>
             </SideNav>
           </Column>
         </Grid>

         {/* Nav pages */}
         <Grid className={styles.navigationBox} fullWidth>
           <Column lg={8} md={4} sm={4}>
             <a href="/theme4">
               <Button kind="tertiary" className={styles.navButton}>
                 <img
                   alt="arrow"
                   src="/images/icons/arrow--left.svg"
                   className={styles.arrowIcon}
                 />
                 {t('previousTheme')} : <br />
                 {t('theme4')}
               </Button>
             </a>
           </Column>
           <Column lg={8} md={4} sm={4} className={styles.nextNav}>
             <a href="/theme6">
               <Button kind="tertiary" className={styles.navButton}>
                 {t('nextTheme')} : <br />
                 {t('theme6')}
                 <img
                   alt="arrow"
                   src="/images/icons/arrow--right.svg"
                   className={styles.arrowIcon}
                 />
               </Button>
             </a>
           </Column>
         </Grid>
       </Theme>
     </div>
   );
 };

export default Theme5;
