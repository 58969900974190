import styles from './Footer.module.scss';
import { Theme, Grid, Column } from '@carbon/react';
import { useTranslation } from 'react-i18next';

function BottomBar() {
  const { t } = useTranslation();

  const companies = [
    {
      name: 'IBM',
      contacts: ['Bruno Guillon', 'Pierre Feillet'],
      links: ['https://www.linkedin.com/in/bruno-guillon', 'https://www.linkedin.com/in/pierrefeillet']
    },
    {
      name: 'Université Paris-Saclay',
      contacts: ['Fatiha Sais', 'Feirouz Ksontini'],
      links: ['https://www.linkedin.com/in/fatihasais', 'https://www.linkedin.com/in/feirouz-ksontini-85a6302']
    },
    {
      name: 'DECISIONBRAIN',
      contacts: ['Filippo Focacci'],
      links: ['https://www.linkedin.com/in/filippo-focacci-35a5091']
    },
    {
      name: 'SOFTEAM/DOCAPOSTE',
      contacts: ['Laurent Goncalves', 'Juan Goncalves'],
      links: ['https://www.linkedin.com/in/laurentgoncalves', 'https://exemple7.com']
    },
    {
      name: 'STET',
      contacts: ['Laurent Perrier'],
      links: ['https://www.linkedin.com/company/stet']
    },
  ];

  return (
    <Theme theme="g100">
      <Grid fullWidth theme="g100">
        <Column lg={16} md={5} sm={4} className={styles.bottomBox}>
        <h4 className={styles.contactsTitle}>{t('contact')}</h4>
          <div className={styles.contactWrapper}>
            {companies.map((company, index) => (
              <div key={index} className={styles.companyContainer}>
                <h4 className={styles.companyName}>{company.name}</h4>
                <div className={styles.contactList}>
                  {company.contacts.map((contact, contactIndex) => (
                    <div key={contactIndex} className={styles.contactItem}>
                      <a href={company.links[contactIndex]} target="_blank" rel="noreferrer noopener">
                        {contact}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Column>
      </Grid>
    </Theme>
  );
} 

export default BottomBar;
