import styles from './Theme.module.scss';
import { Theme, Grid, Column, Link, Button, SideNav, SideNavItems, SideNavLink } from '@carbon/react';
import PoinIBM from '../../Components/PointIBM/PointIBM';
import { theme1_fr, theme1_en } from '../../Data/theme1';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';

const Theme1 = () => {
  const { t, i18n } = useTranslation();
  const theme = i18n.language === "en" ? theme1_en : theme1_fr;
  const [currentTitle, setCurrentTitle] = useState(0);
  const [sizeArray, setSizeArray] = useState([]);

  // Video refs
  /*
  const videoRefs = useRef([]);

  useEffect(() => {
    // This effect will run when `theme` changes, which depends on the language.
    // It ensures that all videos are paused when the language changes.
    if (videoRefs.current) {
      videoRefs.current.forEach(video => {
        if (video && video.pause) {
          video.pause();
        }
      });
    }
  }, [theme]); // This will re-trigger whenever the theme changes (i.e., when the language changes).
*/
 
  useEffect(() => {
    try {
      let tempSizeArray = [(document.getElementById('title-theme-1').clientHeight + document.getElementById('0').clientHeight)];

      theme.forEach((elem, index) => {
        tempSizeArray.push(tempSizeArray[index] + document.getElementById(index).clientHeight);
      })
      setSizeArray(tempSizeArray);

    } catch (error) {
      console.log(error);
    }
  }, [currentTitle, theme]);

  // For videos - Uncertainly useful:-)
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [i18n.language]); // Dependency array with language, changes when language changes

  // For videos - Works on theme change due to language switch
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [theme]);

  useEffect(() => {
    const handleScroll = (event) => {

      sizeArray.forEach((elem, index) => {
        if (index >= 1) {
          if ((sizeArray[index - 1] < window.scrollY) && (window.scrollY < elem)) {
            setCurrentTitle(index);
            return;
          }
        } else if (window.scrollY < elem) {
          setCurrentTitle(index);
          return;
        }
      })
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sizeArray, theme]);


  return (
    <div className={styles.theme}>
      {/* Bar en noir */}
      <Theme theme="g100">
        <Grid className={styles.title} fullWidth id="title-theme-1">
          <Column lg={5} md={8} sm={4}>
            <h1>{t('theme1')}</h1>
            <br />
          </Column>
          <Column lg={7} md={8} sm={4}>
            {i18n.language === 'en' ? (
              <p>
                The aim is to automatically acquire symbolic knowledge from text
                documents or structured data, whether tables or graphs.
                <br />
                The work is structured around key concepts:
                <br />- <span>knowledge graphs</span>, comprising ontologies and
                data graphs, capturing concepts and their interrelationships to
                describe an area of expertise
                <br />- knowledge <span>extraction</span>: how to "understand"
                an ontology, logical rules or specific models such as
                organization models, from a textual document
                <br />- <span>induction</span>: how to learn actionable
                knowledge from a dataset, such as logical rules that can be
                applied to automate a decision.
              </p>
            ) : (
              <p>
                Le but est d’acquérir automatiquement des connaissances
                symboliques à partir de documents textuels ou des données
                structurées qu’elles soient des tables ou des graphes.
                <br /><br />
                Les travaux sont articulés autour des concepts clefs:
                <br />- les
                <span> graphes de connaissances</span>, comprenant des
                ontologies et des données graphes, capturant les concepts et
                leurs relations entre eux pour décrire un domaine de compétence.
                <br />- l’
                <span>extraction</span> de connaissance: comment “comprendre”
                d’un document textuel une ontologie, des règles logiques ou des
                modèles spécifiques comme des modèles d’organisation <br />- l’
                <span>induction</span>: comment apprendre à partir d’un jeu de
                données des connaissances actionnables comme des règles logiques
                que l’on peut appliquer pour automatiser une décision.
              </p>
            )}
            <br />
            <video
              src="https://aida-videos-challenges.s3.eu-de.cloud-object-storage.appdomain.cloud/Theme%201%20-%20Lot-B-Challenges_EN.mp4"
              title="video"
              width="100%"
              height="400px"
              allow="fullscreen"
              controls
            ></video>
          </Column>
          <Column lg={3} md={7} sm={3}></Column>
          <Column lg={1} md={1} sm={1} className={styles.numberBox}>
            <div className={styles.blueNumber}>1</div>
          </Column>
        </Grid>
      </Theme>

      {/* Les avancées d'AIDA */}
      <Theme theme="white">
        <Grid className={styles.navigationBox} fullWidth>
          <Column lg={13} md={4} sm={4}>
            {theme.map((th, index) => (
              <div className={styles.avanceBox} id={index} key={index}>
                <Grid fullWidth>
                  <Column lg={16} md={8} sm={4}>
                    <span>{t('advances')}</span>
                  </Column>
                </Grid>
                <Grid fullWidth>
                  <Column lg={3} md={2} sm={4}>
                    <h4>
                      {index + 1} - {th.title}
                    </h4>
                    <br />
                  </Column>
                  <Column lg={10} md={6} sm={4}>
                    {th.texte.map((texte, index2) => (
                      <p key={`p-text-${index2}`}>{texte}</p>
                    ))}
                    {th.demoTitle.length !== 0 ? (
                      <>
                        <br />
                        <h6>{th.demoTitle}</h6>
                        <p>
                          {th.demoDes}
                          <br />
                        </p>
                        <br />
                        <video
                          //ref={el => videoRefs.current[index] = el} // Assign refs to each video
                          //src={i18n.language === 'en' ? item.videoSrcEn : item.videoSrcFr}
                          //key={th.demoKey}
                          src={th.demoLink}
                          //src={i18n.language === 'en' ? th.demoLinkEn : th.demoLinkFr}
                          title="video"
                          width="100%"
                          height="400px"
                          allow="fullscreen"
                          //autoplay='0'
                          controls
                        ></video>
                        <br />
                      </>
                    ) : (
                      <></>
                    )}

                    {th.publicationsTitles.length !== 0 ? (
                      <>
                        <br />
                        <br />
                        <h6>Publications</h6>
                      </>
                    ) : (
                      <></>
                    )}

                    {th.publicationsTitles.map((linkTitle, index3) => (
                      <div key={`link-${index3}`}>
                        <Link href={th.publicationsLinks[index3]}>
                          {linkTitle} -{'>'}
                        </Link>
                        <br />
                        <span>{th.publicationAuthors[index3]}</span>
                        <br />
                        <br />
                      </div>
                    ))}
                    {th.perspectivesAuthor !== '' ? (
                      <>
                        <br />
                        <h6>Perspectives - {th.perspectivesAuthor}</h6>
                        <p>
                          {th.perspectivesText}
                          <br />
                        </p>
                        <br />
                        <br />
                      </>
                    ) : (
                      <></>
                    )}
                    {th.pointIBM ? <PoinIBM /> : <></>}
                  </Column>
                </Grid>
              </div>
            ))}
          </Column>

          <Column lg={3} md={4} sm={4}>
            <SideNav
              isFixedNav
              expanded={true}
              isChildOfHeader={false}
              aria-label="Side navigation"
            >
              <SideNavItems>
                {theme.map((t, index4) => (
                  <SideNavLink href={'#' + index4} key={`side-link-${index4}`}>
                    <p
                      className={
                        currentTitle === index4
                          ? styles.currentTitle
                          : styles.notCurrentTitle
                      }
                    >
                      {index4 + 1} - {t.title.slice(0, 25)} ...
                    </p>
                  </SideNavLink>
                ))}
              </SideNavItems>
            </SideNav>
          </Column>
        </Grid>

        {/* Nav pages */}
        <Grid className={styles.navigationBox} fullWidth>
          <Column lg={8} md={4} sm={4}></Column>
          <Column lg={8} md={4} sm={4} className={styles.nextNav}>
            <a href="/theme2">
              <Button kind="tertiary" className={styles.navButton}>
                {t('nextTheme')} : <br />
                {t('theme2')}
                <img
                  alt="arrow"
                  src="/images/icons/arrow--right.svg"
                  className={styles.arrowIcon}
                />
              </Button>
            </a>
          </Column>
        </Grid>
      </Theme>
    </div>
  );
};

export default Theme1;
