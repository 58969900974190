export const theme2_fr = [
  {
    title:
      'IA et confiance de l’utilisateur - Méthodologie "T"  [LSI - ENS] [SOFTEAM]',
    texte: [
      "L'évolution rapide de l’IA transforme un large éventail de services et s’invite dans les politiques publiques pour répondre aux enjeux sociétaux et commerciaux.  Cette transformation entraîne des changements dans les organisations complexes en créant de nouvelles fenêtres pour de nouvelles professions et de nouvelles méthodes de résolution de problèmes complexes. L'Union européenne accorde une grande importance à la confiance dans l'interaction entre l'humain et l'intelligence artificielle au sein de la société numérique et de la gouvernance augmentée.",
      <br />,
      "L'objectif visé ici est de pouvoir promouvoir une culture de confiance et d'engagement envers l'IA et la robotique, en impliquant les experts et les opérateurs dans des stratégies de formation, et des procédés de co-conception et d’évaluation des outils industriels.",
      <br />,
      "Partant du concept d'intelligence sociale, cette recherche souligne l'importance de combiner des compétences en éthique et en valeur avec des compétences méthodologiques et la nécessité d'une posture épistémologique pluraliste pour suivre le cycle de vie des connaissances et des institutions qui les intègrent.",
      <br />,
      'La méthodologie “AIDA T” (“T” comme “Trust”) est présentée comme un moyen de favoriser la confiance entre les agents humains et automatisés en établissant des critères normatifs pour garantir la qualité des actions augmentées. Elle est appliquée à une organisation à travers huit dimensions, qui ont été mises au point et validées dans plusieurs domaines professionnels.',
      <br />,
      'L’intelligence artificielle et la robotique interpellent la société en lui adressant trois défis majeurs.',
      '- Faire confiance à des intelligences qui bouleversent les mécanismes de contrôle et de prévention des risques et des erreurs',
      '- Combiner de manière intelligible et explicable d’une parte la compliance vis-à-vis de la règle du droit (notamment RGPD et AI Act en voie principale) et de l’autre part l’innovation et la conséquente attractivité sur les marchés globaux',
      '- Délivrer les biens et services aux citoyens et aux entreprises dans le respect des principes éthiques et dans la perspective de l’efficience.',
      <br />,
      'Le lot T de AIDA NE SE LIMITE PAS A REPONDRE A CES DEFIS. IL A ANTICIPE LA METHODOLOGIE QUI A INSPIRE LA REGULATION EUROPEENNE EN MATIERE DE IA.',
      '- Octogone de la confiance. Une méthodologie testée d’évaluation et de promotion des qualités que l’IA doit promouvoir une fois qu’elle devient partie d’une organisation ou d’un processus décisionnel. Cette méthodologie – nommée « T methodology » – est la base d’un projet sur lequel la coordinatrice du lot est très active qui vise à positionner l’Université Paris-Saclay dans le cadre d’un cas d’usage à haut impact sur la qualité de la gouvernance privée et publique – domaine santé.',
      '- Le cas d’usage applique la méthodologie AIDA TRUST aux salles d’urgence des hôpitaux. Il montre comment et avec quelles procédures il est possible de combiner efficience, respect de la normative européenne, responsabilité éthique, adaptation professionnelle et organisationnelle, dans un contexte d’une organisation du travail et de livraison des services et des réponses qui intègrent l’IA.',
      '- Menu de formation pour les usages. Grace au lot T AIDA offre aux usages un menu de formation et de change management qui fait le suivi de la transformation numérique dans les organisations – comme les hôpitaux – qui intègrent l’IA et ainsi transforment leur manière de livrer services aux citoyens.',
      <br />,
      'Quand le projet AIDA a été financé l’Union Européenne n’avait pas venu ni la pandémie ni la nécessité de se doter un cadre normatif harmonisateur des instances nationales dans le secteur de l’IA, de la robotique, et de l’automation.',
      <br />,
      "Le projet AIDA a été lancé par l'Union Européenne avant la pandémie et avant la prise de conscience de la nécessité d'un cadre réglementaire harmonisé au niveau national pour l'intelligence artificielle, la robotique et l'automatisation.",
      <br />,
      'La proposition de régulation européenne qui est en discussion aujourd’hui représente une opportunité extraordinaire de se positionner comme interlocuteur de visibilité nationale sur les axes de la normativité et de la confiance. En effet, dans la régulation, il y a un dispositif prévu qui nécessite de la combinaison des méthodologies liées aux sciences sociales et à la science de la formation et de l’apprentissage. Ce dispositif serait le mécanisme prévu par le régulateur Européen pour assurer que le design et la mise en œuvre de l’IA dans les différents secteurs de politique publique soit accompagnés par un parcours de test, évaluation participative, et de learning in itinere. Les résultats du Lot T sont alors un atout important pour faire monter à une échelle plus haute de visibilité et d’impact le rôle joué par AIDA dans la stratégie UE de la société numérique.',
      <br />,
      "Cette approche a ouvert la voie à un tout nouveau cadre de validation spécifiquement conçu pour ce secteur, nous permettant ainsi de réaliser des avancées significatives en matière de sandboxing des technologies IA. À l'heure actuelle, les autorités publiques européennes, en particulier l'Union Européenne, sont fortement engagées dans l'accélération de l'innovation et le déploiement des technologies de l'IA dans des secteurs cruciaux de la société. Elles le font tout en mettant un accent majeur sur le développement responsable, en accord avec les valeurs européennes, et en plaçant l'humain au cœur de leurs préoccupations. Cette approche novatrice ouvre des perspectives passionnantes pour Softeam, nous permettant d'envisager une gamme de services conçus pour accompagner les acteurs de la santé dans leurs projets de transformation vers des entités augmentées par l'IA, tout en maintenant une confiance essentielle.",
    ],
    demoTitle: 'Démonstrateur 2.1 : ',
    demoDes:
      '[LSI - ENS] [SOFTEAM] Outil de développement de checklists pour la construction de la confiance dans le déploiement d’agents IA, intégré à l’atelier de modélisation - cas d’étude secteur santé',
    demoLink:
      'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/SOFTEAM%20-%20AIDA%20Lot%20T.mp4',
    publicationsTitles: [
      'Rules for Trust: A New Compass for the Next Generation',
      'Building Trust in AI deployments in Healthcare',
    ],
    publicationsLinks: ['', ''],
    publicationAuthors: ['D. Piana', 'J. Cadavid, D. Piana, A. Abherve'],
    perspectivesAuthor: '',
    perspectivesText: [
      "",
    ],
    pointIBM: false,
  },
  {
    title: 'Méthode ObjectivAIze et logiciel Decision Coordination [IBM]',
    texte: [
      'Comment utiliser avec pertinence l’Humain, ou l’IA, ou une combinaison des 2, sur une base de performance dans la prise de décision?',
      'AIDA contribuer a progresser dans les buts suivants:',
      'a. Déterminer empiriquement les rôles respectifs de l’humain et de l’algorithme dans la prise de décision.',
      'b. Objectiver dans quelles conditions un modèle d’IA est plus performant qu’un analyste ou opérateur humain.',
      'c. Objectiver quand favoriser l’intervention humaine dans les cas où l’IA est moins effective.',
      <br />,
      'L’objectif de ces travaux de recherche, est de déterminer empiriquement les rôles respectifs de l’humain et de l’algorithme dans la prise de décision, et de proposer un composant logiciel qui permette « d’objectiver » dans quelles conditions un modèle d’IA est plus performant qu’un analyste ou opérateur humain et réciproquement d’objectiver quand favoriser l’intervention humaine dans les cas où l’IA est moins effective, et donc en résumé donner les moyens techniques de choisir quel processus de décision pour une décision donnée.',
      <br />,
      'Le logiciel Decision Coordination donne un support efficace pour réaliser cette objectivation empirique, et mettre au point les réglages dans la répartition des tâches de décision (entre automate et expert humain).',
    ],
    demoTitle: 'Démonstrateur 2.2 : ',
    demoDes:
      '[IBM] : Decision Coordination Decision Coordination est un logiciel développé dans le cadre d’AIDA qui permet de déterminer empiriquement les rôles respectifs de l’humain et de l’algorithme dans la prise de décision. Il permet « d’objectiver » dans quelles conditions un modèle d’IA est plus performant qu’un analyste ou opérateur humain et réciproquement d’objectiver quand favoriser l’intervention humaine dans les cas où l’IA est moins effective.',
    demoLink: 'https://www.youtube.com/watch?v=8lo1s29ODj8',
    publicationsTitles: [
      "ObjectivAIze : Déterminer empiriquement les rôles respectifs de l'humain et de l'algorithme dans la prise de décision ",
    ],
    publicationsLinks: [''],
    publicationAuthors: [
      'Thomas Baudel, Grégoire Colombet, Raphaël Hartmann, IBM France Lab, France, IHM ’22, April 05–08, 2022, Namur, Belgique',
    ],
    perspectivesAuthor: 'IBM',
    perspectivesText: [
      "Projet innovant pour aider les organisations à articuler la complementarité Humain & IA sur une base mesurable, aboutissant à une fonctionnalité IBM Decision Coordination.",
    ],
  },
];

export const theme2_en = [
  {
    title: 'AI and user confidence - "T" methodology [LSI - ENS] [SOFTEAM]',
    texte: [
      'The rapid evolution of AI is transforming a wide range of services and inviting itself into public policy to address societal and business challenges.  This transformation is driving change in complex organisations by creating new windows for new professions and new ways of solving complex problems. The European Union attaches great importance to trust in the interaction between humans and artificial intelligence within the digital society and augmented governance',
      <br />,
      'The aim here is to be able to promote a culture of trust and commitment to AI and robotics, by involving experts and operators in training strategies, and co-design and evaluation processes for industrial tools.',
      <br />,
      'Based on the concept of social intelligence, this research highlights the importance of combining skills in ethics and values with methodological skills and the need for a pluralist epistemological stance to follow the life cycle of knowledge and the institutions that integrate it',
      <br />,
      'The "T" methodology ("T" for "Trust") is presented as a means of fostering trust between human and automated agents by establishing normative criteria to guarantee the quality of augmented actions. It is applied to an organisation through eight dimensions, which have been developed and validated in several professional fields',
      <br />,
      'Artificial intelligence and robotics pose three major challenges to society',
      '- Trusting intelligences that revolutionise the mechanisms for controlling and preventing risks and errors',
      '- Combining compliance with the rule of law (in particular the RGPD and the AI Act) with innovation and consequent attractiveness on global markets in an intelligible and explainable way',
      '- Delivering goods and services to citizens and businesses in compliance with ethical principles and with a view to efficiency',
      <br />,
      "AIDA's Lot T is NOT LIMITED TO ANSWERING THESE CHALLENGES. IT HAS ANTICIPATED THE METHODOLOGY THAT HAS INSPIRED EUROPEAN IA REGULATION",
      "- Trust Octagon. A tested methodology for assessing and promoting the qualities that AI should promote once it becomes part of an organisation or a decision-making process. This methodology - known as the 'T methodology' - is the basis of a project in which the batch coordinator is very active, which aims to position Université Paris-Saclay in the context of a use case with a high impact on the quality of private and public governance - health sector",
      '- The use case applies the AIDA TRUST methodology to hospital emergency rooms. It shows how and with which procedures it is possible to combine efficiency, compliance with European standards, ethical responsibility, professional and organisational adaptation, in a context of work organisation and delivery of services and responses that incorporate AI.',
      '- Training menu for uses. Thanks to the T package, AIDA offers users a training and change management menu that monitors the digital transformation in organisations - such as hospitals - that are integrating AI and thus transforming the way they deliver services to citizens',
      <br />,
      'When the AIDA project was funded, the European Union had not come to grips with either the pandemic or the need to establish a harmonising regulatory framework for national authorities in the AI, robotics and automation sectors',
      <br />,
      'The AIDA project was launched by the European Union before the pandemic and before there was any awareness of the need for a harmonised regulatory framework at national level for artificial intelligence, robotics and automation.',
      <br />,
      "The proposal for European regulation under discussion today represents an extraordinary opportunity to position ourselves as an interlocutor with national visibility in the areas of standardisation and trust. In fact, the regulation includes a mechanism that requires a combination of methodologies linked to the social sciences and the science of training and learning. This device would be the mechanism planned by the European regulator to ensure that the design and implementation of AI in the various public policy sectors is accompanied by a process of testing, participatory evaluation and learning in itinere. The results of Batch T are therefore an important asset in raising the profile and impact of the role played by AIDA in the EU's digital society strategy",
      <br />,
      'This approach has paved the way for an entirely new validation framework specifically designed for this sector, enabling us to make significant advances in the sandboxing of AI technologies. At present, European public authorities, in particular the European Union, are strongly committed to accelerating innovation and the deployment of AI technologies in crucial sectors of society. They are doing so while placing a major emphasis on responsible development, in line with European values, and putting people at the heart of their concerns. This innovative approach opens up exciting prospects for Softeam, enabling us to envisage a range of services designed to support healthcare players in their transformation projects towards AI-enhanced entities, while maintaining essential trust.',
    ],
    demoTitle: 'Demonstrator 2.1 : ',
    demoDes:
      '[LSI - ENS] [SOFTEAM] Tool for to develop checklists for building trust in the deployment in the deployment of AI agents, integrated into the modelling workshop - health sector case study',
    demoLink:
      'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/SOFTEAM%20-%20AIDA%20Lot%20T_EN.mp4',
    publicationsTitles: [
      'Rules for Trust: A New Compass for the Next Generation',
      'Building Trust in AI deployments in Healthcare',
    ],
    publicationsLinks: ['', ''],
    publicationAuthors: ['D. Piana', 'J. Cadavid, D. Piana, A. Abherve'],
    pointIBM: false,
  },
  {
    title: 'ObjectivAIze method and Decision Coordination software [IBM].',
    texte: [
      'Comment utiliser avec pertinence l’Humain, ou l’IA, ou une combinaison des 2, sur une base de performance dans la prise de décision?',
      'AIDA contribuer a progresser dans les buts suivants:',
      'a. Déterminer empiriquement les rôles respectifs de l’humain et de l’algorithme dans la prise de décision.',
      'b. Objectiver dans quelles conditions un modèle d’IA est plus performant qu’un analyste ou opérateur humain.',
      'c. Objectiver quand favoriser l’intervention humaine dans les cas où l’IA est moins effective.',
      <br />,
      'The aim of this research work is to determine empirically the respective roles of humans and algorithms in decision-making, and to propose a software component that can "objectify" the conditions under which an AI model is more effective than a human analyst or operator, and conversely to objectify when to favour human intervention in cases where AI is less effective, and therefore, in short, to provide the technical means to choose which decision-making process to use for a given decision',
      <br />,
      'The Decision Coordination software provides effective support for carrying out this empirical objectivation, and fine-tuning the distribution of decision-making tasks (between the robot and the human expert)',
    ],
    demoTitle: 'Démonstrateur 2.2 : ',
    demoDes:
      "[IBM]: Decision Coordination Decision Coordination is a software software developed as part of the AIDA project, which makes it possible to determine empirically determine the respective roles of humans and algorithm in decision making. It makes it possible to 'objectify' under what conditions an AI model performs better than an analyst or than a human analyst or operator, and vice versa objectivise when to favour human intervention in cases where where AI is less effective.",
    demoLink: 'https://www.youtube.com/watch?v=8lo1s29ODj8',
    publicationsTitles: [
      'ObjectivAIze: Empirically determining the respective roles of humans and algorithms in decision-making',
    ],
    publicationsLinks: [''],
    publicationAuthors: [
      'Thomas Baudel, Grégoire Colombet, Raphaël Hartmann, IBM France Lab, France, IHM ’22, April 05–08, 2022, Namur, Belgique',
    ],
    perspectivesAuthor: 'IBM',
    perspectivesText: [
      "Innovative project to help organizations articulate the complementarity of Human & AI on a measurable basis, leading to a Decision Coordination functionality.",
    ],
    pointIBM: true,
  },
];
