export const theme10_fr = [
    {
      title: 'DETECTER LES FRAUDES',
      texte: [
        "Le projet intègre l'apprentissage automatique et un moteur de règles pour induire des règles à partir de données historiques, qui sont ensuite évaluées et potentiellement modifiées par des experts avant d'être mises en production. ",
        "Un prototype d'inducteur de règles a été développé dans le cadre de la thèse RETRO dirigée par IBM France Lab, avec l'objectif de tester des algorithmes d'induction de règles dans un environnement sécurisé de détection de fraude bancaire.",
        "Le problème d'induction de règles a démarré par l'apprentissage de règles métier à partir de données tabulaires labellisées, en utilisant des techniques telles que CN2 et RIPPER. Ces méthodes ont été explorées pour proposer des règles transparentes et auditables, applicables en temps réel. ",
        "Le projet utilise également des datasets open-source fictifs pour les tests initiaux, avant d'expérimenter sur des données réelles.",
        "L’algorithme RIPPER est un algorithme d’induction éprouvé́ et public. Dans ce projet nous l’avons perfectionné en l’associant à des paramétrages et à des pre-processus différents de données, pour avoir plus de flexibilité dans sa configuration et optimiser ses temps d’apprentissage. ",
        "Les échanges avec STET et les résultats obtenus pour l’algorithme RIPPER sur la plateforme sécurisée ont montré que le contexte d’apprentissage des règles de fraudes comprenait des données fortement non balancées ",
        "(environ 1 cas de fraude pour 3000 événements) insérées dans un flux bruité (pas de déterminisme parfait entre les valeurs d’entrée et la valeur de fraude). ",
        "Cette dernière caractéristique est à l’origine de nombreux cas de fausse prédiction de fraude durant l’exploitation des règles (environ 1 cas vrai positif pour 10 faux).",
        "RIPPER, qui est un algorithme généraliste, n’est pas bien adapté pour traiter le bruit. C’est probablement la cause de la complication parfois excessive observée pour les conditions des règles.",
        "Sur la base des résultats analysés IBM a travaillé sur 2 nouveaux algorithmes:",
        "Hyper Rule Search (HRS), avec un apprentissage de schémas de fraude non deterministes et de basse frequence,R2N, une approche à base de réseaux de neurones pour l’induction de règles logiques basées sur l’apprentissage automatique de séquences temporelles",
        "En résumé, ce projet a permis d’identifier l’état de l’art, et d’améliorer la détection et la prévention de la fraude bancaire grâce à une combinaison d'IA et d'apprentissage automatique, tout en maintenant la transparence et l'auditabilité nécessaires dans le domaine financier.",
        ],
      demoTitle:
        'Démonstrateur Detection de fraudes [IBM]',
      demoDes: '',
      demoLink:
        'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/IBM-FraudDetection-PhilippeBonnard.mp4',
      publicationsTitles: [
        "Apprentissage de règles pour la détection de fraudes",
      ],
      publicationsLinks: ['https://hal.science/hal-03298747/document', '', '', ''],
      publicationAuthors: [
        'Marine Collery, Philippe Bonnard, François Fages ',
      ],
      perspectivesAuthor: '',
      perspectivesText: [],
    },
    ];  

  export const theme10_en = [
    {
      title: 'DETECTING FRAUD',
      texte: [
      "The project integrates machine learning and a rule engine to induce rules from historical data, which are then evaluated and potentially modified by experts before being deployed in production. ",
      "A prototype rule inducer was developed as part of the RETRO thesis led by IBM France Lab, with the goal of testing rule induction algorithms in a secure environment for bank fraud detection.",
      "The rule induction problem began with learning business rules from labeled tabular data, using techniques such as CN2 and RIPPER. These methods were explored to propose transparent and auditable rules, applicable in real-time. ",
      "The project also uses fictional open-source datasets for initial testing, before experimenting with real data.",
      "The RIPPER algorithm is a proven and public induction algorithm. In this project, we refined it by combining it with different settings and data pre-processing, to provide more flexibility in its configuration and optimize its learning times. ",
      "Exchanges with STET and the results obtained for the RIPPER algorithm on the secure platform showed that the learning context for fraud rules included heavily imbalanced data ",
      "(about 1 fraud case for every 3000 events) inserted in a noisy flow (no perfect determinism between input values and the value of fraud). ",
      "This last characteristic is the cause of many false fraud predictions during the exploitation of the rules (about 1 true positive case for every 10 false).",
      "RIPPER, being a generalist algorithm, is not well adapted for handling noise. This is probably the reason for the sometimes excessive complication observed in the conditions of the rules.",
      "Based on the analyzed results, IBM worked on 2 new algorithms:",
      "Hyper Rule Search (HRS), with learning of non-deterministic and low-frequency fraud patterns, and R2N, a neural network-based approach for the induction of logical rules based on the machine learning of temporal sequences",
      "In summary, this project has enabled the identification of the state of the art, and improved the detection and prevention of bank fraud through a combination of AI and machine learning, while maintaining the transparency and auditability necessary in the financial field.",
      ],
      demoTitle:
      'Fraud Detection Demonstrator [IBM]',
      demoDes: '',
      demoLink:
      'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/IBM-FraudDetection-PhilippeBonnard_EN.mp4',
      publicationsTitles: [
      "Rule Learning for Fraud Detection",
      ],
      publicationsLinks: ['https://hal.science/hal-03298747/document', '', '', ''],
      publicationAuthors: [
      'Marine Collery, Philippe Bonnard, François Fages ',
      ],
      perspectivesAuthor: '',
      perspectivesText: [],
      },
  ];