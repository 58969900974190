export const theme3_fr = [
    {
      title: 'MODÉLISER LES ORGANISATIONS, PRÉPARER L’AVÈNEMENT D’ORGANISATIONS HYBRIDES HUMAIN / IA',
      texte: [
        'A. Un langage de modélisation AIDA basé sur des standards',
        "Softeam présente le méta-modèle BPM/DCM étendu (BDAMM) pour la représentation des architectures d'entreprise et l'intégration d'agents intelligents opérationnels (OIA) dans les processus métier. ",
        "Notre langage prend en charge différentes notations standard, notamment TOGAF et ArchiMate pour l'architecture d'entreprise, BPMN ou DCM pour la modélisation des processus métier, ainsi qu'UML pour la modélisation des systèmes.",
        "B. L’atelier de modélisation AIDA",
        "Softeam a développé un atelier de modélisation basé sur le langage AIDA en utilisant son produit Modelio. Cet atelier propose des fonctionnalités telles que la génération de documentation, la traçabilité, la collaboration en équipe et la gestion de versions. ",
        "De plus, il offre un support complet de la méthodologie AIDA, permettant aux organisations de planifier intégralement leur projet de transformation en modélisant la situation actuelle («tel quel») et la situation envisagée («à venir»). L'atelier facilite ensuite l'identification des trajectoires de transformation et l'analyse de l'impact des changements envisagés.",
        "C. Intégration avec la gestion d’agents intelligents de la plateforme AIDA.",
        "L'atelier de modélisation s'intègre également avec le composant open source de gestion d'agents intelligents, développé par IBM. Cette intégration permet aux utilisateurs de l'atelier de modélisation d'accéder au référentiel d'agents intelligents, à leurs capacités (\"skills\") et de les déployer en tant que \"digital workers\" dans le modèle d'architecture d'entreprise.",
        "D. Automatisation dans la construction de modèles d’architecture d’entreprise.",  
        "Grâce à cette intégration, l'initialisation automatique de modèles d'architecture d'entreprise à partir de documents textuels est rendue possible. Cette innovation, réalisée au moyen de techniques de traitement automatique du langage naturel (NLP) par UPS, offre aux utilisateurs de l'atelier de modélisation un gain de temps considérable lors de la conception de leurs modèles d'entreprise.",
        "En capitalisant sur la base documentaire préexistante au sein de l'entreprise, ce module facilite considérablement le processus de modélisation.",
        "E. Intégration avec le module de recommendations d’AIDA.",
        "Softeam a intégré un module client de production automatique de recommandations en développement par UPS. Cette avancée permet aux utilisateurs de l'atelier de modélisation de bénéficier de recommandations automatiques pour leurs modèles d'architecture d'entreprise, renforçant ainsi la qualité et la pertinence de leurs conceptions. Grâce à ce module, les utilisateurs peuvent obtenir des recommandations pertinentes pour créer des modèles d'architecture plus complets et précis.",
        "F. Accompagnement pour la construction de la confiance dans l’IA.  L'Atelier de Modélisation AIDA a réalisé une avancée en intégrant une fonctionnalité qui permet la génération de Listes de Vérification de Qualité spécialisées. Ces listes sont adaptées à la structure organisationnelle et aux besoins spécifiques de chaque entreprise. Cette innovation sert un double objectif : ",
        "- premièrement, renforcer les initiatives continues de transformation organisationnelle ;",
        " et deuxièmement, établir une base solide et fiable de confiance dans l'intégration transparente de l'intelligence artificielle au sein du système d'information de l'entreprise. Cette initiative est menée en étroite collaboration avec le Lot T du projet AIDA global. ",
        "Les listes de vérification produites par le biais de ce processus sont adaptées ",
        "aux spécificités inhérentes au projet de transformation de chaque organisation. Elles couvrent de manière exhaustive plusieurs dimensions, toutes conçues pour favoriser la culture de la confiance. Ces dimensions englobent la sécurité, la robustesse, l'alignement sur les valeurs fondamentales et le respect des pratiques légales",
      ],
      demoTitle:
        'Atelier de modélisation AIDA pour l’accompagnement des organisations dans leur transformation vers des organisations hybrides humain-IA [SOFTEAM]',
      demoDes: 'Atelier de modélisation',
      demoLink:
        'https://aida-videos-challenges.s3.eu-de.cloud-object-storage.appdomain.cloud/Theme%203%20-%20AIDA%20challenge%20-%20v2.mp4',
      publicationsTitles: [
        "",
      ],
      publicationsLinks: ['', '', '', ''],
      publicationAuthors: [
      ],
      perspectivesAuthor: 'Softeam',
      perspectivesText: [
        "Point de vue de Softeam sur l’avancement des recherches et l’horizon d’application : Softeam est convaincu du potentiel commercial de l’atelier de modélisation pour constituer une solution d’outils et services pour accompagner les entreprises dans leur transformation. ",
        "En ce qui concerne l’intégration avec les prototypes développés par les partenaires académiques, Softeam réalise actuellement des études de faisabilité pour une industrialisation des intégrations réalisées.",
        ],
    },
    {
        title: 'METHODOLOGIE AIDA',
        texte: [
            "SOFTEAM a développé un guide méthodologique étape par étape qui aide les architectes d'entreprise à appliquer le méta-modèle sur des organisations et des systèmes commerciaux spécifiques. ",
            "Cette méthodologie est définie comme un ensemble de tâches exécutées dans un ordre spécifique pour répondre au besoin d’accompagner les entreprises dans leur transformation vers des organisations hybrides humain-IA. ",
            "La méthodologie BPM/DCM augmentée peut être utilisée pour aider à transformer un système ",
            "d'information grâce au Prototype du Moteur Méthodologique, basé sur l’atelier de modélisation AIDA. Cette extension permet la visualisation,  l'instanciation et l'exécution d'une méthodologie, en connectant les modèles développés aux documents utilisés et à la méthodologie. ",
            "L'extension du Moteur Méthodologique propose plusieurs services, notamment le Gestionnaire de Méthodologies, le Moteur de Workflow, le Gestionnaire de Documents, le service de Modèles et le Gestionnaire d'Audit. ",
            "Le Workflow Méthodologique représente l'instanciation d'une méthodologie sur un projet, avec des tâches ayant des prédécesseurs et des successeurs. ",
            "La méthodologie peut faire référence aux modèles/diagrammes à mettre à jour et utiliser des règles d'audit et des indicateurs qualitatifs pour surveiller l'avancement.", 
            "Les documents sont des blocs de modèles, des diagrammes ou des ressources externes nécessaires aux tâches et ont un cycle de vie avec des états. ",
            "Des règles d'audit sont attribuées aux tâches pour valider les actions pendant la modélisation, et un processus de révision a lieu à la fin d'une phase/tâche.", 
            "Le Référentiel de Méthodologies est un service pour héberger, centraliser et diffuser les méthodologies, tandis que les méthodologies sont documentées sous forme d'une application web.",
            ],
        demoTitle:
          'Intégration avec le module de référentiel d’Open Agents d’AIDA et leur déploiement dans l’atelier de modélisation [SOFTEAM]',
        demoDes: '',
        demoLink:
          'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/SOFTEAM%20-%20AIDA%20Open%20Agents%20Registry.mp4',
        publicationsTitles: [
          " (2022). A Methodology for Modeling Digital Transformation of Organizations to Integrate Automated Decision-Making Tools based on Artificial Intelligence. In RCIS Workshops.",
        ],
        publicationsLinks: ['https://rcis-conf.com/rcis2022/RCIS-WS&RP2022/RP-paper1.pdf', '', '', ''],
        publicationAuthors: [
          'Abhervé, A., Said, B., & Bagnato, A ',
        ],
        perspectivesAuthor: 'UPS',
        perspectivesText: [
          "La méthodologie AIDA fait une partie intégrante de l’atelier de modélisation, qui jouit du potentiel de commercialisation présenté dans la section précédente. ",
          "De plus, cette méthodologie offre à Softeam une possibilité pour développer une offre de services connexes à l’atelier de modélisation.",
          ],
      },
      {
        title: 'CAS D’ÉTUDE DE VALIDATION DE LA MÉTHODOLOGIE ET L’ATELIER DE MODÉLISATION AIDA',
        texte: [
            "Le cas d’étude de gestion RH proposé par SOFTEAM afin de valider l’approche permet de modéliser les structures et fonctions globales des unités administratives et opérationnelles. Dans le cas RH cet inventaire couvre les principales fonctions commerciales et services nécessaires liés au recrutement de nouveaux collaborateurs, à leur intégration dans l'entreprise, jusqu'à leur éventuel départ. ",
            "Le modèle proposé exprime les objectifs et les ambitions de Softeam en matière de recrutement et de gestion des RH, ainsi que les exigences et les règles commerciales du système. Nous avons ensuite capturé les différentes fonctions commerciales et services et leurs relations avec les exigences, les utilisateurs finaux et les composants d'application et de données sous-jacents. Ensuite, nous avons montré comment ces composants sont accessibles via l'infrastructure technologique depuis le siège principal et les différentes agences de Softeam. ",
            "Enfin, nous avons détaillé les schémas des différents processus commerciaux liés à GDSRH et ses modèles de données logiques. Au delà de l’analyse des processus l’ambition est de reconnaître les points chauds où l'intégration d'optimisations basées sur l'IA serait bénéfique, en anticipant les transformations nécessaires pour intégrer de telles améliorations basées sur l'IA, puis nous les appliquerons efficacement et enfin nous surveillerons et évaluerons les gains des améliorations.",
            "Le cas d'étude a porté sur le développement complet d'une amélioration basée sur l'IA, à savoir un assistant pour le traitement des données de recrutement. Cet assistant permet l'extraction automatique de résumés à partir des CV des candidats. Cette fonctionnalité offre un gain de temps significatif aux professionnels des ressources humaines en automatisant une tâche laborieuse.",
            ],
        demoTitle: [
          "Initialisation de modèles d’architecture d’entreprise à partir de l’extraction de concepts sur documents d’entreprise [SOFTEAM]",
       ],
        demoDes: '',
        demoLink: [
          'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/SOFTEAM%20-%20AIDA%20Extraction%20de%20concepts.mp4',
        ],
        publicationsTitles: [
          " (2022). A Methodology for Modeling Digital Transformation of Organizations to Integrate Automated Decision-Making Tools based on Artificial Intelligence. In RCIS Workshops.",
        ],
        publicationsLinks: ['https://rcis-conf.com/rcis2022/RCIS-WS&RP2022/RP-paper1.pdf', '', '', ''],
        publicationAuthors: [
          'Abhervé, A., Said, B., & Bagnato, A ',
        ],
        perspectivesAuthor: 'Softeam',
        perspectivesText: [
          "Le cas d’étude a permis aux consultants de Softeam, en charge de la modernisation des services et systèmes d’information internes, d’apprécier l’utilité de la méthodologie AIDA pour planifier l’adoption des technologies IA dans un service spécifique de l’entreprise, en l’occurrence le service RH.",
          "Ils ont également apprécié le fait d’avoir pu obtenir un premier prototype développé, en état fonctionnel, de l’un des agents IA projetés, qui permet d’appréhender rapidement les avantages de déployer ces technologies. ",
          ],
      },
    ];  

  export const theme3_en = [
    {
      title: 'MODELING ORGANIZATIONS, PREPARING FOR THE ADVENT OF HYBRID HUMAN/IA ORGANIZATIONS',
      texte: [
        'A. A standards-based AIDA modeling language',
        "Softeam presents the extended BPM/DCM meta-model (BDAMM) for representing enterprise architectures and integrating intelligent operational agents (OIAs) into business processes. ",
        "Our language supports various standard notations, including TOGAF and ArchiMate for enterprise architecture, BPMN or DCM for business process modeling, as well as UML for system modeling.",
        "B. The AIDA modeling workshop",
        "Softeam has developed a modeling workshop based on the AIDA language, using its Modelio product. This workshop offers functionalities such as documentation generation, traceability, team collaboration and version management. ",
        'In addition, it offers full support for the AIDA methodology, enabling organizations to fully plan their transformation project by modeling the current situation ("as is") and the envisaged situation ("to come"). The workshop then facilitates the identification of transformation trajectories and the analysis of the impact of the envisaged changes',
        "C. Integration with the AIDA platform's intelligent agent management",
        "The modeling workshop also integrates with the open source intelligent agent management component developed by IBM. This integration enables Model Builder users to access the repository of intelligent agents and their skills, and to deploy them as digital workers in the enterprise architecture model.",
        "D. Automation in the construction of enterprise architecture models",  
        "Thanks to this integration, the automatic initialization of enterprise architecture models from text documents is made possible. This innovation, achieved using UPS's automatic natural language processing (NLP) techniques, offers model shop users considerable time savings when designing their enterprise models.",
        "By capitalizing on the pre-existing document base within the company, this module considerably facilitates the modeling process.",
        "E. Integration with AIDA's recommendations module",
        "Softeam has integrated a client module for the automatic production of recommendations in UPS development. This enhancement enables users of the modeling workshop to benefit from automatic recommendations for their enterprise architecture models, thus reinforcing the quality and relevance of their designs. Thanks to this module, users can obtain relevant recommendations to create more complete and accurate architecture models.",
        "F. Building trust in AI.  The AIDA Modeling Workshop has made a breakthrough by integrating functionality that enables the generation of specialized Quality Checklists. These lists are tailored to the organizational structure and specific needs of each company. This innovation serves a dual purpose: ",
        "- firstly, to reinforce ongoing organizational transformation initiatives;",
        "and secondly, to establish a solid and reliable basis of trust in the seamless integration of artificial intelligence within the enterprise information system. This initiative is being carried out in close collaboration with Lot T of the global AIDA project. ",
        "The checklists produced through this process are tailored ",
        "to the specificities inherent in each organization's transformation project. They comprehensively cover several dimensions, all designed to foster a culture of trust. These dimensions include security, robustness, alignment with core values and compliance with legal practices",
      ],
      demoTitle:
      'AIDA modeling workshop to support organizations in their transformation towards hybrid human-IA organizations [SOFTEAM]',
      demoDes:  'Modelling workshop',
      demoLink:
        'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/SOFTEAM-Atelier-de-Modelisation-AIDA_EN.mp4',
      publicationsTitles: [
        "",
      ],
      publicationsLinks: ['', '', '', ''],
      publicationAuthors: [
      ],
      perspectivesAuthor: 'Softeam',
      perspectivesText: [
        "Softeam's view on research progress and application horizon: Softeam is convinced of the commercial potential of the modeling workshop to constitute a solution of tools and services to support companies in their transformation. ",
        "Regarding integration with prototypes developed by academic partners, Softeam is currently carrying out feasibility studies for industrialization of the integrations achieved.",
        ],
    },
    {
        title: 'METHODOLOGIE AIDA',
        texte: [
            "SOFTEAM has developed a step-by-step methodological guide that helps enterprise architects apply the meta-model to specific business organizations and systems. ",
            "This methodology is defined as a set of tasks performed in a specific order to meet the need to support companies in their transformation to hybrid human-IA organizations. ",
            "Augmented BPM/DCM methodology can be used to help transform a system",
            " of information thanks to the Methodology Engine Prototype, based on the AIDA modeling workshop. This extension enables the visualization, instantiation and execution of a methodology, by connecting the models developed to the documents used and to the methodology. ",
            "The Methodology Engine extension offers several services, including the Methodology Manager, Workflow Engine, Document Manager, Template service and Audit Manager. ",
            "The Methodology Workflow represents the instantiation of a methodology on a project, with tasks having predecessors and successors. ",
            "The methodology can refer to the models/diagrams to be updated and use audit rules and qualitative indicators to monitor progress.", 
            "Documents are blocks of models, diagrams or external resources needed for tasks and have a lifecycle with states. ",
            "Audit rules are assigned to tasks to validate actions during modeling, and a review process takes place at the end of a phase/task.", 
            "The Methodology Repository is a service for hosting, centralizing and distributing methodologies, while methodologies are documented in the form of a web application.",
            ],
        demoTitle:
          "Integration with AIDA's Open Agents repository module and deployment in the modeling workshop [SOFTEAM].",
        demoDes: '',
        demoLink:
          'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/SOFTEAM%20-%20AIDA%20Open%20Agents%20Registry_EN.mp4',
        publicationsTitles: [
          " (2022). A Methodology for Modeling Digital Transformation of Organizations to Integrate Automated Decision-Making Tools based on Artificial Intelligence. In RCIS Workshops.",
        ],
        publicationsLinks: ['https://rcis-conf.com/rcis2022/RCIS-WS&RP2022/RP-paper1.pdf', '', '', ''],
        publicationAuthors: [
          'Abhervé, A., Said, B., & Bagnato, A ',
        ],
        perspectivesAuthor: 'UPS',
        perspectivesText: [
          "The AIDA methodology is an integral part of the modeling workshop, which enjoys the marketing potential outlined in the previous section. ",
          "In addition, this methodology offers Softeam an opportunity to develop a range of services related to the modeling workshop",
          ],
      },
      {
        title: 'METHODOLOGY VALIDATION CASE STUDY AND AIDA MODELING WORKSHOP',
        texte: [
            "The HR management case study proposed by SOFTEAM to validate the approach models the global structures and functions of administrative and operational units. In the case of HR, this inventory covers the main business functions and services required for recruiting new employees, integrating them into the company, through to their eventual departure. ",
            "The proposed model expresses Softeam's objectives and ambitions in terms of recruitment and HR management, as well as the system's requirements and business rules. We then captured the different business functions and services and their relationships with requirements, end-users and the underlying application and data components. Then we showed how these components are accessed via the technology infrastructure from Softeam's head office and various branches. ",
            "Finally, we detailed the schematics of the various business processes linked to GDSRH and its logical data models. Beyond process analysis the ambition is to recognize hot spots where the integration of AI-based optimizations would be beneficial, anticipating the transformations needed to integrate such AI-based improvements, then we will apply them effectively and finally monitor and evaluate the gains of the improvements.",
            "The case study focused on the complete development of an AI-based enhancement, namely an assistant for processing recruitment data. This assistant enables the automatic extraction of summaries from candidate CVs. This functionality offers significant time savings to human resources professionals by automating a laborious task",
            ],
        demoTitle: [
          "Initialization of enterprise architecture models from concept extraction on enterprise documents [SOFTEAM]",
       ],
        demoDes: '',
        demoLink: [
          'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/SOFTEAM%20-%20AIDA%20Extraction%20de%20concepts_EN.mp4',
        ],
        publicationsTitles: [
          " (2022). A Methodology for Modeling Digital Transformation of Organizations to Integrate Automated Decision-Making Tools based on Artificial Intelligence. In RCIS Workshops.",
        ],
        publicationsLinks: ['https://rcis-conf.com/rcis2022/RCIS-WS&RP2022/RP-paper1.pdf', '', '', ''],
        publicationAuthors: [
          'Abhervé, A., Said, B., & Bagnato, A ',
        ],
        perspectivesAuthor: 'Softeam',
        perspectivesText: [
          "The case study enabled Softeam's consultants, in charge of modernizing internal services and information systems, to appreciate the usefulness of the AIDA methodology for planning the adoption of AI technologies in a specific company department, in this case the HR department.",
          "They also appreciated the fact that they were able to obtain a first developed prototype, in working condition, of one of the projected AI agents, which enabled them to quickly grasp the benefits of deploying these technologies. ",
          ],
      },
  ];