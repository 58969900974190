import styles from './Theme.module.scss';
import { Theme, Grid, Column, Link, Button, SideNav, SideNavItems, SideNavLink } from '@carbon/react';
//import PoinDecisionBrain from '../../Components/PointDecisionBrain/PointDecisionBrain';
import { theme9_en, theme9_fr } from '../../Data/theme9';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const Theme9 = () => {
  const { t, i18n } = useTranslation();
  const theme = i18n.language === "en" ? theme9_en : theme9_fr;
  const [currentTitle, setCurrentTitle] = useState(0);
  const [sizeArray, setSizeArray] = useState([]);

  useEffect(() => {
    try {
      let tempSizeArray = [(document.getElementById('title-theme-9').clientHeight + document.getElementById('0').clientHeight)];

      theme.forEach((elem, index) => {
        tempSizeArray.push(tempSizeArray[index] + document.getElementById(index).clientHeight);
      })
      setSizeArray(tempSizeArray);
    } catch (error) {
      console.log(error);
    }
  }, [currentTitle, theme]);

  useEffect(() => {
    const handleScroll = (event) => {

      sizeArray.forEach((elem, index) => {
        if (index >= 1) {
          if ((sizeArray[index - 1] < window.scrollY) && (window.scrollY < elem)) {
            setCurrentTitle(index);
            return;
          }
        } else if (window.scrollY < elem) {
          setCurrentTitle(index);
          return;
        }
      })
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sizeArray, theme]);

  // For videos - Uncertainly useful:-)
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [i18n.language]); // Dependency array with language, changes when language changes

  // For videos - Works on theme change due to language switch
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [theme]);

  return (
    <div className={styles.theme}>
      {/* Bar en noire */}
      <Theme theme="g100">
        <Grid className={styles.title} fullWidth id="title-theme-">
          <Column lg={5} md={8} sm={4}>
            <h1>{t('theme9')}</h1>
            <br />
          </Column>
          <Column lg={7} md={8} sm={4}>
            {i18n.language === 'en' ? (
            <p>
              DecisionBrain, an innovative company in the field of optimization and machine learning, has developed within the AIDA project advanced strategies and tools focused on three key areas:
              <br />- <span>Data Management</span>, to facilitate the collection, monitoring, preparation, and orchestration of data, thus ensuring the quality and reliability of the information necessary for optimization. 
              AIDA has evolved methodologies and specific tools for anomaly detection, verification, and data completion, thus covering all aspects of their life cycle.
              <br />- <span>Optimization and Machine Learning Platform</span>: Thanks to the AIDA project, DecisionBrain has enhanced its platform to combine data, analysis, optimization, and machine learning. 
              The goal is to facilitate the implementation of optimization solutions, with extended capabilities for scenario management and monitoring of compute-intensive tasks.
              <br />- <span>Human Resources Optimization</span>, adapted to various sectors such as insurance, banking, and the public sector. 
              This optimization, based on advanced mathematical models for personnel planning, responds to strategic, tactical, and operational needs. 
              A concrete example is its application in the management of environmental audits in Singapore, demonstrating its effectiveness in the planning and optimization of tasks and resources in real-world conditions.
            </p>
            ) : (
            <p>
              DecisionBrain, une entreprise innovante dans le domaine de l'optimisation et du machine learning, a développé dans le cadre du projet AIDA des stratégies et des outils avancés axés sur trois domaines clés :
              <br />- <span>Gestion des données</span>, pour faciliter la collecte, le monitoring, la préparation et l'orchestration des données, garantissant ainsi la qualité et la fiabilité des informations nécessaires pour l'optimisation. 
              AIDA a permet de faire évoluer la méthodologies et les outils spécifiques pour la détection d'anomalies, la vérification et leur completion des données, couvrant ainsi tous les aspects de leur cycle de vie.
              <br />- <span>Plateforme d’optimisation et machine learning</span>: Grâce au projet AIDA, DecisionBrain a amélioré sa plateforme pour combiner données, analyses, optimisation et apprentissage automatique. 
              Le but est de faciliter la mise en œuvre des solutions d'optimisation, avec des capacités étendues pour la gestion de scénarios et la surveillance des tâches de calcul intensif.
              <br />- <span>Optimisation des ressources humaines</span>, adaptée à divers secteurs comme l'assurance, la banque et le secteur public. 
              Cette optimisation basée sur des modèles mathématiques avancés pour la planification du personnel, répondant à des besoins stratégiques, tactiques et opérationnels. 
              Un exemple concret est son application dans la gestion des audits environnementaux à Singapour, démontrant son efficacité dans la planification et l'optimisation des tâches et des ressources en conditions réelles.
              </p>
            )}
           {i18n.language === 'en' ? (
              <video
                src="https://aida-videos-challenges.s3.eu-de.cloud-object-storage.appdomain.cloud/Theme%209%20-%20New%20Release%20DB%20Gene%204.0.2%20fp2_EN.mp4"
                title="video"
                width="100%"
                height="400px"
                allow="fullscreen"
                controls
              ></video>
              ) : (
                <video
                src="https://aida-videos-challenges.s3.eu-de.cloud-object-storage.appdomain.cloud/Theme%209%20-%20New%20Release%20DB%20Gene%204.0.2%20fp2_EN.mp4"
                title="video"
                width="100%"
                height="400px"
                allow="fullscreen"
                controls
              ></video>
            )}
          </Column>
          <Column lg={3} md={7} sm={3}></Column>
          <Column lg={1} md={1} sm={1} className={styles.numberBox}>
            <div className={styles.blueNumber}>9</div>
          </Column>
        </Grid>
      </Theme>

      {/* Les avancées d'AIDA */}
      <Theme theme="white">
        <Grid className={styles.navigationBox} fullWidth>
          <Column lg={13} md={4} sm={4}>
            {theme.map((th, index) => (
              <div className={styles.avanceBox} id={index} key={index}>
                <Grid fullWidth>
                  <Column lg={16} md={8} sm={4}>
                    <span>{t('advances')}</span>
                  </Column>
                </Grid>
                <Grid fullWidth>
                  <Column lg={3} md={2} sm={4}>
                    <h4>
                      {index + 1} - {th.title}
                    </h4>
                    <br />
                  </Column>
                  <Column lg={10} md={6} sm={4}>
                    {th.texte.map((texte, index2) => (
                      <p key={`p-text-${index2}`}>{texte}</p>
                    ))}
                    {th.demoTitle.length !== 0 ? (
                      <>
                        <br />
                        <h6>{th.demoTitle}</h6>
                        <p>
                          {th.demoDes}
                          <br />
                        </p>
                        <br />
                        <video
                          src={th.demoLink}
                          title="video"
                          width="100%"
                          height="400px"
                          allow="fullscreen"
                          controls
                        ></video>
                        <br />
                      </>
                    ) : (
                      <></>
                    )}

                    {th.publicationsTitles.length !== 0 ? (
                      <>
                        <br />
                        <br />
                        <h6>Publications</h6>
                      </>
                    ) : (
                      <></>
                    )}

                    {th.publicationsTitles.map((linkTitle, index3) => (
                      <div key={`link-${index3}`}>
                        <Link href={th.publicationsLinks[index3]}>
                          {linkTitle} -{'>'}
                        </Link>
                        <br />
                        <span>{th.publicationAuthors[index3]}</span>
                        <br />
                        <br />
                      </div>
                    ))}
                    {th.perspectivesAuthor !== '' ? (
                      <>
                        <br />
                        <h6>Perspectives - {th.perspectivesAuthor}</h6>
                        <p>
                          {th.perspectivesText}
                          <br />
                        </p>
                        <br />
                        <br />
                      </>
                    ) : (
                      <></>
                    )}
                    {th.pointDecisionBrain ? <pointDecisionBrain /> : <></>}
                  </Column>
                </Grid>
              </div>
            ))}
          </Column>

          <Column lg={3} md={4} sm={4}>
            <SideNav
              isFixedNav
              expanded={true}
              isChildOfHeader={false}
              aria-label="Side navigation"
            >
              <SideNavItems>
                {theme.map((t, index4) => (
                  <SideNavLink href={'#' + index4} key={`side-link-${index4}`}>
                    <p
                      className={
                        currentTitle === index4
                          ? styles.currentTitle
                          : styles.notCurrentTitle
                      }
                    >
                      {index4 + 1} - {t.title.slice(0, 25)} ...
                    </p>
                  </SideNavLink>
                ))}
              </SideNavItems>
            </SideNav>
          </Column>
        </Grid>

        {/* Nav pages */}
        <Grid className={styles.navigationBox} fullWidth>
          <Column lg={8} md={4} sm={4}>
            <a href="/theme8">
              <Button kind="tertiary" className={styles.navButton}>
                <img
                  alt="arrow"
                  src="/images/icons/arrow--left.svg"
                  className={styles.arrowIcon}
                />
                {t('previousTheme')} : <br />
                {t('theme8')}
              </Button>
            </a>
          </Column>
          <Column lg={8} md={4} sm={4} className={styles.nextNav}>
            <a href="/theme10">
              <Button kind="tertiary" className={styles.navButton}>
                {t('nextTheme')} : <br />
                {t('theme10')}
                <img
                  alt="arrow"
                  src="/images/icons/arrow--right.svg"
                  className={styles.arrowIcon}
                />
              </Button>
            </a>
          </Column>
        </Grid>
      </Theme>
    </div>
  );
};

export default Theme9;
