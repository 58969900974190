import './App.scss';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import HeaderBar from './Components/Header/HeaderBar';
import Home from './Pages/Home/Home';
import Footer from './Components/Footer/Footer';
import Theme1 from './Pages/Themes/Theme1';
import Theme2 from './Pages/Themes/Theme2';
import Theme3 from './Pages/Themes/Theme3';
import Theme4 from './Pages/Themes/Theme4';
import Theme5 from './Pages/Themes/Theme5';
import Theme6 from './Pages/Themes/Theme6';
import Theme7 from './Pages/Themes/Theme7';
import Theme8 from './Pages/Themes/Theme8';
import Theme9 from './Pages/Themes/Theme9';
import Theme10 from './Pages/Themes/Theme10';

function App() {
  return (
    <div className="App">     
      <BrowserRouter>
      <HeaderBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/theme1" element={<Theme1 />} />
          <Route path="/theme2" element={<Theme2 />} />
          <Route path="/theme3" element={<Theme3 />} />
          <Route path="/theme4" element={<Theme4 />} />
          <Route path="/theme5" element={<Theme5 />} />
          <Route path="/theme6" element={<Theme6 />} />
          <Route path="/theme7" element={<Theme7 />} />
          <Route path="/theme8" element={<Theme8 />} />
          <Route path="/theme9" element={<Theme9 />} />
          <Route path="/theme10" element={<Theme10 />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
