export const theme7_fr = [
  {
    title: "CADRE GENERAL",
    texte: [
      "Le thème 'Expliquer l'IA' s'inscrit dans cette perspective de l'XAI, et souhaite répondre au besoin d'avoir confiance dans les systèmes d'IA et leurs résultats, en construisant des algorithmes pour produire des explications de façon automatique. ",
      "En plus de l’innovation dans la production de décisions expliquées, l’originalité des travaux réside dans l’ambition de doter un système d'IA de plusieurs types d'explications, selon le type de modèle utilisé,  à savoir :   ",
      "- Un système à base de modèles d’optimisation. Les modèles d’optimisation offrent des moyens et des outils efficaces pour trouver des solutions à des problèmes de décision, comme la planification et l’ordonnancement. ",
      " Toutefois, les solveurs d’optimisation sont souvent des boites noires inexplicables dont les solutions sont inaccessibles aux utilisateurs. A notre connaissance il existe très peu de travaux dans ce champs.  ",
      "L’objectif dans ce projet est d’avancer l’état de l’art la question de construction automatique des explications pour des modèles d’optimisation. ",
      "- Un système à base de modèles multicritères : l’analyse multicritère met au cœur du raisonnement les préférences et les jugements des utilisateurs. Elle repose sur une famille paramétrique de fonctions synthétisant l’ensemble des préférences exprimées sur des critères (souvent conflictuels) en une solution du problème de décision. ",
      "Ces modèles sont bien fondés du point de vue de la théorie de la décision.  Dans ce contexte, très peu de travaux se sont intéressés à la question de la génération d’explication des résultats (recommandations) d’un modèle de décision même si l'aspect multicritère des problèmes de décision est largement reconnu dans les applications réelles. ",
      "Dans ce contexte, la difficulté de la génération d'explications vient du fait que différents critères sont en jeu, l'utilisateur n'est pas nécessairement en mesure d'évaluer pleinement leur importance ou de comprendre comment ils interagissent.",
      "- Un système à base de modèles de Machine Learning. Le Machine Learning ou apprentissage automatique est basé sur des approches statistiques dont l'objectif est 'd'apprendre' des modèles prédictifs à partir de données observées. ",
      "De nombreux algorithmes d'apprentissage ont été proposés dans la littérature, performants en prévision et capables de passer à l'échelle sur de grandes quantités de données hétérogènes, avec des implémentations open source largement utilisées. ",
      "Cependant, ces modèles ne sont généralement pas intrinsèquement interprétables, ni accompagnés d'un moyen facile d'expliquer les prévisions qu’ils fournissent. Ils sont souvent présentés comme des outils « boîte noire »  effectuant des opérations complexes et non intuitives sur leurs entrées, ce qui peut être un problème dans de nombreuses applications où l’explication ou la justification de la décision du modèle peut avoir une plus grande valeur ajoutée que la décision elle-même. ",
      "Dans le cadre de ce projet, nous proposons d'aller plus loin, et de concevoir des solutions permettant d'interpréter les modèles, prévision à prévision, et sans compromis sur les performances de ces derniers.",
    ],
    demoTitle: '',
    demoDes: '',
    demoLink: '',
    publicationsTitles: [],
    publicationsLinks: [],
    publicationAuthors: [],
    perspectivesAuthor: '',
    perspectivesText: [ ],
  },
  {
    title: "CONCEPTION ET GÉNÉRATION D'EXPLICATIONS POUR DES SOLUTIONS D'UN PROBLÈME DE PLANIFICATION D'EMPLOYÉS MOBILES POUR DES UTILISATEURS",
    texte: [
      "Les systèmes d'aide à la décision basés sur l'optimisation combinatoire trouvent des applications dans divers domaines professionnels. ",
      "Cependant, les décideurs qui utilisent ces systèmes ne comprennent souvent pas les concepts mathématiques et les principes algorithmiques qui les sous-tendent. ",
      "Ce manque de compréhension peut entraîner du scepticisme et une réticence à accepter les solutions générées par le système, érodant ainsi la confiance placée dans le système. ",
      "Cette thèse traite cette problématique dans le cas du problème de planification d'employés mobiles, en anglais Workforce Scheduling and Routing Problem (WSRP), un problème d'optimisation combinatoire couplant de l'allocation de ressources humaines et du routage.  ",
      "Tout d'abord, nous proposons un cadre qui modélise le processus d'explication de solutions pour les utilisateurs d'un système de résolution de WSRP, permettant d'aborder une large gamme de sujets.  Les utilisateurs initient le processus en faisant des observations sur une solution et en formulant des questions liées à ces observations grâce à des modèles de texte prédéfinis. ",
      "Ces questions peuvent être de type contrastif, scénario ou contrefactuel. D'un point de vue mathématique, elles reviennent essentiellement à se demander s'il existe une solution faisable et meilleure dans un voisinage de la solution courante.  Selon les types de questions, cela conduit à la formulation d'un ou de plusieurs problèmes de décision et de programmes mathématiques. ",
      "Ensuite, nous développons une méthode pour générer des textes d'explication de différents types, avec un vocabulaire de haut niveau adapté aux utilisateurs. Notre méthode repose sur des algorithmes efficaces calculant du contenu explicatif afin de remplir des modèles de textes d'explication. Des expériences numériques montrent que ces algorithmes ont des temps d'exécution globalement compatibles avec une utilisation en temps quasi-réel des explications par les utilisateurs.  ",
      "Enfin, nous présentons un design de système structurant les interactions entre nos techniques de génération d'explications et les utilisateurs qui reçoivent les textes d'explication. ",
      "Ce système sert de base à un prototype d'interface graphique visant à démontrer l'applicabilité pratique et les potentiels bénéfices de notre approche dans son ensemble."
    ],
    demoTitle:
      'Explaining solutions of an optimization problem for decision makers [CentraleSupelec, DecisionBrain]',
    demoDes: '',
    demoLink:
      'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/Lot-F-MICS-XWSRP_prototype_compressed.mp4',
    publicationsTitles: [
      "Modeling and generating user-centered contrastive explanations for the Workforce Scheduling and Routing Problem, by  ICORES 2023.",
      "Conception et génération d'explications à propos des solutions d'un problème de planification d'employés mobiles pour des utilisateurs d'un système d'optimisation. PhD thesis of University of Paris-Saclay, 2023.",
    ],
    publicationsLinks: [],
    publicationAuthors: [
      'Lerouge, Mathieu; Gicquel, Céline; Mousseau, Vincent; Ouerdane, Wassila',
      'Lerouge, Mathieu'
    ],
    perspectivesAuthor: '',
    perspectivesText: [ ],
  },
  {
    title: 'EXPLICATIONS EN AIDE MULTICRITÈRE À LA DÉCISION : SCHÉMAS DÉDUCTIFS, ALGORITHMES ET EXPÉRIMENTATIONS',
    texte: [
      "Nous traitons de l'explication en aide multicritère à la décision, pour des problèmes de choix (la meilleure option) et de rangement (de la meilleure à la moins bonne). Nous proposons une nouvelles approche de l'explication d'une comparaison par paire d'alternatives basée sur la définition de ce que nous appelons un contexte: ",
      "- un triplet rassemblant une relation binaire  sur les alternatives, ",
      "- un sous-ensemble de cette dernière composé de comparaisons par paire supposées admises pour le décideur, ",
      "- et un schéma déductif. Le schéma déductif explicite les mécanismes en jeu dans la déduction de la comparaison par paire à expliquer à partir des comparaisons par paire admises. ",
      "Les mécanismes de déduction considérés s'appuient sur les propriétés de la relation binaire à laquelle appartiennent l'ensemble des comparaisons par paire admises et la comparaison par paire à expliquer. ",
      "Les relations binaires considérées dans le travail ont toutes pour point commun d'être représentables par un modèle additif. Les propriétés du modèle additif retenues sont celles de transitivité et d'indépendance. Le choix porté sur ces propriétés se justifie par le fait qu'elles correspondent à des modes de raisonnement intuitifs pour les décideurs. À partir de ces propriétés, nous avons construit différents schémas déductifs auxquels peut être conforme l'explication d'une comparaison par paire d'alternatives. Ensuite, nous avons décrit et implémenté  différents algorithmes de calcul d'explications conformes à ces schémas déductifs. ",
      "Ces algorithmes ont permis de mesurer l'expressivité des schémas déductifs auxquels ils se rapportent.",
      "Les autres contributions de ce travail introduisent notre approche de l'explication dans les processus d'aide à la décision multicritère. ",
      "Le cadre de décision traité est celui dans lequel sont en présence deux acteurs: le décideur et un expert dont le rôle est d'aider le décideur. Le problème considéré concerne le choix d'un sous-ensemble d'alternatives et où chaque critère possède exactement deux niveaux d'évaluation. ",
      "Nous montrons comment l'explication peut permettre à l'analyste de soutenir sa recommandation auprès du décideur, de justifier les conclusions robustes obtenues, mais aussi d'éliciter les préférences du décideur à partir de l'explication des conclusions robustes.",
      "Pour rendre concrète cette fonction d'élicitation, nous ébauchons un protocole interactif qui, à travers les réactions du décideur aux explications qui lui sont présentées, rend possible la collecte d'information préférentielle additionnelle susceptible de faire progresser le processus d'aide vers la recommandation à formuler. En ce qui concerne l'explication de la recommandation finale, nous avons conduit des expérimentations numériques destinées à mesurer la proportion de recommandations explicables à l'aide du schéma de couverture et à partir de sous-comparaisons par paire non décomposables. ",
      "Nous avons enfin proposé quelques façons de remédier aux situations d'inexplicabilité. ",
    ],
    demoTitle:
      '',
    demoDes: '',
    demoLink:
      '',
    publicationsTitles: [],
    publicationsLinks: [],
    publicationAuthors: [],
    perspectivesAuthor: '',
    perspectivesText: [ ],
  },
  {
    title: "VERS DES MODÈLES D'APPRENTISSAGE AUTOMATIQUE INTERPRÉTABLES GRÂCE À DES EXPLICATIONS ET DES VISUALISATIONS CONTREFACTUELLES",
    texte: [
      "Nous avons développé un démonstrateur qui permet de répondre au défi de rendre les modèles d'apprentissage automatique interprétables grâce à une approche basée sur la combinaison d'explications contrefactuelles avec des techniques de visualisation.  ",
      "Cette méthode imite l'approche humaine pour diagnostiquer les anomalies en extrayant plusieurs exemples contrefactuels pour une même requête, et en imposant un critère de diversité parmi les exemples produits. ",
      "A ce titre, cette approche est très utile dans des scénarios de détection et diagnostic d'anomalies. L'objectif du démonstrateur est de représenter les explications contrefactuelles sous forme de graphiques intuitifs, créant ainsi un support convivial pour interagir avec les décisions complexes des modèles d'IA et mieux les comprendre. ",
      "Nous montrons que l'utilisation combinée d'algorithmes d'interprétabilité et de techniques de visualisation permet de combler le fossé entre la prise de décision complexe des algos d'IA et la compréhension humaine. Ce dernier aspect favorise la transparence, la confiance et l'acceptation sociale des applications et prises de décision reposant sur l'intelligence artificielle.",],
    demoTitle: [
      "Démonstrateur",
    ],
    demoDes: '',
    demoLink: [
      'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/LOT-F_CEA-LIST_FR_v3.mp4',
    ],
    publicationsTitles: [
      "Questionable Stepwise Explanations for a Robust Additive Preference Model. International Journal of Approximate Reasoning, pp.108982 ",
      "Des explications transitives questionnables au service de l'élicitation de préférences additives. JIAF 2023: 114-123 ",
      //"Counterfactual Explanations for Workforce Scheduling and Routing Problems",
      "Des explications par étapes pour le modèle additif. JIAF 2022: 23-34 ",
      "Explications en aide multicritère à la décision: schémas déductifs, algorithmes et expérimentations. PhD thesis of University of Paris-Saclay, 2023.  "
      //"Best Paper Award Honorable for “Counterfactual Explanations for Workforce Scheduling and Routing Problems” received at the 12th International Conference on Operations Research and Enterprise Systems (ICORES) held in 2023.",
      //"An exact counterfactual-example-based approach to tree-ensemble models interpretability. arXiv:2105.14820. Submitted on 31 May 2021",
    ],
    publicationsLinks: [
    ],
    publicationAuthors: [
      'Questionable Stepwise Explanations for a Robust Additive Preference Model, by Manuel Amoussou, Khaled Belahcène, Nicolas Maudet, Vincent Mousseau, Wassila Ouerdane.', 
      'Manuel Amoussou, Khaled Belahcène, Nicolas Maudet, Vincent Mousseau, Wassila Ouerdane', 
      'Manuel Amoussou, Khaled Belahcène, Nicolas Maudet, Vincent Mousseau, Wassila Ouerdane', 
      'Manuel Amoussou',
    ],
    perspectivesAuthor: '',
    perspectivesText: [ ],
  },
  {
    title: "DISSEMINATION ET PARTICIPATIONS AUX EVENEMENTS SCIENTIFIQUES",
    texte: [
      "- Présentation “Generating explanations of various types for end-users of optimization systems, application to the Workforce Scheduling and Routine Problem”, at  ROADEF 2023, February 20th to 23rd 2023.",
      "- Présentation “Explication de recommandations issues d'un modèle additif : de la conceptualisation à l'évaluation”. ROADEF 2023 . Rennes 20-23 février 2023.",
      "- Présentation at Journées du GT sur l'explicabilité et l'IA de confiance, Explicon GDR RADIA. 7 et 8 juin 2023 -- LIP6 Sorbonne Université.",
      "- Présentation “Designing methods for explaning solutions stemming from optimization systems, application to the Workforce Scheduling and Routine Problem”, at  ROADEF 2022,, on February 23rd to 25th 2022.",
      "- Pitch “My PhD subject in less than 6 minutes, at the annual celebration day of the Doctoral School Interfaces, held at CentraleSupélec in Gif-sur-Yvette (France), on December 6th 2021.",
      "- Présentation “Challenges of designing explanation tools for optimization systems”, at the seminar Forum Industriel de l’Intelligence Artificielle (FIIA) organized by the Association Française pour l'Intelligence Artificielle (AFIA) on the topic \"Trustworthy AI: responsability, robustness, transparency\", held in Paris (France), on October 7th 2021.",
      "- Poster “Designing explanation tools for optimization systems: Application to the Workforce Scheduling and Routine Problem”, at the symposium organized by the Groupe De Recherche Intelligence Artificielle (GDRIA) - Institut d’Automne en Intelligence Artificielle (IA2) on the topic “Artificial Intelligence and Explainability”, held in Paris (France), from September 27th to October 1st 2021.",
    ],
    demoTitle: [
      "",
    ],
    demoDes: '',
    demoLink: [
    ],
    publicationsTitles: [],
    publicationsLinks: [],
    publicationAuthors: [],
    perspectivesAuthor: '',
    perspectivesText: [ ],
  },
];

export const theme7_en = [
  {
    title: "GENERAL FRAMEWORK",
    texte: [
      "The theme 'Explaining AI' falls within the scope of XAI (Explainable AI) and aims to address the need for trust in AI systems and their outcomes, by constructing algorithms to automatically generate explanations. ",
      "In addition to innovation in the production of explained decisions, the originality of the work lies in the ambition to equip an AI system with several types of explanations, depending on the type of model used, namely: ",
      "- A system based on optimization models. Optimization models provide effective means and tools to find solutions to decision-making problems, such as planning and scheduling. ",
      " However, optimization solvers are often inexplicable black boxes whose solutions are inaccessible to users. To our knowledge, very few works have explored this field. ",
      "The goal in this project is to advance the state of the art in the question of automatic construction of explanations for optimization models. ",
      "- A system based on multi-criteria models: multi-criteria analysis places the preferences and judgments of users at the heart of reasoning. It relies on a parametric family of functions synthesizing all the preferences expressed on criteria (often conflicting) into a solution to the decision-making problem. ",
      "These models are well-founded from a decision theory perspective. In this context, very few works have focused on the question of generating explanations for the results (recommendations) of a decision-making model, even though the multi-criteria aspect of decision-making problems is widely recognized in real-world applications. ",
      "In this context, the difficulty of generating explanations comes from the fact that different criteria are at play, and the user may not be fully able to assess their importance or understand how they interact.",
      "- A system based on Machine Learning models. Machine Learning or automatic learning is based on statistical approaches whose goal is to 'learn' predictive models from observed data. ",
      "Numerous learning algorithms have been proposed in the literature, performing well in forecasting and scalable over large quantities of heterogeneous data, with widely used open-source implementations. ",
      "However, these models are generally not inherently interpretable, nor do they come with an easy way to explain the forecasts they provide. They are often presented as 'black box' tools performing complex and non-intuitive operations on their inputs, which can be a problem in many applications where explaining or justifying the model's decision may have greater added value than the decision itself. ",
      "In this project, we propose to go further, and to design solutions for interpreting models, forecast by forecast, without compromising on their performance.",
    ],
    demoTitle: '',
    demoDes: '',
    demoLink: '',
    publicationsTitles: [],
    publicationsLinks: [],
    publicationAuthors: [],
    perspectivesAuthor: '',
    perspectivesText: [ ],
  },
  {
    title: "DESIGN AND GENERATION OF EXPLANATIONS FOR SOLUTIONS OF A MOBILE EMPLOYEE SCHEDULING PROBLEM FOR USERS",
    texte: [
      "Decision support systems based on combinatorial optimization find applications in various professional fields. ",
      "However, decision-makers who use these systems often do not understand the mathematical concepts and algorithmic principles underlying them. ",
      "This lack of understanding can lead to skepticism and reluctance to accept the solutions generated by the system, thus eroding trust in the system. ",
      "This thesis addresses this issue in the case of the mobile employee scheduling problem, known as the Workforce Scheduling and Routing Problem (WSRP), a combinatorial optimization problem that combines human resource allocation and routing. ",
      "First, we propose a framework that models the process of explaining solutions for users of a WSRP resolution system, allowing for a broad range of topics. Users initiate the process by making observations on a solution and formulating questions related to these observations using predefined text templates. ",
      "These questions can be of a contrastive, scenario, or counterfactual nature. Mathematically, they essentially amount to asking whether there is a feasible and better solution in the vicinity of the current solution. Depending on the types of questions, this leads to the formulation of one or several decision problems and mathematical programs. ",
      "Then, we develop a method to generate explanatory texts of different types, with high-level vocabulary suited for users. Our method relies on efficient algorithms calculating explanatory content to fill explanatory text templates. Numerical experiments show that these algorithms have overall execution times compatible with quasi-real-time use of explanations by users. ",
      "Finally, we present a system design structuring the interactions between our explanation generation techniques and the users who receive the explanatory texts. ",
      "This system serves as the basis for a graphical interface prototype aimed at demonstrating the practical applicability and potential benefits of our approach as a whole."
    ],
    demoTitle:
      'Explaining solutions of an optimization problem for decision makers [CentraleSupelec, DecisionBrain]',
    demoDes: '',
    demoLink:
      'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/Lot-F-MICS-XWSRP_prototype_compressed_EN.mp4',
    publicationsTitles: [
        "Modeling and generating user-centered contrastive explanations for the Workforce Scheduling and Routing Problem, by  ICORES 2023.",
        "Design and generation of explanations about the solutions of a mobile employee scheduling problem for users of an optimization system.. PhD thesis of University of Paris-Saclay, 2023.",
      ],
    publicationsLinks: [],
    publicationAuthors: [
        'Lerouge, Mathieu; Gicquel, Céline; Mousseau, Vincent; Ouerdane, Wassila',
        'Lerouge, Mathieu',
      ],
    publicationAuthors: [
      '',
    ],
    perspectivesAuthor: '',
    perspectivesText: [ ],
  },
  {
    title: 'EXPLANATIONS IN MULTICRITERIA DECISION AID: DEDUCTIVE SCHEMAS, ALGORITHMS, AND EXPERIMENTATIONS',
    texte: [
      "We address explanations in multicriteria decision aid for choice problems (selecting the best option) and ranking (from best to worst). We propose a new approach to explaining a pairwise comparison of alternatives based on the definition of what we call a context: ",
      "- a triplet gathering a binary relation on alternatives, ",
      "- a subset of the latter composed of pairwise comparisons assumed to be accepted by the decision-maker, ",
      "- and a deductive scheme. The deductive scheme explicates the mechanisms involved in deducing the pairwise comparison to be explained from the accepted pairwise comparisons. ",
      "The deduction mechanisms considered rely on the properties of the binary relation to which the set of accepted pairwise comparisons and the pairwise comparison to be explained belong. ",
      "The binary relations considered in the work all have in common that they are representable by an additive model. The properties of the additive model retained are those of transitivity and independence. The choice of these properties is justified by the fact that they correspond to intuitive reasoning modes for decision-makers. From these properties, we have constructed various deductive schemes to which the explanation of a pairwise comparison of alternatives can conform. Next, we have described and implemented different algorithms for calculating explanations conforming to these deductive schemes. ",
      "These algorithms have allowed us to measure the expressiveness of the deductive schemes to which they relate.",
      "The other contributions of this work introduce our approach to explanation in multicriteria decision aid processes. ",
      "The decision-making framework addressed is one in which two actors are present: the decision-maker and an expert whose role is to assist the decision-maker. The problem considered concerns the choice of a subset of alternatives, where each criterion has exactly two evaluation levels. ",
      "We show how the explanation can enable the analyst to support their recommendation to the decision-maker, to justify the robust conclusions obtained, and also to elicit the preferences of the decision-maker from the explanation of the robust conclusions.",
      "To make this elicitation function concrete, we outline an interactive protocol that, through the decision-maker's reactions to the explanations presented, makes it possible to collect additional preferential information that can advance the aid process towards the recommendation to be formulated. Regarding the explanation of the final recommendation, we have conducted numerical experiments aimed at measuring the proportion of explainable recommendations using the coverage schema and from non-decomposable sub-pairwise comparisons. ",
      "We have finally proposed some ways to remedy inexplicability situations. ",
    ],
    demoTitle:
      '',
    demoDes: '',
    demoLink:
      '',
    publicationsTitles: [
    ],
    publicationsLinks: ['', '', '', ''],
    publicationAuthors: [
    ],
    perspectivesAuthor: '',
    perspectivesText: [ ],
  },
  {
    title: "TOWARDS INTERPRETABLE MACHINE LEARNING MODELS THROUGH COUNTERFACTUAL EXPLANATIONS AND VISUALIZATIONS",
    texte: [
      "We have developed a demonstrator that responds to the challenge of making machine learning models interpretable through an approach based on combining counterfactual explanations with visualization techniques. ",
      "This method mimics the human approach to diagnosing anomalies by extracting several counterfactual examples for the same query, and by imposing a criterion of diversity among the produced examples. ",
      "In this regard, this approach is very useful in scenarios of detection and diagnosis of anomalies. The goal of the demonstrator is to represent counterfactual explanations in the form of intuitive graphs, thus creating a user-friendly support to interact with the complex decisions of AI models and better understand them. ",
      "We show that the combined use of interpretability algorithms and visualization techniques can bridge the gap between complex AI decision-making and human understanding. This latter aspect promotes transparency, trust, and social acceptance of applications and decision-making based on artificial intelligence.",],
    demoTitle: [
      "Demonstrator",
    ],
    demoDes: '',
    demoLink: [
      'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/LOT-F_CEA-LIST_EN_v3.mp4',
    ],
    publicationsTitles: [
      "Questionable Stepwise Explanations for a Robust Additive Preference Model. International Journal of Approximate Reasoning, pp.108982 ",
      "Des explications transitives questionnables au service de l'élicitation de préférences additives. JIAF 2023: 114-123 ",
      //"Counterfactual Explanations for Workforce Scheduling and Routing Problems",
      "Des explications par étapes pour le modèle additif. JIAF 2022: 23-34 ",
      "Explications en aide multicritère à la décision: schémas déductifs, algorithmes et expérimentations. PhD thesis of University of Paris-Saclay, 2023.  "
      //"Best Paper Award Honorable for “Counterfactual Explanations for Workforce Scheduling and Routing Problems” received at the 12th International Conference on Operations Research and Enterprise Systems (ICORES) held in 2023.",
      //"An exact counterfactual-example-based approach to tree-ensemble models interpretability. arXiv:2105.14820. Submitted on 31 May 2021",
    ],
    publicationsLinks: [
    ],
    publicationAuthors: [
      'Questionable Stepwise Explanations for a Robust Additive Preference Model, by Manuel Amoussou, Khaled Belahcène, Nicolas Maudet, Vincent Mousseau, Wassila Ouerdane.', 
      'Manuel Amoussou, Khaled Belahcène, Nicolas Maudet, Vincent Mousseau, Wassila Ouerdane', 
      'Manuel Amoussou, Khaled Belahcène, Nicolas Maudet, Vincent Mousseau, Wassila Ouerdane', 
      'Manuel Amoussou',
    ],
    perspectivesAuthor: '',
    perspectivesText: [ ],
  },
  {
    title: "DISSEMINATION AND PARTICIPATION IN SCIENTIFIC EVENTS",
    texte: [
      "- Presentation “Generating explanations of various types for end-users of optimization systems, application to the Workforce Scheduling and Routing Problem”, at ROADEF 2023, February 20th to 23rd 2023.",
      "- Presentation “Explanation of recommendations from an additive model: from conceptualization to evaluation”. ROADEF 2023. Rennes 20-23 February 2023.",
      "- Presentation at the GT Days on explainability and trustworthy AI, Explicon GDR RADIA. June 7 and 8, 2023 -- LIP6 Sorbonne University.",
      "- Presentation “Designing methods for explaining solutions stemming from optimization systems, application to the Workforce Scheduling and Routing Problem”, at ROADEF 2022, on February 23rd to 25th 2022.",
      "- Pitch “My PhD subject in less than 6 minutes, at the annual celebration day of the Doctoral School Interfaces, held at CentraleSupélec in Gif-sur-Yvette (France), on December 6th 2021.",
      "- Presentation “Challenges of designing explanation tools for optimization systems”, at the seminar Forum Industriel de l’Intelligence Artificielle (FIIA) organized by the Association Française pour l'Intelligence Artificielle (AFIA) on the topic \"Trustworthy AI: responsibility, robustness, transparency\", held in Paris (France), on October 7th 2021.",
      "- Poster “Designing explanation tools for optimization systems: Application to the Workforce Scheduling and Routing Problem”, at the symposium organized by the Groupe De Recherche Intelligence Artificielle (GDRIA) - Institut d’Automne en Intelligence Artificielle (IA2) on the topic “Artificial Intelligence and Explainability”, held in Paris (France), from September 27th to October 1st 2021.",
    ],
    demoTitle: [
      "",
    ],
    demoDes: '',
    demoLink: [
    ],
    publicationsTitles: [
    ],
    publicationsLinks: [
    ],
    publicationAuthors: [
    ],
    perspectivesAuthor: '',
    perspectivesText: [ ],
  },
];