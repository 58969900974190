import styles from './Theme.module.scss';
import { Theme, Grid, Column, Link, Button, SideNav, SideNavItems, SideNavLink } from '@carbon/react';
import PoinIBM from '../../Components/PointIBM/PointIBM';
import { theme4_fr, theme4_en } from '../../Data/theme4';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';

const Theme4 = () => {
  const { t, i18n } = useTranslation();
  const theme = i18n.language === "en" ? theme4_en : theme4_fr;
  const [currentTitle, setCurrentTitle] = useState(0);
  const [sizeArray, setSizeArray] = useState([]);

  useEffect(() => {
    try {
      let tempSizeArray = [(document.getElementById('title-theme-4').clientHeight + document.getElementById('0').clientHeight)];

      theme.forEach((elem, index) => {
        tempSizeArray.push(tempSizeArray[index] + document.getElementById(index).clientHeight);
      })
      setSizeArray(tempSizeArray);

    } catch (error) {
      console.log(error);
    }
  }, [currentTitle, theme]);

  useEffect(() => {
    const handleScroll = (event) => {

      sizeArray.forEach((elem, index) => {
        if (index >= 1) {
          if ((sizeArray[index - 1] < window.scrollY) && (window.scrollY < elem)) {
            setCurrentTitle(index);
            return;
          }
        } else if (window.scrollY < elem) {
          setCurrentTitle(index);
          return;
        }
      })
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sizeArray, theme]);

  // For videos - Uncertainly useful:-)
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [i18n.language]); // Dependency array with language, changes when language changes

  // For videos - Works on theme change due to language switch
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [theme]);

  return (
    <div className={styles.theme}>
      {/* Bar en noire */}
      <Theme theme="g100">
        <Grid className={styles.title} fullWidth id="title-theme-4">
          <Column lg={5} md={8} sm={4}>
            <h1>{t('theme4')}</h1>
            <br />
          </Column>
          <Column lg={7} md={8} sm={4}>
            {i18n.language === 'en' ? (
              <p>
                Organizations often find themselves facing the need to process personal data as part of their business operations. With the advent of organizational transformation focused on artificial intelligence, the intensive use of this data becomes essential. However, privacy protection and compliance with the GDPR require companies to have precise control over their personal data management. Often, organizations store and process personal data without being fully aware of it. Compliance with the GDPR can represent a major challenge, especially for older companies with a vast historical data legacy and a multitude of business processes.
                <br />
                <br />AIDA's personal data management workshop is an integrated component of the AIDA modeling workshop. It offers organizations the opportunity to comply with the GDPR in the context of their transformation into a hybrid human-AI organization. Within this workshop, the enterprise architect can define a personal data dictionary, specify the processes carried out on these data, as well as their legal bases.
                <br />
                <br />As part of AIDA, SOFTEAM has developed two features:
                <br /> <span>- The generation of GDPR user charters</span>, to create user charters adapted to each role and individual,
                <br /> <span>- The discovery and identification of personal data uses</span> within an organization's files.
              </p>
            ) : (
              <p>
                Les organisations se trouvent fréquemment confrontées à la nécessité de traiter des données personnelles
                dans le cadre de leurs opérations métier. Avec l'avènement de la transformation organisationnelle axée
                sur l'intelligence artificielle, l'utilisation intensive de ces données devient incontournable.
                Toutefois, la protection de la vie privée et la conformité au RGPD imposent aux entreprises une maîtrise précise
                de leur gestion des données personnelles. Souvent, les organisations stockent et traitent des données personnelles
                sans en avoir pleinement conscience. La mise en conformité au RGPD peut représenter un défi majeur,
                en particulier pour les entreprises anciennes disposant d'un vaste capital historique de données et
                d'une multitude de processus métier.
                <br />
                <br />L'atelier de gestion des données personnelles d'AIDA est un composant intégré à l'atelier de modélisation AIDA.
                Il offre aux organisations la possibilité de se conformer au RGPD dans le contexte de leur transformation vers
                une organisation hybride humain-IA. Au sein de cet atelier, l'architecte d'entreprise peut définir un dictionnaire
                des données personnelles, spécifier les traitements qui sont effectués sur ces données, ainsi que leurs bases légales.
                <br />
                <br />Dans le cadre d'AIDA, SOFTEAM a développé deux fonctionnalités :
                <br /> <span>- La génération de chartes d’utilisateur RGPD</span>, fin de créer des chartes d'utilisation adaptées à chaque role et individu,
                <br /> <span>- la découverte et l'identification des utilisations de données personnelles</span> au sein des fichiers d'une organisation.
              </p>
            )}
            <br />
            {i18n.language === 'en' ? (
              <video
                src="https://aida-videos-challenges.s3.eu-de.cloud-object-storage.appdomain.cloud/Theme-4-Gestion-Donnees-Personnelles-SOFTEAM_EN.mp4"
                title="video"
                width="100%"
                height="400px"
                allow="fullscreen"
                controls
              ></video>
            ) : (
              <video
                src="https://aida-videos-challenges.s3.eu-de.cloud-object-storage.appdomain.cloud/Theme-4-Gestion-Donne%CC%81es-Personnelles-SOFTEAM.mp4"
                title="video"
                width="100%"
                height="400px"
                allow="fullscreen"
                controls
              ></video>
            )}
          </Column>
          <Column lg={3} md={7} sm={3}></Column>
          <Column lg={1} md={1} sm={1} className={styles.numberBox}>
            <div className={styles.blueNumber}>4</div>
          </Column>
        </Grid>
      </Theme>

      {/* Les avancées d'AIDA */}
      <Theme theme="white">
        <Grid className={styles.navigationBox} fullWidth>
          <Column lg={13} md={4} sm={4}>
            {theme.map((th, index) => (
              <div className={styles.avanceBox} id={index} key={index}>
                <Grid fullWidth>
                  <Column lg={16} md={8} sm={4}>
                    <span>{t('advances')}</span>
                  </Column>
                </Grid>
                <Grid fullWidth>
                  <Column lg={3} md={2} sm={4}>
                    <h4>
                      {index + 1} - {th.title}
                    </h4>
                    <br />
                  </Column>
                  <Column lg={10} md={6} sm={4}>
                    {th.texte.map((texte, index2) => (
                      <p key={`p-text-${index2}`}>{texte}</p>
                    ))}
                    {th.demoTitle.length !== 0 ? (
                      <>
                        <br />
                        <h6>{th.demoTitle}</h6>
                        <p>
                          {th.demoDes}
                          <br />
                        </p>
                        <br />
                        <video
                          src={th.demoLink}
                          title="video"
                          width="100%"
                          height="400px"
                          allow="fullscreen"
                          controls
                        ></video>
                        <br />
                      </>
                    ) : (
                      <></>
                    )}

                    {th.publicationsTitles.length !== 0 ? (
                      <>
                        <br />
                        <br />
                        <h6>Publications</h6>
                      </>
                    ) : (
                      <></>
                    )}
                    <br />
                    {th.publicationsTitles.map((linkTitle, index3) => (
                      <div key={`link-${index3}`}>
                        <Link href={th.publicationsLinks[index3]}>
                          {linkTitle} -{'>'}
                        </Link>
                        <br />
                        <span>{th.publicationAuthors[index3]}</span>
                        <br />
                        <br />
                      </div>
                    ))}
                    {th.perspectivesAuthor !== '' ? (
                      <>
                        <br />
                        <h6>Perspectives - {th.perspectivesAuthor}</h6>
                        <p>
                          {th.perspectivesText}
                          <br />
                        </p>
                        <br />
                        <br />
                      </>
                    ) : (
                      <></>
                    )}
                    {th.pointIBM ? <PoinIBM /> : <></>}
                  </Column>
                </Grid>
              </div>
            ))}
          </Column>

          <Column lg={3} md={4} sm={4}>
            <SideNav
              isFixedNav
              expanded={true}
              isChildOfHeader={false}
              aria-label="Side navigation"
            >
              <SideNavItems>
                {theme.map((th, index4) => (
                  <SideNavLink href={'#' + index4} key={`side-link-${index4}`}>
                    <p
                      className={
                        currentTitle === index4
                          ? styles.currentTitle
                          : styles.notCurrentTitle
                      }
                    >
                      {index4 + 1} - {th.title.slice(0, 25)} ...
                    </p>
                  </SideNavLink>
                ))}
              </SideNavItems>
            </SideNav>
          </Column>
        </Grid>

        {/* Nav pages */}
        <Grid className={styles.navigationBox} fullWidth>
          <Column lg={8} md={4} sm={4}>
            <a href="/theme3">
              <Button kind="tertiary" className={styles.navButton}>
                <img
                  alt="arrow"
                  src="/images/icons/arrow--left.svg"
                  className={styles.arrowIcon}
                />
                {t('previousTheme')} : <br />
                {t('theme3')}
              </Button>
            </a>
          </Column>
          <Column lg={8} md={4} sm={4} className={styles.nextNav}>
            <a href="/theme5">
              <Button kind="tertiary" className={styles.navButton}>
                {t('nextTheme')} : <br />
                {t('theme5')}
                <img
                  alt="arrow"
                  src="/images/icons/arrow--right.svg"
                  className={styles.arrowIcon}
                />
              </Button>
            </a>
          </Column>
        </Grid>
      </Theme>
    </div>
  );
};

export default Theme4;
