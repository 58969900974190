import styles from './Theme.module.scss';
import { Theme, Grid, Column, Link, Button, SideNav, SideNavItems, SideNavLink } from '@carbon/react';
import PoinIBM from '../../Components/PointIBM/PointIBM';
import { theme3_fr, theme3_en } from '../../Data/theme3';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';

const Theme3 = () => {
  const { t, i18n } = useTranslation();
  const theme = i18n.language === "en" ? theme3_en : theme3_fr;
  const [currentTitle, setCurrentTitle] = useState(0);
  const [sizeArray, setSizeArray] = useState([]);

  useEffect(() => {
    try {
      let tempSizeArray = [(document.getElementById('title-theme-3').clientHeight + document.getElementById('0').clientHeight)];

      theme.forEach((elem, index) => {
        tempSizeArray.push(tempSizeArray[index] + document.getElementById(index).clientHeight);
      })
      setSizeArray(tempSizeArray);

    } catch (error) {
      console.log(error);
    }
  }, [currentTitle, theme]);

  useEffect(() => {
    const handleScroll = (event) => {

      sizeArray.forEach((elem, index) => {
        if (index >= 1) {
          if ((sizeArray[index - 1] < window.scrollY) && (window.scrollY < elem)) {
            setCurrentTitle(index);
            return;
          }
        } else if (window.scrollY < elem) {
          setCurrentTitle(index);
          return;
        }
      })
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sizeArray, theme]);

  // For videos - Uncertainly useful:-)
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [i18n.language]); // Dependency array with language, changes when language changes

  // For videos - Works on theme change due to language switch
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [theme]);

  /*
  const handleVideoClick = () => {
    const video = document.getElementById('votreIdDeVideo');
    if (video.paused) {
      video.play();
    }
  };
  */

  return (
    <div className={styles.theme}>
      {/* Bar en noire */}
      <Theme theme="g100">
        <Grid className={styles.title} fullWidth id="title-theme-3">
          <Column lg={5} md={8} sm={4}>
            <h1>{t('theme3')}</h1>
            <br />
          </Column>
          <Column lg={7} md={8} sm={4}>
            {i18n.language === 'en' ? (
              <p>
                The transformation of organisations towards entities augmented
                by artificial intelligence (AI) is a complex challenge,
                influenced by the size and inherent complexity of their core
                activities. The success of such a project depends on the ability
                to understand the impact of these transformations, and it is in
                this context that modelling is emerging as an essential
                solution. <br />
                <br />
                As part of the AIDA project, SOFTEAM has made a firm commitment
                to achieving significant results, thereby making a substantial
                contribution to the overall objective of the project: to support
                companies in their transition to augmented organisations. This
                fundamental contribution is materialised by the harmonious
                integration of AI capabilities with human skills, aiming to
                achieve unprecedented levels of digital automation. The central
                innovation of this approach lies in the development of the AIDA
                modelling workshop, built on established standards such as
                TOGAF, ArchiMate, BPMN, DCM and UML. This innovative tool offers
                a holistic approach to the comprehensive representation of
                enterprise architectures, providing a clear and integrated view
                of the transformation to hybrid organisations. <br />
                <br />
                The AIDA modeling workshop, developed in collaboration with the
                Modelio product, is a key element of this breakthrough, offering
                advanced features such as documentation generation, traceability
                and version management, reflecting SOFTEAM's unwavering
                commitment to simplifying the process of planning and modeling
                transformation projects. These concrete results and the
                demonstrators presented illustrate SOFTEAM's ongoing commitment
                and pioneering position in the field of organisational
                transformation, leading companies towards a future where human
                and artificial intelligence converge synergistically to create
                truly augmented organisations.
              </p>
            ) : (
              <p>
                La transformation des organisations vers des entités augmentées
                par l'intelligence artificielle (IA) constitue un défi complexe,
                influencé par la taille et la complexité inhérente à leurs
                activités centrales. La réussite d'un tel projet repose sur la
                capacité à appréhender l'impact de ces transformations, et c'est
                dans ce contexte que la modélisation émerge comme une solution
                essentielle. <br />
                <br /> Au sein du projet AIDA, SOFTEAM s'est résolument engagé à
                concrétiser des résultats significatifs, offrant ainsi une
                contribution substantielle à l'objectif global du projet :
                accompagner les entreprises dans leur transition vers des
                organisations augmentées. Cette contribution fondamentale se
                matérialise par l'intégration harmonieuse des capacités de l'IA
                avec les compétences humaines, visant à atteindre des niveaux
                d'automatisation digitale inédits. L'innovation centrale de
                cette démarche réside dans le développement de l'atelier de
                modélisation AIDA, construit sur des normes établies telles que
                TOGAF, ArchiMate, BPMN, DCM et UML. Cet outil novateur propose
                une approche holistique pour représenter de manière exhaustive
                les architectures d'entreprise, fournissant ainsi une vision
                claire et intégrée de la transformation vers des organisations
                hybrides. <br />
                <br /> L'atelier de modélisation AIDA, élaboré en collaboration
                avec le produit Modelio, s'impose comme un élément clé de cette
                avancée, offrant des fonctionnalités avancées telles que la
                génération de documentation, la traçabilité et la gestion de
                versions, reflétant l'engagement inébranlable de SOFTEAM dans la
                simplification du processus de planification et de modélisation
                des projets de transformation. Ces résultats concrets et les
                démonstrateurs présentés illustrent l'engagement continu et la
                position avant-gardiste de SOFTEAM dans le domaine de la
                transformation organisationnelle, conduisant les entreprises
                vers un avenir où l'intelligence humaine et artificielle
                convergent de manière synergique pour créer des organisations
                véritablement augmentées.
              </p>
            )}
            <br />
            {i18n.language === 'en' ? (
              <video
                src="https://aida-videos-challenges.s3.eu-de.cloud-object-storage.appdomain.cloud/Theme-3-Challenge-Modelisation-SOFTEAM_EN.mp4"
                title="video"
                width="100%"
                height="400px"
                allow="fullscreen"
                controls
              ></video>
            ) : (
              <video
                src="https://aida-videos-challenges.s3.eu-de.cloud-object-storage.appdomain.cloud/Theme-3-Challenge-Modélisation-SOFTEAM.mp4"
                title="video"
                width="100%"
                height="400px"
                allow="fullscreen"
                controls
              ></video>
            )}
          </Column>
          <Column lg={3} md={7} sm={3}></Column>
          <Column lg={1} md={1} sm={1} className={styles.numberBox}>
            <div className={styles.blueNumber}>3</div>
          </Column>
        </Grid>
      </Theme>

      {/* Les avancées d'AIDA */}
      <Theme theme="white">
        <Grid className={styles.navigationBox} fullWidth>
          <Column lg={13} md={4} sm={4}>
            {theme.map((th, index) => (
              <div className={styles.avanceBox} id={index} key={index}>
                <Grid fullWidth>
                  <Column lg={16} md={8} sm={4}>
                    <span>{t('advances')}</span>
                  </Column>
                </Grid>
                <Grid fullWidth>
                  <Column lg={3} md={2} sm={4}>
                    <h4>
                      {index + 1} - {th.title}
                    </h4>
                    <br />
                  </Column>
                  <Column lg={10} md={6} sm={4}>
                    {th.texte.map((texte, index2) => (
                      <p key={`p-text-${index2}`}>{texte}</p>
                    ))}
                    {th.demoTitle.length !== 0 ? (
                      <>
                        <br />
                        <h6>{th.demoTitle}</h6>
                        <p>
                          {th.demoDes}
                          <br />
                        </p>
                        <br />
                        <video
                          src={th.demoLink}
                          title="video"
                          width="100%"
                          height="400px"
                          allow="fullscreen"
                          controls
                        ></video>
                        <br />
                      </>
                    ) : (
                      <></>
                    )}

                    {th.publicationsTitles.length !== 0 ? (
                      <>
                        <br />
                        <br />
                        <h6>Publications</h6>
                      </>
                    ) : (
                      <></>
                    )}
                    <br />
                    {th.publicationsTitles.map((linkTitle, index3) => (
                      <div key={`link-${index3}`}>
                        <Link href={th.publicationsLinks[index3]}>
                          {linkTitle} -{'>'}
                        </Link>
                        <br />
                        <span>{th.publicationAuthors[index3]}</span>
                        <br />
                        <br />
                      </div>
                    ))}
                    {th.perspectivesAuthor !== '' ? (
                      <>
                        <br />
                        <h6>Perspectives - {th.perspectivesAuthor}</h6>
                        <p>
                          {th.perspectivesText}
                          <br />
                        </p>
                        <br />
                        <br />
                      </>
                    ) : (
                      <></>
                    )}
                    {th.pointIBM ? <PoinIBM /> : <></>}
                  </Column>
                </Grid>
              </div>
            ))}
          </Column>
          <Column lg={3} md={4} sm={4}>
            <SideNav
              isFixedNav
              expanded={true}
              isChildOfHeader={false}
              aria-label="Side navigation"
            >
              <SideNavItems>
                {theme.map((th, index4) => (
                  <SideNavLink href={'#' + index4} key={`side-link-${index4}`}>
                    <p
                      className={
                        currentTitle === index4
                          ? styles.currentTitle
                          : styles.notCurrentTitle
                      }
                    >
                      {index4 + 1} - {th.title.slice(0, 25)} ...
                    </p>
                  </SideNavLink>
                ))}
              </SideNavItems>
            </SideNav>
          </Column>
          <Column lg={8} md={4} sm={4}>
            <a href="/theme2">
              <Button kind="tertiary" className={styles.navButton}>
                <img
                  alt="arrow"
                  src="/images/icons/arrow--left.svg"
                  className={styles.arrowIcon}
                />
                {t('previousTheme')} : <br />
                {t('theme2')}
              </Button>
            </a>
          </Column>
          <Column lg={8} md={4} sm={4} className={styles.nextNav}>
            <a href="/theme4">
              <Button kind="tertiary" className={styles.navButton}>
                {t('nextTheme')} : <br />
                {t('theme4')}
                <img
                  alt="arrow"
                  src="/images/icons/arrow--right.svg"
                  className={styles.arrowIcon}
                />
              </Button>
            </a>
          </Column>
        </Grid>
      </Theme>
    </div>
  );
};

export default Theme3;
