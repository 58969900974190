import styles from './PoinIBM.module.scss';
import { useTranslation } from 'react-i18next';

function PoinIBM() {
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.pointIBM}>
      {i18n.language === 'en' ? (
        <>
          <h5>
            <span className={styles.blueText}>IBM</span>'s view on research
            progress and application horizon
          </h5>
          <strong>Advanced search</strong> : excellent results in controlling
          the relationship between expressiveness and performance, and limits to
          be overcome concerning current learning strategies for highly
          unbalanced datasets.
          <br />
          <strong>Horizon</strong> : industrial outlets in 2025. <br />
        </>
      ) : (
        <>
          <h5>
            Point de vue d’<span className={styles.blueText}>IBM</span> sur
            l’avancement des recherches et l’horizon d’application
          </h5>
          <strong>Recherche très avancée</strong> : excellents résultats dans le
          contrôle du rapport entre expressivité et performance et limites à
          dépasser concernant les stratégies d’apprentissage actuelles pour des
          ensembles de données fortement déséquilibrés. <br />
          <strong>Horizon</strong> : débouchés industriels en 2025. <br />
        </>
      )}
    </div>
  );
}

export default PoinIBM;
