import styles from './Theme.module.scss';
import { Theme, Grid, Column, Link, Button, SideNav, SideNavItems, SideNavLink } from '@carbon/react';
import PoinIBM from '../../Components/PointIBM/PointIBM';
import { theme8_fr, theme8_en } from '../../Data/theme8';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';

const Theme8 = () => {
  const { t, i18n } = useTranslation();
  const theme = i18n.language === "en" ? theme8_en : theme8_fr;
  const [currentTitle, setCurrentTitle] = useState(0);
  const [sizeArray, setSizeArray] = useState([]);

  useEffect(() => {
    try {
      let tempSizeArray = [(document.getElementById('title-theme-7').clientHeight + document.getElementById('0').clientHeight)];

      theme.forEach((elem, index) => {
        tempSizeArray.push(tempSizeArray[index] + document.getElementById(index).clientHeight);
      })
      setSizeArray(tempSizeArray);

    } catch (error) {
      console.log(error);
    }
  }, [currentTitle, theme]);

  useEffect(() => {
    const handleScroll = (event) => {

      sizeArray.forEach((elem, index) => {
        if (index >= 1) {
          if ((sizeArray[index - 1] < window.scrollY) && (window.scrollY < elem)) {
            setCurrentTitle(index);
            return;
          }
        } else if (window.scrollY < elem) {
          setCurrentTitle(index);
          return;
        }
      })
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sizeArray, theme]);

  // For videos - Uncertainly useful:-)
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [i18n.language]); // Dependency array with language, changes when language changes

  // For videos - Works on theme change due to language switch
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [theme]);

  return (
    <div className={styles.theme}>
      {/* Bar en noire */}
      <Theme theme="g100">
        <Grid className={styles.title} fullWidth id="title-theme-7">
          <Column lg={5} md={8} sm={4}>
            <h1>{t('theme8')}</h1>
            <br />
          </Column>
          <Column lg={7} md={8} sm={4}>
            {i18n.language === 'en' ? (
            <p>
            Companies face an ever-evolving business environment, requiring operational excellence to remain competitive. They must:
            <br />- Manage the growing complexity of their operational systems to stay agile and responsive,
            <br />- Identify and resolve inefficiencies to optimize business processes, eliminate bottlenecks, and improve profitability,
            <br />- Enhance visibility and transparency of operations for better decision-making and increased accountability,
            <br />- Integrate artificial intelligence into business processes to boost efficiency, anticipate trends, and model behaviors for strategic decision-making.
            <br /><br />
            To address these strategic challenges, companies are faced with several crucial technical questions:
            <br />- <span>Business Process Optimization</span>: How to optimize processes to maximize efficiency and profitability?
            <br />- <span>Business Data Analysis</span>: How to leverage data to identify trends, patterns, and opportunities for improvement?
            <br />- <span>Real-Time Automation</span>: How to capture and process operational events in real-time for increased responsiveness?
            <br /><br />
            In response to these challenges, IBM has developed IBM Business Automation Insights, a robust solution that enables companies to transform their operational data into actionable insights. This software component is designed to:
            <br />- <span>Capture operational events</span> generated by digital business automation systems.
            <br />- <span>Aggregate</span> these data into relevant Key Performance Indicators (<span>KPIs</span>), providing an accurate and contextualized view of operations.
            <br />- <span>Present these indicators in</span> intuitive dashboards, offering decision-makers a real-time view of business performance and opportunities for improvement.
            <br /><br />By placing data collection, real-time analysis, and artificial intelligence at the heart of the decision-making strategy, IBM Business Automation Insights is not just a response to current challenges. 
            It's an evolving platform, ready to adapt and anticipate the future needs of businesses, arming them with the necessary tools to navigate today's and tomorrow's complex business landscape.
            </p>
            ) : (
            <p>
            Les entreprises font face à un environnement commercial en constante évolution, nécessitant une excellence opérationnelle pour rester compétitives. Elles doivent :
            <br />- Gérer la complexité croissante de leurs systèmes opérationnels pour rester agiles et réactives.
            <br />- Identifier et résoudre les inefficacités pour optimiser les processus commerciaux, éliminer les goulots d'étranglement et améliorer la rentabilité.
            <br />- Améliorer la visibilité et la transparence des opérations pour une meilleure prise de décision et une responsabilisation accrue.
            <br />- Intégrer l'intelligence artificielle dans les processus commerciaux pour renforcer l'efficacité, anticiper les tendances et modéliser les comportements pour une prise de décision stratégique.
            <br /><br />
            Pour répondre à ces défis stratégiques, les entreprises sont confrontées à plusieurs questions techniques cruciales :
            <br />- Optimisation des processus commerciaux : Comment optimiser les processus pour maximiser l'efficacité et la rentabilité ?
            <br />- Analyse des données commerciales : Comment exploiter les données pour identifier les tendances, les modèles et les opportunités d'amélioration ?
            <br />- Automatisation et temps réel : Comment capturer et traiter les événements opérationnels en temps réel pour une réactivité accrue ?
            <br /><br />
            En réponse à ces défis, IBM a développé IBM Business Automation Insights, une solution robuste permettant aux entreprises de transformer leurs données opérationnelles en insights actionnables. Ce composant logiciel est conçu pour :
            <br />- <span>Capturer les événements opérationnels</span> générés par les systèmes d’automatisation des affaires numériques.
            <br />- <span>Agréger</span> ces données en indicateurs de performance clés (<span>KPI</span>)  pertinents, permettant une vision précise et contextualisée des opérations.
            <br />- <span>Présenter ces indicateurs dans des tableaux de bord</span> intuitifs, offrant aux décideurs une vue en temps réel des performances commerciales et des opportunités d'amélioration.
            <br /><br />En plaçant la collecte de données, l'analyse en temps réel et l'intelligence artificielle au cœur de la stratégie décisionnelle, IBM Business Automation Insights n'est pas seulement une réponse aux défis actuels. 
            C'est une plateforme évolutive, prête à s'adapter et à anticiper les besoins futurs des entreprises, les armant avec les outils nécessaires pour naviguer dans le paysage commercial complexe d'aujourd'hui et de demain.
            </p>
          )}
          <br />
          <video
            src="https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/IBM%20Business%20Process%20Automation%20Insights%20Demo_EN.mp4"
            title="video"
            width="100%"
            height="400px"
            allow="fullscreen"
            controls
          ></video>
        </Column>
        <Column lg={3} md={7} sm={3}></Column>
        <Column lg={1} md={1} sm={1} className={styles.numberBox}>
          <div className={styles.blueNumber}>8</div>
        </Column>
      </Grid>
    </Theme>

    {/* Les avancées d'AIDA */}
    <Theme theme="white">
      <Grid className={styles.navigationBox} fullWidth>
        <Column lg={13} md={4} sm={4}>
          {theme.map((th, index) => (
            <div className={styles.avanceBox} id={index} key={index}>
              <Grid fullWidth>
                <Column lg={16} md={8} sm={4}>
                  <span>{t('advances')}</span>
                </Column>
              </Grid>
              <Grid fullWidth>
                <Column lg={3} md={2} sm={4}>
                  <h4>
                    {index + 1} - {th.title}
                  </h4>
                  <br />
                </Column>
                <Column lg={10} md={6} sm={4}>
                  {th.texte.map((texte, index2) => (
                    <p key={`p-text-${index2}`}>{texte}</p>
                  ))}
                  {th.demoTitle.length !== 0 ? (
                    <>
                      <br />
                      <h6>{th.demoTitle}</h6>
                      <p>
                        {th.demoDes}
                        <br />
                      </p>
                      <br />
                      <video
                          src={th.demoLink}
                          title="video"
                          width="100%"
                          height="400px"
                          allow="fullscreen"
                          controls
                        ></video>
                      <br />
                    </>
                  ) : (
                    <></>
                  )}

                  {th.publicationsTitles.length !== 0 ? (
                    <>
                      <br />
                      <br />
                      <h6>Publications</h6>
                    </>
                  ) : (
                    <></>
                  )}
                  <br />
                  {th.publicationsTitles.map((linkTitle, index3) => (
                    <div key={`link-${index3}`}>
                      <Link href={th.publicationsLinks[index3]}>
                        {linkTitle} -{'>'}
                      </Link>
                      <br />
                      <span>{th.publicationAuthors[index3]}</span>
                      <br />
                      <br />
                    </div>
                  ))}
                  {th.pointIBM ? <PoinIBM /> : <></>}
                </Column>
              </Grid>
            </div>
          ))}
        </Column>

        <Column lg={3} md={4} sm={4}>
          <SideNav
            isFixedNav
            expanded={true}
            isChildOfHeader={false}
            aria-label="Side navigation"
          >
            <SideNavItems>
              {theme.map((t, index4) => (
                <SideNavLink href={'#' + index4} key={`side-link-${index4}`}>
                  <p
                    className={
                      currentTitle === index4
                        ? styles.currentTitle
                        : styles.notCurrentTitle
                    }
                  >
                    {index4 + 1} - {t.title.slice(0, 25)} ...
                  </p>
                </SideNavLink>
              ))}
            </SideNavItems>
          </SideNav>
        </Column>
      </Grid>

      {/* Nav pages */}
      <Grid className={styles.navigationBox} fullWidth>
           <Column lg={8} md={4} sm={4}>
             <a href="/theme7">
               <Button kind="tertiary" className={styles.navButton}>
                 <img
                   alt="arrow"
                   src="/images/icons/arrow--left.svg"
                   className={styles.arrowIcon}
                 />
                 {t('previousTheme')} : <br />
                 {t('theme7')}
               </Button>
             </a>
           </Column>
           <Column lg={8} md={4} sm={4} className={styles.nextNav}>
             <a href="/theme9">
               <Button kind="tertiary" className={styles.navButton}>
                 {t('nextTheme')} : <br />
                 {t('theme9')}
                 <img
                   alt="arrow"
                   src="/images/icons/arrow--right.svg"
                   className={styles.arrowIcon}
                 />
               </Button>
             </a>
           </Column>
         </Grid>
       </Theme>
     </div>
   );
 };

export default Theme8;
