import styles from './Theme.module.scss';
import { Theme, Grid, Column, Link, Button, SideNav, SideNavItems, SideNavLink } from '@carbon/react';
import PoinIBM from '../../Components/PointIBM/PointIBM';
import { theme6_fr, theme6_en } from '../../Data/theme6';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';

const Theme6 = () => {
  const { t, i18n } = useTranslation();
  const theme = i18n.language === "en" ? theme6_en : theme6_fr;
  const [currentTitle, setCurrentTitle] = useState(0);
  const [sizeArray, setSizeArray] = useState([]);

  useEffect(() => {
    try {
      let tempSizeArray = [(document.getElementById('title-theme-6').clientHeight + document.getElementById('0').clientHeight)];

      theme.forEach((elem, index) => {
        tempSizeArray.push(tempSizeArray[index] + document.getElementById(index).clientHeight);
      })
      setSizeArray(tempSizeArray);

    } catch (error) {
      console.log(error);
    }
  }, [currentTitle, theme]);

  useEffect(() => {
    const handleScroll = (event) => {

      sizeArray.forEach((elem, index) => {
        if (index >= 1) {
          if ((sizeArray[index - 1] < window.scrollY) && (window.scrollY < elem)) {
            setCurrentTitle(index);
            return;
          }
        } else if (window.scrollY < elem) {
          setCurrentTitle(index);
          return;
        }
      })
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sizeArray, theme]);

  // For videos - Uncertainly useful:-)
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [i18n.language]); // Dependency array with language, changes when language changes

  // For videos - Works on theme change due to language switch
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [theme]);

  return (
    <div className={styles.theme}>
      {/* Bar en noire */}
      <Theme theme="g100">
        <Grid className={styles.title} fullWidth id="title-theme-6">
          <Column lg={5} md={8} sm={4}>
            <h1>{t('theme6')}</h1>
            <br />
          </Column>
          <Column lg={7} md={8} sm={4}>
            {i18n.language === 'en' ? (
            <p>
              The objective of this package is to secure the use of AI. It is about ensuring the robustness and reliability of AI models.
              The real-world conditions encountered by learning algorithms are sometimes very different from those in which they were developed.
              Generally, environments are non-stationary and it is sometimes difficult to match the development scenario to its use.
              <br />
              <br />In this package, we will question the detection and the consequences of these variations:
              <br />- What happens when the data statistics evolve compared to those used during training?
              <br />- Can we detect these <span>shifts</span> (or an anomaly) in the dataset?
              <br />- Can we protect against contradictory examples (or "<span>adversarial examples</span>" in English) while ensuring reliability and robustness?
              <br />
              <br />These questions are closely related to the problem of dataset shift, known under the name of <span>covariables</span> (or "covariate shift" in English).
              <br />
            </p>
            ) : (
              <p>L'objectif de ce lot est de sécuriser l'utilisation de l'IA. Il s'agit de s'assurer de la robustesse et de la fiabilité des modèles d'IA. 
              Les conditions réelles rencontrées par des algorithmes d'apprentissage sont parfois très différentes de celles dans lesquelles elles ont été développées. 
              En règle générale, les environnements sont non stationnaires et il est parfois difficile de faire correspondre le scénario de développement à son utilisation. 
              <br />
              <br />Dans ce lot, nous nous interrogerons sur la détection et sur les conséquences de ses variations:
              <br />- Que se passe-t-il lorsque les statistiques des données évoluent par rapport à celles utilisées lors de l’entraînement ? 
              <br />- Peut-on détecter ces <span>décalages</span> (ou une anomalie) de l'ensemble de données ? 
              <br />- Peut-on se protéger contre des exemples contradictoires (ou "<span>adversarial examples</span>" en anglais) tout en garantissant fiabilité et robustesse ? 
              <br />
              <br />Ces questions sont étroitement liées au problème du décalage des ensembles de données, connu sur le nom de <span>covariables</span> (ou "covariate shift" en anglais).
              <br />
              </p>
            )}
                     <br />
            {i18n.language === 'en' ? (
              <video
                src="https://aida-videos-challenges.s3.eu-de.cloud-object-storage.appdomain.cloud/Lot-C-OOD-detection-compressed.mp4"
                title="video"
                width="100%"
                height="400px"
                allow="fullscreen"
                controls
              ></video>
              ) : (
                <video
                src="https://aida-videos-challenges.s3.eu-de.cloud-object-storage.appdomain.cloud/Lot-C-OOD-detection-compressed.mp4"
                title="video"
                width="100%"
                height="400px"
                allow="fullscreen"
                controls
              ></video>
            )}
          </Column>
          <Column lg={3} md={7} sm={3}></Column>
          <Column lg={1} md={1} sm={1} className={styles.numberBox}>
            <div className={styles.blueNumber}>6</div>
          </Column>
        </Grid>
      </Theme>

      {/* Les avancées d'AIDA */}
      <Theme theme="white">
        <Grid className={styles.navigationBox} fullWidth>
          <Column lg={13} md={4} sm={4}>
            {theme.map((th, index) => (
              <div className={styles.avanceBox} id={index} key={index}>
                <Grid fullWidth>
                  <Column lg={16} md={8} sm={4}>
                    <span>{t('advances')}</span>
                  </Column>
                </Grid>
                <Grid fullWidth>
                  <Column lg={3} md={2} sm={4}>
                    <h4>
                      {index + 1} - {th.title}
                    </h4>
                    <br />
                  </Column>
                  <Column lg={10} md={6} sm={4}>
                    {th.texte.map((texte, index2) => (
                      <p key={`p-text-${index2}`}>{texte}</p>
                    ))}
                    {th.demoTitle.length !== 0 ? (
                      <>
                        <br />
                        <h6>{th.demoTitle}</h6>
                        <p>
                          {th.demoDes}
                          <br />
                        </p>
                        <br />
                        <video
                          src={th.demoLink}
                          title="video"
                          width="100%"
                          height="400px"
                          allow="fullscreen"
                          controls
                        ></video>
                        <br />
                      </>
                    ) : (
                      <></>
                    )}

                    {th.publicationsTitles.length !== 0 ? (
                      <>
                        <br />
                        <br />
                        <h6>Publications</h6>
                      </>
                    ) : (
                      <></>
                    )}
                    <br />
                    {th.publicationsTitles.map((linkTitle, index3) => (
                      <div key={`link-${index3}`}>
                        <Link href={th.publicationsLinks[index3]}>
                          {linkTitle} -{'>'}
                        </Link>
                        <br />
                        <span>{th.publicationAuthors[index3]}</span>
                        <br />
                        <br />
                      </div>
                    ))}
                    {th.perspectivesAuthor !== '' ? (
                      <>
                        <br />
                        <h6>Perspectives - {th.perspectivesAuthor}</h6>
                        <p>
                          {th.perspectivesText}
                          <br />
                        </p>
                        <br />
                        <br />
                      </>
                    ) : (
                      <></>
                    )}
                    {th.pointIBM ? <PoinIBM /> : <></>}
                  </Column>
                </Grid>
              </div>
            ))}
          </Column>

          <Column lg={3} md={4} sm={4}>
            <SideNav
              isFixedNav
              expanded={true}
              isChildOfHeader={false}
              aria-label="Side navigation"
            >
              <SideNavItems>
                {theme.map((th, index4) => (
                  <SideNavLink href={'#' + index4} key={`side-link-${index4}`}>
                    <p
                      className={
                        currentTitle === index4
                          ? styles.currentTitle
                          : styles.notCurrentTitle
                      }
                    >
                      {index4 + 1} - {th.title.slice(0, 25)} ...
                    </p>
                  </SideNavLink>
                ))}
              </SideNavItems>
            </SideNav>
          </Column>
        </Grid>

        {/* Nav pages */}
        <Grid className={styles.navigationBox} fullWidth>
          <Column lg={8} md={4} sm={4}>
            <a href="/theme3">
              <Button kind="tertiary" className={styles.navButton}>
                <img
                  alt="arrow"
                  src="/images/icons/arrow--left.svg"
                  className={styles.arrowIcon}
                />
                {t('previousTheme')} : <br />
                {t('theme5')}
              </Button>
            </a>
          </Column>
          <Column lg={8} md={4} sm={4} className={styles.nextNav}>
            <a href="/theme5">
              <Button kind="tertiary" className={styles.navButton}>
                {t('nextTheme')} : <br />
                {t('theme7')}
                <img
                  alt="arrow"
                  src="/images/icons/arrow--right.svg"
                  className={styles.arrowIcon}
                />
              </Button>
            </a>
          </Column>
        </Grid>
      </Theme>
    </div>
  );
};

export default Theme6;
