export const theme5_fr = [
  {
    title:
      '(Multi-) Modélisation des décisions intégrant plusieurs IAs dans la plateforme AIDA',
    texte: [
      'La plateforme décisionnelle d’AIDA (composant IBM Automation Decision Services), permet la création et l’édition des artefacts de modèles de décision, de règles métier, de tables de décision, de modèles de tâches, tous pouvant intégrer des modèles prédictifs (intégration possible de tous modèles obtenus par apprentissage automatique). Ces modèles sont tous dotés d’une palette de moyens de mise au point pour les utilisateurs métier : multi-modélisation collaborative et itérative, gestion en projets, et capacité à rendre les modèles exécutables et à les tester immédiatement.',
      'L’intégration des modèles symboliques et des modèles obtenus par apprentissage sur des données est donc complète à design-time et à runtime, est agnostique quand à la technologie ou au composant d’apprentissage (nombreux formats d’importation et d’appel) et a été validée sur des applications industrielles.',
    ],
    demoTitle: '',
    demoDes: "",
    demoLink: '',
    publicationsTitles: [
      "Démonstration introductive à IBM ADS",
      "IBM ADS incorporant l'apprentissage automatique",
      "2 approches pour incorporer des modèles appris dans IBM ADS",
      'Documentation publique du produit commercial IBM Automation Decision Services',
    ],
    publicationsLinks: [
      'https://youtu.be/tW95VdI1WK0?si=bP2xwWrMcDs1AEOE',
      'https://youtu.be/7ZJqt5bexS8',
      'https://youtu.be/7ZJqt5bexS8?si=u3QFvKCN6rVnUdTf',
      'https://www.ibm.com/docs/en/cloud-paks/cp-biz-automation/23.0.1?topic=services-creating-decision-artifacts',
    ],
    publicationAuthors: [],
    texte_a: [''],
    perspectivesAuthor: 'IBM',
    perspectivesText: [
      'État d’avancement: produit commercial déployé',
    ]
  },
  {
    title: 'Induction de règles dans la plateforme AIDA',
    texte: [
      'Six axes de R&D ont fait l’objet de contributions d’IBM dans le cadre d’AIDA:',
      'A. Importation « transparente » de modèles d’apprentissage automatique',
      'B. Amélioration de RIPPER',
      'C. Induction de règles avec des données séquentielles',
      'D. Publication des évolutions de l’algorithme R2N en Open Source',
      'E. Induction de scorecards',
      'F. Vers un benchmarking de l’induction de règles',
      'Le champ de R&D couvert par ces travaux en matière d’induction de règles, en complément et collaboration avec le LISN sur la fouille de règles, fait du consortium AIDA l’équipe de R&D l plus avancée sur le sujet et donne à la plateforme AIDA une très forte avance technologique.',
    ],
    demoTitle: '',
    demoDes: "",
    demoLink: '',
    publicationsTitles: [],
    publicationsLinks: [],
    publicationAuthors: [],
    texte_a: [],
    perspectivesAuthor: '',
    perspectivesText: [
      '',
    ],
    pointIBM7: false,
  },
  {
    title: 'Cycle de vie et gouvernance des décisions dans la plateforme AIDA',
    texte: [
      "Dans la plateforme décisionnelle d’AIDA, tous les artefacts peuvent se présenter comme une arborescence de fichiers versionnée, tout en permettant d’en maintenir une vue structurée pour l'écriture des décisions, et de façon à être manipulables simplement par l’utilisateur métier. La gouvernance est donc aussi puissante que Git, et est abstraite pour que l’utilisateur métier puisse configurer et gérer des projets, générer des services de décision, modifier les modèles de décision et générer les services exécutables à nouveau, etc. L’approche est intégrée pour tous les types d’artefacts de décision et permet un déploiement immédiat dans un contexte d’exécution dûment défini.",
    ],
    demoTitle: '',
    demoDes: "",
    demoLink: '',
    publicationsTitles: [
      'Documentation publique du produit commercial Automation Decision Services : ',
    ],
    publicationsLinks: [
      'https://www.ibm.com/docs/en/cloud-paks/cp-biz-automation/23.0.1?topic=services-managing-decisions',
    ],
    publicationAuthors: [],
    texte_a: [''],
    perspectivesAuthor: 'IBM',
    perspectivesText: [
      'État d’avancement: produit commercial déployé',
    ]
  },
  {
    title: 'Simulation et comparaison de décisions dans la plateforme AIDA',
    texte: [
      'La plateforme décisionnelle d’AIDA est dotée d’une approche générique de la simulation et de la supervision de systèmes de décision automatique appliqués à des jeux de données massifs, en vue de leur validation.',
      ' Le système de simulation proposé adresse, indépendamment de la technologie de résolution de problème ou de prise de décision, le besoin pour tout utilisateur métier de prendre la mesure de ce que le système de décision peut produire comme informations, recommandations, actions, en vérifier la validité sur des données représentatives des conditions de production et de pouvoir le faire avant une (première ou nouvelle) mise en production. Le composant (nom de code « Simulatte ») permet de réaliser des simulations selon trois modes gradués :',
      "- Simulation unitaire : exécution d'un (seul) service de décision à partir d'une source de données et d’un réservoir de données décisionnelles, et analyse a postériori du résultat de la simulation (calcul de métriques et KPIs, et production de vues) ;",
      '- Multi-simulation comparative : deux simulations utilisant un même KPIs sont automatiquement comparées et font l’objet d’une visualisation des différences ;',
      '- Simulation pour l’optimisation : un paramètre logique d’une décision peut être modifié automatiquement pour capturer la variation du système de décision sur un jeu de données, et ainsi permettre la recherche de la valeur optimale.',
    ],
    demoTitle: 'SimuLatte: projet open source de simulations décisionnelles [IBM]',
    demoDes: "",
    demoLink:
      'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/IBM%20-%20AIDA%20-%20Simulations%20decisionnelles_FR.mp4',
    publicationsTitles: [
      'Les composants Simulatte livrés en mode Open Source public sur Github. ',
      'Les experimentations Simulatte livrées en mode Open Source public sur Github. ',
    ],
    publicationsLinks: [
      'https://github.com/DecisionsDev/simulatte', 
      'https://github.com/DecisionsDev/decisions-analytics'],
    publicationAuthors: [],
    texte_a: [],
    perspectivesAuthor: 'IBM',
    perspectivesText: [
      "Le support des simulations est considéré pour une productisation plus large d'intelligence décisionnelle",
    ]
  },
  {
    title: 'Explications composites des décisions dans la plateforme AIDA',
    texte: [
      'La réalisation d’une technologie d’explication des décisions basées sur la combinaison de connaissances expertes ou apprises a été menée dans trois direction:',
      <br />,
      <h6>A. Intégration sémantique des connaissances expertes et apprises.',
        'La plateforme décisionnelle d’AIDA permet d’intégrer nativement dans les langages de règles métier les capacités prédictives fournies par des services d’apprentissage automatique et d’avoir une palette complète d’expressions prescriptives.</h6>,
      'Etat d’avancement: produit commercial déployé.',
      <br />,
      <h6>B. Intégration d’explications</h6>,
      "L’exploration des possibilités d’intégration d’explications pour des systèmes mettant en œuvre un apprentissage automatique ont permis de contribuer à la mise au point d’une méthode d’interprétabilité appelée SMACE (qui combine une approche géométrique pour les règles de décision avec les méthodes d'interprétabilité existantes pour les modèles d'apprentissage automatique afin de générer un classement intuitif des caractéristiques adapté à l'utilisateur final. Alors que les approches diagnostiques de modèles établies produisent de mauvais résultats sur des données tabulaires (en particulier en donnant la même importance à plusieurs caractéristiques), les expérimentations montrent que SMACE permet de les classer avec une performance sémantique accrue.",
    ],
    demoTitle: '',
    demoDes: "",
    demoLink: '',
    publicationsTitles: [
      'SMACE: A New Method for the Interpretability of Composite Decision Systems',
    ],
    publicationsLinks: [
      'https://2022.ecmlpkdd.org/wp-content/uploads/2022/09/sub_648.pdf',
    ],
    publicationAuthors: [
      'Gianluigi Lopardo, Damien Garreau, Frederic Precioso, Greger Ottosson  ECML PKDD 2022 : the European Conference on Machine Learning and Principles and Practice of Knowledge Discovery in Databases .',
    ],
    texte_a: [' '],
    perspectivesAuthor: 'IBM',
    perspectivesText: [
      'Recherche à poursuivre : expérimentations et évolutions algorithmiques à prévoir.',
    ]
  },
];

export const theme5_en = [{
  title:
    '(Multi-) Decision modelling integrating several IAs in the AIDA platform',
  texte: [
    "The AIDA decision support platform (IBM Automation Decision Services component) enables the creation and editing of decision model artefacts, business rules, decision tables and task models, all of which can incorporate predictive models (all models obtained by machine learning can be integrated). These models are all equipped with a range of development resources for business users: collaborative and iterative multi-modelling, project management, and the ability to make models executable and test them immediately",
    "The integration of symbolic models and models obtained by learning from data is therefore complete at design-time and runtime, is agnostic when it comes to the learning technology or component (numerous import and call formats) and has been validated on industrial applications",
  ],
  demoTitle: '',
  demoDes: "",
  demoLink: '',
  publicationsTitles: [
    "General IBM ADS demo",
    "IBM ADS with Machine Learning",
    '2 ways to integrate ML in IBM ADS',
    'Public documentation for the IBM Automation Decision Services commercial product',
  ],
  publicationsLinks: [
    'https://youtu.be/tW95VdI1WK0?si=bP2xwWrMcDs1AEOE',
    'https://youtu.be/7ZJqt5bexS8',
    'https://youtu.be/7ZJqt5bexS8?si=u3QFvKCN6rVnUdTf',
    'https://www.ibm.com/docs/en/cloud-paks/cp-biz-automation/23.0.1?topic=services-creating-decision-artifacts',
  ],
  publicationAuthors: [],
  texte_a: [''],
  perspectivesAuthor: 'IBM',
  perspectivesText: [
    'Status: commercial product deployed',
  ]
},
{
  title: 'Induction of rules in the AIDA platform',
  texte: [
    'IBM has contributed to AIDA in six R&D areas:',
    "A. 'Transparent' import of machine learning models",
    "B. Improvement of RIPPER",
    'C. Rule induction with sequential data',
    'D. Publication of R2N algorithm evolutions in Open Source',
    "E. Induction of scorecards",
    'F. Towards benchmarking rule induction',
    "The field of R&D covered by this work on rule induction, in addition to and in collaboration with LISN on rule mining, makes the AIDA consortium the most advanced R&D team on the subject and gives the AIDA platform a very strong technological lead",
  ],
  demoTitle: '',
  demoDes: "",
  demoLink: '',
  publicationsTitles: [],
  publicationsLinks: [],
  publicationAuthors: [],
  texte_a: [],
  perspectivesAuthor: '',
  perspectivesText: [
    '',
  ],
  pointIBM7: false,
},
{
  title: 'Decision life cycle and governance in the AIDA platform',
  texte: ["In the AIDA decision platform, all the artefacts can be presented as a versioned tree of files, while allowing a structured view to be maintained for writing decisions, and in such a way that they can be manipulated simply by the business user. Governance is therefore as powerful as Git, and is abstracted so that the business user can configure and manage projects, generate decision services, modify decision models and generate executable services again, and so on. The approach is integrated for all types of decision artefacts and enables immediate deployment in a duly defined execution context",
  ],
  demoTitle: '',
  demoDes: "",
  demoLink: '',
  publicationsTitles: [
    'Documentation publique du produit commercial Automation Decision Services : ',
  ],
  publicationsLinks: [
    'https://www.ibm.com/docs/en/cloud-paks/cp-biz-automation/23.0.1?topic=services-managing-decisions',
  ],
  publicationAuthors: [],
  texte_a: [''],
  perspectivesAuthor: 'IBM',
  perspectivesText: [
    'Status: commercial product deployed',
  ]
},
{
  title: 'Simulation and comparison of decisions in the AIDA platform',
  texte: [
    "AIDA's decision support platform is equipped with a generic approach to the simulation and supervision of automatic decision systems applied to massive data sets, with a view to their validation",
    "The proposed simulation system addresses, independently of the problem-solving or decision-making technology, the need for any business user to take the measure of what the decision-making system can produce in terms of information, recommendations and actions, to check their validity on data representative of production conditions and to be able to do this before a (first or new) production launch. The component (codename 'Simulatte') enables simulations to be carried out in three graduated modes:",
    "Unit simulation: execution of a (single) decision service from a data source and a reservoir of BI data, and a posteriori analysis of the simulation result (calculation of metrics and KPIs, and production of views);",
    '- Comparative multi-simulation: two simulations using the same KPIs are automatically compared and the differences are displayed;',
    "Simulation for optimisation: a logical parameter of a decision can be automatically modified to capture the variation of the decision system on a set of data, and thus enable the search for the optimal value",
  ],
  demoTitle:
    'SimuLatte: open source project for decision simulations [IBM]',
  demoDes: "",
  demoLink:
    'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/IBM%20-%20AIDA%20-%20Simulations%20decisionnelles_EN.mp4',
  publicationsTitles: [
    'SimuLatte components delivered in Open Source on Github.',
    'SimuLatte experiments delivered in Open source on Github.',
  ],
  publicationsLinks: [
    'https://github.com/DecisionsDev/simulatte', 
    'https://github.com/DecisionsDev/decisions-analytics',
  ],
  publicationAuthors: [],
  texte_a: [],
  perspectivesAuthor: 'IBM',
  perspectivesText: [
    'Simulations are a central piece for product evolutions in a larger decision intteligence space.'
  ]
},
{
  title: 'Composite explanations of decisions in the AIDA platform',
  texte: [
    'The development of a technology to explain decisions based on the combination of expert or learned knowledge has been pursued in three directions:',
    <br />,
    <h6>A. Semantic integration of expert and learned knowledge.</h6>,
    "AIDA's BI platform enables the predictive capabilities provided by machine learning services to be integrated natively into business rules languages, providing a complete range of prescriptive expressions",
    "Status: commercial product deployed",
    <br />,
    <h6>B. Integration of explanations</h6>,
    "Exploring the possibilities of integrating explanations for systems implementing machine learning have contributed to the development of an interpretability method called SMACE (which combines a geometric approach for decision rules with existing interpretability methods for machine learning models to generate intuitive feature ranking tailored to the end user. While established model diagnostic approaches produce poor results on tabular data (particularly by giving the same importance to multiple features), experiments show that SMACE can rank them with improved semantic performance.",
  ],
  demoTitle: '',
  demoDes: "",
  demoLink: '',
  publicationsTitles: [
    'SMACE: A New Method for the Interpretability of Composite Decision Systems',
  ],
  publicationsLinks: [
    'https://2022.ecmlpkdd.org/wp-content/uploads/2022/09/sub_648.pdf',
  ],
  publicationAuthors: [
    'Gianluigi Lopardo, Damien Garreau, Frederic Precioso, Greger Ottosson  ECML PKDD 2022 : the European Conference on Machine Learning and Principles and Practice of Knowledge Discovery in Databases .',
  ],
  texte_a: [
    '',
  ],
  perspectivesAuthor: 'IBM',
  perspectivesText: [
    'Research to be continued: experimentation and algorithmic developments to be planned.',
  ],
  pointIBM7: false,
},];

export const axes_fr = [
  {
    title:
      'A. Importation « transparente » de modèles d’apprentissage automatique',
    texte: [
      'IBM a réalisé une intégration des techniques d’induction de règles dans la plateforme décisionnelle d’AIDA pour répondre aux problématiques suivantes :',
      ' - si nous pouvons, dans une certaine mesure, expliquer des prédictions individuelles à partir de modèles ML en boîte noire, comment construire et utiliser des modèles ML qui soient intrinsèquement interprétables ?',
      ' - apprendre des règles plutôt que des boîtes noires rend possible de valider le modèle avant sa mise en production, en effet les approches à base d’explication a posteriori ne sont pas applicables dans la plupart des cas d’automatisation de la décision (par opposition à l’aide à la décision).',
      "Il est donc maintenant possible d'importer un tel type de modèle ML sous forme d’un ensemble de règles. L'utilisation d'ensembles de règles comme type de modèle transparent pour faire des prédictions est très naturel dans la plateforme AIDA puisque la plateforme de décision est construite autour de la création et de la gestion de règles métier. La seule différence est que dans ce cas les règles importées ne sont pas rédigées à la main par des analystes commerciaux, mais sont apprises en externe à partir de données historiques.",
      "Le flux global d'apprentissage et d'utilisation des ensembles de règles dans les décisions est essentiellement le même que celui d'un pipeline de formation ML ordinaire, la principale exception étant la dernière étape où nous importons les modèles ML en tant qu'ensemble de règles, au lieu de déployer le modèle ML derrière une API REST et de faire une invocation à distance.",
    ],
    demoTitle: '',
    demoDes: "",
    demoLink: '',
    publicationsTitles: [
      'Documentation publique du produit commercial Automation Decision Services',
    ],
    publicationsLinks: [
      'https://www.ibm.com/docs/en/cloud-paks/cp-biz-automation/22.0.1?topic=services-integrating-machine-learning',
    ],
    publicationAuthors: ['Etat d’avancement: produit commercial déployé.'],
    pointIBM: false,
    texte_a: [],
  },
  {
    title: 'B. Amélioration de RIPPER',
    texte: [
      'Les travaux d’amélioration de RIPPER ont donné des résultats remarquables en matière d’amélioration des performances de l’algorithme réécrit par l’équipe IBM. ',
      'Une analyse de performance interne à RIPPER a été entreprise. Des améliorations du code ont été apportées, notamment durant la phase d’initialisation de l’algorithme. Il a été constaté des réductions considérables des temps d’exécution. Le gain en temps observé sur la plateforme de tests IBM en utilisant des données synthétiques balancées générées à partir d’une seule règle composée de deux conditions simples est de x14 pour 100000 lignes et de x51 pour 300000 lignes.',
    ],
    demoTitle: '',
    demoDes: "",
    demoLink: '',
    publicationsTitles: [],
    publicationsLinks: [],
    publicationAuthors: [],
    pointIBM: false,
    texte_a: [],
  },
  {
    title: 'C. Induction de règles avec des données séquentielles',
    texte: [
      'Recherche et développement sur l’induction de règles, c’est-à-dire la possibilité de produire de règles symboliques par apprentissage sur un jeu de données, qui est centrée sur ',
      "- l’expressivité des règles (les méthodes existantes d'induction de règles aboutissent souvent à des modèles de règles longs et donc moins interprétables), ",

      '- la définition des littéraux par apprentissage par réseau de neurones différentiable de bout en bout (la plupart des méthodes existantes supposent des littéraux prédéfinis),',

      '- l’induction sur des données séquentielles produisant des règles qui prennent en compte la position d’un fait dans une séquence (fondamental par exemple pour les applications de détection de fraude, de traitement de la parole ou de diagnostic génomique).',
    ],
    demoTitle: '',
    demoDes: "",
    demoLink: '',
    publicationsTitles: [
      'Differentiable Rule Induction with Learned Relational Features',
      'Learning binary classification rules for sequential data',
      'Neural-based Classification Rule Learning for Sequential Data',
    ],
    publicationsLinks: [
      'https://ceur-ws.org/Vol-3212/paper3.pdf',
      'https://strl2022.github.io/files/short1.pdf',
      'https://arxiv.org/abs/2302.11286',
    ],
    publicationAuthors: [
      'Remy Kusters, Yusik Kim, Marine Collery, Christian de Sainte Marie, Shubham Gupta,16th International Workshop on Neural-Symbolic Learning and Reasoning, Sep. 2022.',
      'Marine Collery, Remy Kusters.“1st International Workshop on Spatio-Temporal Reasoning and Learning 2022” --- IJCAI-ECAI 2022',
      'Marine Collery, Philippe Bonnard, François Fages & Remy Kusters(IBM France Lab (1,2), Inria Saclay (3), IBM (France) Research (4))Published as a conference paper at ICLR 2023 ',
    ],
    pointIBM: true,
    texte_a: [],
  },
  {
    title: 'D. Publication des évolutions de l’algorithme R2N en Open Source',
    texte: [
      ' L’implémentation de R2N qui a fait l’objet des travaux tels que présentés dans l’article « Differentiable Rule Induction with Learned Relational Features » (référence ci-dessus), ont été publiés en Open Source sur le site suivant : https://github.com/IBM/rulelearn. ',
      'Le package mis en Open Source sous le nom “rulelearn (v0.1)” contient un toolkit d’induction de règles à partir de datasets.',
    ],
    demoTitle: '',
    demoDes: "",
    demoLink: '',
    publicationsTitles: [],
    publicationsLinks: [],
    publicationAuthors: [],
    pointIBM: false,
    texte_a: [],
  },
  {
    title: 'E. Induction de scorecards',
    texte: [
      'Cette recherche apporte quatre contributions en matière d’induction de scorecards à partir de données par apprentissage automatique :',
      '1. une architecture différentiable de bout en bout qui apprend conjointement les extrémités des cases et leurs sous-cotes associées ;',

      '2. une nouvelle variante de scorecard que nous appelons carte de coefficient scorecard ;',

      '3. la différenciation de bout en bout des modèles proposés pour produire des scorecards sur des caractéristiques composées qui sont elles-mêmes apprises en même temps que la scorecard.',

      "4. des stratégies pour sélectionner automatiquement le nombre de cases pour chaque caractéristique lors de l'apprentissage de l'un des modèles proposés.",
    ],
    demoTitle: '',
    demoDes: "",
    demoLink: '',
    publicationsTitles: ['On a differentiable model for learning scorecards'],
    publicationsLinks: [],
    publicationAuthors: [
      'Shubham Gupta, Remy Kusters, and Christian de Sainte Marie,  IBM Research & IBM France Lab, Orsay, France',
    ],
    pointIBM: false,
    texte_a: [
      'Recherche à poursuivre : expérimentations et évolutions algorithmiques à prévoir.',
      'Horizon : débouchés industriels en 2026.',
    ],
  },
  {
    title: 'F. Vers un benchmarking de l’induction de règles',
    texte: [
      " En complément des travaux sur R2N, l’équipe de recherche d’IBM France (IBM Research et IBM France Lab) a contribué à des travaux de recherche en vue de l’établissement d’un benchmarking « structuré » de l’induction de règles, sous forme d’une suite d'analyse comparative extensible de problèmes de classification de décisions métier binaires typiques et des résultats pour plusieurs pipelines d'induction de règles (es pipelines comprennent des algorithmes classiques ainsi que des approches récentes prometteuses).",
    ],
    demoTitle: '',
    demoDes: "",
    demoLink: '',
    publicationsTitles: [
      'Towards Comprehensive Benchmarking for Rule Induction in Transparent Automated Business Decisions',
    ],
    publicationsLinks: [],
    publicationAuthors: [
      ' Hagen Völzer 1,3, Daniel Horn 2, Yusik Kim 3, and Greger Ottosson 4 1 University of St. Gallen, Switzerland 2 IBM Germany 3 IBM Research – Europe (IBM France) 4 IBM France Lab',
    ],
    pointIBM: false,
    perspectivesAuthor: 'IBM',
    perspectivesText: [
      'Recherche à poursuivre annuellement (de l’ordre de la production d’un état de l’art).',
    ],
    texte_a: [
      'Recherche à poursuivre annuellement (de l’ordre de la production d’un état de l’art). ',
    ],
  },
];

export const axes_en = [{
  title:
    'A. "Transparent" import of machine learning models',
  texte: [
    'IBM has integrated rule induction techniques into the AIDA BI platform to address the following issues:',
    '- If we can, to some extent, explain individual predictions from black-box ML models, how can we build and use ML models that are intrinsically interpretable?',
    '- learning rules rather than black boxes makes it possible to validate the model before it is put into production, since a posteriori explanation-based approaches are not applicable in most cases of decision automation (as opposed to decision support)',
    "It is now possible to import this type of ML model in the form of a set of rules. The use of rule sets as a type of transparent model for making predictions is very natural in the AIDA platform, since the decision platform is built around the creation and management of business rules. The only difference is that in this case the imported rules are not hand-written by business analysts, but are learned externally from historical data.",
    "The overall flow of learning and using rule sets in decisions is essentially the same as an ordinary ML training pipeline, the main exception being the last step where we import the ML models as a rule set, instead of deploying the ML model behind a REST API and doing a remote invocation.",

  ],
  demoTitle: '',
  demoDes: "",
  demoLink: '',
  publicationsTitles: [
    'Public documentation for the Automation Decision Services commercial product',
  ],
  publicationsLinks: [
    'https://www.ibm.com/docs/en/cloud-paks/cp-biz-automation/22.0.1?topic=services-integrating-machine-learning',
  ],
  publicationAuthors: ['Status: commercial product deployed.'],
  pointIBM: false,
  texte_a: [],
}, {
  title: 'B. Improving RIPPER',
  texte: [
    "RIPPER's improvement work has produced remarkable results in terms of improving the performance of the algorithm rewritten by the IBM team. ",
    "An internal performance analysis of RIPPER has been undertaken. Improvements were made to the code, particularly during the initialization phase of the algorithm. Considerable reductions in execution times were observed. The time saving observed on the IBM test platform using balanced synthetic data generated from a single rule composed of two simple conditions is x14 for 100,000 rows and x51 for 300,000 rows",
  ],
  demoTitle: '',
  demoDes: "",
  demoLink: '',
  publicationsTitles: [],
  publicationsLinks: [],
  publicationAuthors: [],
  pointIBM: false,
  texte_a: [],
},
{
  title: 'C. Induction of rules with sequential data',
  texte: ["Research and development on rule induction, i.e. the ability to produce symbolic rules by learning from a dataset, which focuses on ",
    "the expressiveness of rules (existing rule induction methods often result in long rule models that are less interpretable),",

    "the definition of literals by end-to-end differentiable neural network learning (most existing methods assume predefined literals),",

    "induction on sequential data producing rules that take into account the position of a fact in a sequence (fundamental, for example, for applications in fraud detection, speech processing or genomic diagnosis)",
  ],
  demoTitle: '',
  demoDes: "",
  demoLink: '',
  publicationsTitles: [
    'Differentiable Rule Induction with Learned Relational Features',
    'Learning binary classification rules for sequential data',
    'Neural-based Classification Rule Learning for Sequential Data',
  ],
  publicationsLinks: [
    'https://ceur-ws.org/Vol-3212/paper3.pdf',
    'https://strl2022.github.io/files/short1.pdf',
    'https://arxiv.org/abs/2302.11286',
  ],
  publicationAuthors: [
    'Remy Kusters, Yusik Kim, Marine Collery, Christian de Sainte Marie, Shubham Gupta,16th International Workshop on Neural-Symbolic Learning and Reasoning, Sep. 2022.',
    'Marine Collery, Remy Kusters.“1st International Workshop on Spatio-Temporal Reasoning and Learning 2022” --- IJCAI-ECAI 2022',
    'Marine Collery, Philippe Bonnard, François Fages & Remy Kusters(IBM France Lab (1,2), Inria Saclay (3), IBM (France) Research (4))Published as a conference paper at ICLR 2023 ',
  ],
  pointIBM: true,
  texte_a: [],
},
{
  title: 'D. Publication of R2N algorithm developments in Open Source',
  texte: [
    ' The implementation of R2N that was the subject of the work as presented in the article "Differentiable Rule Induction with Learned Relational Features" (reference above), have been published as Open Source on the following site: https://github.com/IBM/rulelearn. ',
    'The package released as Open Source under the name "rulelearn (v0.1)" contains a toolkit for inducing rules from datasets.',
  ],
  demoTitle: '',
  demoDes: "",
  demoLink: '',
  publicationsTitles: [],
  publicationsLinks: [],
  publicationAuthors: [],
  pointIBM: false,
  texte_a: [],
},
{
  title: 'E. Induction des tableaux de bord',
  texte: [
    'This research makes four contributions to scorecard induction from machine learning data:',
    '1. an end-to-end differentiable architecture that jointly learns the ends of cells and their associated sub-scores;',

    '2. a new scorecard variant that we call a coefficient scorecard;',

    '3. end-to-end differentiation of the proposed models to produce scorecards on compound features that are themselves learned along with the scorecard.',

    "4. strategies for automatically selecting the number of cells for each feature when learning one of the proposed models",
  ],
  demoTitle: '',
  demoDes: "",
  demoLink: '',
  publicationsTitles: ['On a differentiable model for learning scorecards'],
  publicationsLinks: [],
  publicationAuthors: [
    'Shubham Gupta, Remy Kusters, and Christian de Sainte Marie,  IBM Research & IBM France Lab, Orsay, France',
  ],
  pointIBM: false,
  texte_a: [
    'Research to be continued: experimentation and algorithmic developments to be planned',
    'Horizon: industrial opportunities in 2026',
  ],
},
{
  title: 'F. Towards benchmarking rule induction',
  texte: ["In addition to the work on R2N, the IBM France research team (IBM Research and IBM France Lab) has contributed to research work to establish a \"structured\" benchmarking of rule induction, in the form of an extensible benchmarking suite of typical binary business decision classification problems and results for several rule induction pipelines (es pipelines include classic algorithms as well as promising recent approaches)."
  ],
  demoTitle: '',
  demoDes: "",
  demoLink: '',
  publicationsTitles: [
    'Towards Comprehensive Benchmarking for Rule Induction in Transparent Automated Business Decisions',
  ],
  publicationsLinks: [],
  publicationAuthors: [
    ' Hagen Völzer 1,3, Daniel Horn 2, Yusik Kim 3, and Greger Ottosson 4 1 University of St. Gallen, Switzerland 2 IBM Germany 3 IBM Research – Europe (IBM France) 4 IBM France Lab',
  ],
  pointIBM: false,
  texte_a: [
    'Research to be continued on an annual basis (of the order of producing a state of the art report).',
  ],
},];
