import styles from './Theme.module.scss';
import { Theme, Grid, Column, Link, Button, SideNav, SideNavItems, SideNavLink } from '@carbon/react';
import PoinIBM from '../../Components/PointIBM/PointIBM';
import { theme10_fr, theme10_en } from '../../Data/theme10';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';

const Theme10 = () => {
  const { t, i18n } = useTranslation();
  const theme = i18n.language === "en" ? theme10_en : theme10_fr;
  const [currentTitle, setCurrentTitle] = useState(0);
  const [sizeArray, setSizeArray] = useState([]);

  useEffect(() => {
    try {
      let tempSizeArray = [(document.getElementById('title-theme-3').clientHeight + document.getElementById('0').clientHeight)];

      theme.forEach((elem, index) => {
        tempSizeArray.push(tempSizeArray[index] + document.getElementById(index).clientHeight);
      })
      setSizeArray(tempSizeArray);

    } catch (error) {
      console.log(error);
    }
  }, [currentTitle, theme]);

  useEffect(() => {
    const handleScroll = (event) => {

      sizeArray.forEach((elem, index) => {
        if (index >= 1) {
          if ((sizeArray[index - 1] < window.scrollY) && (window.scrollY < elem)) {
            setCurrentTitle(index);
            return;
          }
        } else if (window.scrollY < elem) {
          setCurrentTitle(index);
          return;
        }
      })
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sizeArray, theme]);

  // For videos - Uncertainly useful:-)
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [i18n.language]); // Dependency array with language, changes when language changes

  // For videos - Works on theme change due to language switch
  useEffect(() => {
    // Function to stop all iframes (like videos) from playing
    const pauseAllIframeVideos = () => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        console.log('Pausing iframe with src:', iframe.src);
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };

    // Stop videos when the language changes
    pauseAllIframeVideos();

  }, [theme]);

  return (
    <div className={styles.theme}>
      {/* Bar en noire */}
      <Theme theme="g100">
        <Grid className={styles.title} fullWidth id="title-theme-10">
          <Column lg={5} md={8} sm={4}>
            <h1>{t('theme10')}</h1>
            <br />
          </Column>
          <Column lg={7} md={8} sm={4}>
            {i18n.language === 'en' ? (
              <p>
              The rapid evolution of online payments and the emergence of new methods such as instant payment require increasingly sophisticated analyses to effectively combat fraud and <span>ensure transaction security</span>.
              STET, in collaboration with IBM, is innovating in this field by exploiting intelligent automation to optimize crucial tasks. This includes supporting data scientists in the rapid development of new anti-fraud rules, adapted to emerging trends.
              <br /><br />The objective is to exhaustively identify different fraud scenarios and to follow their constant evolution.
              The effectiveness of AI lies in its ability to facilitate decision-making. For this, it is essential that the <span>rules generated by AI</span> are clear and understandable, thus allowing easy human interpretation and validation.
              Speed is a major issue: we must analyze the millions of transactions carried out each day, with peaks that can reach more than 1000 transactions per second, and <span>detect cases of fraud in real time</span> and in just a few milliseconds.
              Parallel to this, it is crucial to minimize false positives, these erroneous alerts that hinder the user experience.
              <br /><br />Our research, conducted in partnership with IBM as part of the AIDA project, strengthens the reliability of our AI models dedicated to detecting payment fraud. Their continuous improvement aims to increase their transparency, thus establishing a solid trust environment with our internal teams and offering significant added value to our clients.
              </p>
            ) : (
              <p>
              L'évolution rapide des paiements en ligne et l'émergence de nouvelles méthodes telles que l'instant paiement exigent des analyses de plus en plus sophistiquées pour combattre efficacement la fraude et assurer la <span>sécurité</span> des transactions.
              STET, en collaboration avec IBM, innove dans ce domaine en exploitant l'automatisation intelligente pour optimiser des tâches cruciales. Cela inclut notamment le soutien aux data scientists dans l'élaboration rapide de nouvelles règles antifraude, adaptées aux tendances émergentes. 
              <br /><br />L'objectif est d'identifier exhaustivement les différents scénarios de fraude et de suivre leur évolution constante.
              L'efficacité de l'IA réside dans sa capacité à faciliter les prises de décisions. Pour cela, il est essentiel que les <span>règles générées par l'IA</span> soient claires et compréhensibles, permettant ainsi une interprétation et une validation humaine aisées. 
              La rapidité est un enjeu majeur : nous devons analyser les millions de transactions effectuées chaque jour, avec des pics pouvant atteindre plus de 1000 transactions par seconde, et <span>détecter en temps réel</span> et en quelques millisecondes les cas de fraude. 
              En parallèle, il est crucial de minimiser les faux positifs, ces alertes erronées qui entravent l'expérience utilisateur.
              <br /><br />Nos recherches menées en partenariat avec IBM dans le cadre du projet AIDA renforcent la fiabilité de nos modèles d'IA dédiés à la détection de fraudes dans les paiements. Leur amélioration continue vise à accroître leur transparence, instaurant ainsi un climat de confiance solide avec nos équipes internes et offrant une valeur ajoutée significative à nos clients.
              </p>
            )}
            <br />
            <video
              src="https://aida-acteurs.s3.eu-de.cloud-object-storage.appdomain.cloud/STET-Rodolphe-Meyer.mp4"
              title="video"
              width="100%"
              height="400px"
              allow="fullscreen"
              controls
            ></video>
          </Column>
          <Column lg={3} md={7} sm={3}></Column>
          <Column lg={1} md={1} sm={1} className={styles.numberBox}>
            <div className={styles.blueNumber}>10</div>
          </Column>
        </Grid>
      </Theme>

      {/* Les avancées d'AIDA */}
      <Theme theme="white">
        <Grid className={styles.navigationBox} fullWidth>
          <Column lg={13} md={4} sm={4}>
            {theme.map((th, index) => (
              <div className={styles.avanceBox} id={index} key={index}>
                <Grid fullWidth>
                  <Column lg={16} md={8} sm={4}>
                    <span>{t('advances')}</span>
                  </Column>
                </Grid>
                <Grid fullWidth>
                  <Column lg={3} md={2} sm={4}>
                    <h4>
                      {index + 1} - {th.title}
                    </h4>
                    <br />
                  </Column>
                  <Column lg={10} md={6} sm={4}>
                    {th.texte.map((texte, index2) => (
                      <p key={`p-text-${index2}`}>{texte}</p>
                    ))}
                    {th.demoTitle.length !== 0 ? (
                      <>
                        <br />
                        <h6>{th.demoTitle}</h6>
                        <p>
                          {th.demoDes}
                          <br />
                        </p>
                        <br />
                        <video
                          src={th.demoLink}
                          title="video"
                          width="100%"
                          height="400px"
                          allow="fullscreen"
                          controls
                        ></video>
                        <br />
                      </>
                    ) : (
                      <></>
                    )}

                    {th.publicationsTitles.length !== 0 ? (
                      <>
                        <br />
                        <br />
                        <h6>Publications</h6>
                      </>
                    ) : (
                      <></>
                    )}
                    <br />
                    {th.publicationsTitles.map((linkTitle, index3) => (
                      <div key={`link-${index3}`}>
                        <Link href={th.publicationsLinks[index3]}>
                          {linkTitle} -{'>'}
                        </Link>
                        <br />
                        <span>{th.publicationAuthors[index3]}</span>
                        <br />
                        <br />
                      </div>
                    ))}
                    {th.pointIBM ? <PoinIBM /> : <></>}
                  </Column>
                </Grid>
              </div>
            ))}
          </Column>

          <Column lg={3} md={4} sm={4}>
            <SideNav
              isFixedNav
              expanded={true}
              isChildOfHeader={false}
              aria-label="Side navigation"
            >
              <SideNavItems>
                {theme.map((t, index4) => (
                  <SideNavLink href={'#' + index4} key={`side-link-${index4}`}>
                    <p
                      className={
                        currentTitle === index4
                          ? styles.currentTitle
                          : styles.notCurrentTitle
                      }
                    >
                      {index4 + 1} - {t.title.slice(0, 25)} ...
                    </p>
                  </SideNavLink>
                ))}
              </SideNavItems>
            </SideNav>
          </Column>
        </Grid>

        {/* Nav pages */}
        <Grid className={styles.navigationBox} fullWidth>
          <Column lg={8} md={4} sm={4}>
            <a href="/theme9">
              <Button kind="tertiary" className={styles.navButton}>
                <img
                  alt="arrow"
                  src="/images/icons/arrow--left.svg"
                  className={styles.arrowIcon}
                />
                {t('previousTheme')} : <br />
                {t('theme9')}
              </Button>
            </a>
          </Column>
          <Column lg={8} md={4} sm={4} className={styles.nextNav}></Column>
        </Grid>
      </Theme>
    </div>
  );
};

export default Theme10;
