export const theme9_fr = [
  {
    title: 'Gestion des données pour des solutions d’optimisation',
    texte: [
      "Les méthodes d’optimisation mathématique sont extrêmement sensibles à la disponibilité et qualité des données.",
      "Les étapes de collecte, préparation, et nettoyage des données sont donc essentielles pour obtenir les bonnes solutions avec les méthodes d’optimisation.",
      " De plus en plus aujourd’hui on voit des avantages à exploiter des données externes à l’entreprise et des données en temps réel.",
      "Grace à AIDA, DecisionBrain a fait évoluer les services nécessaires pour collecter, monitorer, préparer et orchestrer les données nécessaires à l’exécution des fonctionnalités d'optimisation de nos solutions.",
      'L’objectif est donc de travailler sur les données fournies en entrée aux solutions développées par DecisionBrain afin d’en améliorer la qualité.',
      'Cela passe par des outils de détection d’anomalie de données, d’alertes, des outils de complétion de données, des connecteurs et des outils de manipulation de données.',
      "Nous avons développé une méthodologie et des outils génériques pour traiter les questions de qualité des données et de détection des anomalies. L'analyse couvre plusieurs aspects du cycle de vie des données et rassemble les bonnes pratiques pour plusieurs questions qui pourraient compromettre le succès d'une application d'aide à la décision : ",
      "· Collecte et nettoyage des données",
      "· Conception du modèle de données",
      "· Disponibilité des données",
      "· Analyse de la qualité des données",
      "· Processus d'intégration des données",
      "· Maintenance de l'intégration des données",
      <br/>,
      'Parmi les outils développés, on peut citer :',
      "· Outil de journalisation de l'intégration des données",
      '· Capacités de vérification des données prenant en compte à la fois les erreurs structurelles et la validation des règles métier',
      '· Erreurs de données liées à des conflits dûs à des modifications simultanées',
      "· Outil d'analyse comparative pour suivre de manière transparente les progrès de la performance algorithmique sur différentes versions du logiciel, l'évolution de la qualité des données sur différentes versions de l'ensemble de données, ou les deux.",
      <br/>,
      "Nous avons développé une méthodologie pour traiter la complétion des données, allant d'approches les plus simples telles que la suppression et l'imputation simple (où la valeur manquante est remplacée par une moyenne ou une autre quantité statistiquement pertinente) à des approches plus complexes telles que l'estimation du maximum de vraisemblance et l'imputation multiple.",
      "Notre méthodologie est basée sur les données à classer en données structurelles, métier, d'exécution et de décision.",
      "À titre d'exemple, nous avons développé un composant de complétion de données pour le calcul de temps de parcours hors ligne, où plusieurs milliers de calculs de distance et/ou de temps de parcours sont nécessaires pour une seule optimisation, ce qui rend souvent l'utilisation de services en ligne (tels que Google Maps) prohibitive d'un point de vue tarifaire.",
      'Un autre exemple est une bibliothèque de pseudonymisation, un logiciel utilisé pour anonymiser les données sensibles (comme les informations personnelles) dans les ensembles de données qui peuvent être échangées par courrier électronique ou stockées dans un ticket de HelpDesk, conformément aux réglementations RGPD.',
      <br/>,
      "Au cours du projet AIDA, nous avons également développé un framework d'intégration des données. Ce framework est conçu autour des concepts de \"source de données\", à partir desquelles des entités du modèle peuvent être lues; et de consommateurs de source de données, qui sont permettent de traiter les entités lues. Le framework est utilisé au cœur de la plateforme pour supporter nativement l'importation et l'exportation de données de scénarios vers des fichiers Excel ou des fichiers DBRF (bruts ou compressés avec Gzip ou Zip). Plusieurs extensions de ce framework sont également publiées sous forme de bibliothèques Java pour simplifier l'intégration d'autres formats de données courants, par exemple avec des bases de données SQL. Enfin, l'API du framework peut également être utilisée pour mettre en œuvre d'autres sources de données et consommateurs de sources de données et prendre en charge n'importe quel format de données avec lequel il est nécessaire de s'intégrer. Entre autres, nous avons intégré des connecteurs pour permettre l'interopérabilité entre notre plateforme et d'autres systèmes tels que des outils de BI comme Tableau.",
    ],
    demoTitle:
      'DBGene Data Checker',
    demoDes: "",
    demoLink: 'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/DB%20Gene_%20Data%20checker_NO.mp4',
    publicationsTitles: ['Video DBGene 4.0.2', 'Video DBGene Data Checker','Data Integration API'],
    publicationsLinks: [
      'https://www.youtube.com/watch?v=rRHDT-EnPRs',
      'https://www.youtube.com/watch?v=-wsnuY1fa28',
      'https://gene-documentation-403fp2.public.decisionbrain.cloud/documentation/index.html#/data-integration/data-integration-api',
    ],
    publicationAuthors: ['', ''],
    perspectivesAuthor: '',
    perspectivesText: [ ],
    pointDecisionBrain: false,
  },
  {
    title:
      'Plateforme d’optimisation et «machine learning» pour l’aide à la décision',
    texte: [
      "Grâce au projet AIDA, nous avons pu apporter des améliorations majeures à la plateforme DecisionBrain conçue pour créer des solutions d'aide à la décision en combinant des données, des analyses et des technologies d'optimisation et d'apprentissage automatique.",
      'Ses principales caractéristiques sont les suivantes :',
      '· Réduire le temps et le coût de mise en œuvre des applications',
      "· Capacités prêtes à l'emploi pour créer des solutions métier",
      "· Une interface web moderne, qui favorise l'adoption par l'utilisateur final",
      '· Déploiement sur des clouds multiples - privé, hybride, public',
      '· Conformité aux méthodologies de développement agile',
      <br/>,
      "La plateforme DB est basée sur une architecture de micro-services pour aider les utilisateurs à construire, déployer et exécuter des solutions d'optimisation entièrement évolutives - rapidement, facilement et efficacement.",
      "Le concept derrière la plateforme DecisionBrain est de construire un cadre modulaire pour développer facilement des applications d'optimisation des décisions et de l'étendre avec des modules qui peuvent répondre à des besoins spécifiques. La plateforme est construite autour des composants principaux suivants :",
      "· Un serveur d'optimisation qui exécute localement ou à distance de multiples tâches de calcul intensif (par exemple, optimisation, apprentissage automatique, etc.) et fournit des outils administratifs pour les surveiller et les gérer.",
      "· Un service de scénarios et un service de gestion des données qui permettent à l'utilisateur de gérer des scénarios et de tester ses décisions dans l'incertitude. Les scénarios peuvent être créés à partir de données unifiées et cohérentes et peuvent être partagés pour une prise de décision collaborative. Ces composants peuvent être facilement étendus avec de nouvelles fonctionnalités personnalisées.",
      '· Des interfaces utilisateur web basées sur des composants modernes, intuitifs et faciles à utiliser ; elles tirent parti de notre expérience dans la création de solutions utilisées par les leaders mondiaux du marché. Elles peuvent également être complétées par des composants personnalisés.',
      <br/>,
      'Basée sur la technologie Docker, la plateforme peut être facilement intégrée dans les environnements informatiques des clients, que ce soit dans un cloud privé, public ou hybride.',
      "Les tâches d'optimisation étant des tâches à forte intensité de calcul, elles sont découplées du reste de l'application et s'exécutent de manière asynchrone, en allouant à la demande les ressources nécessaires. En même temps, elles peuvent être facilement monitorées et fournir des indicateurs de performance afin que l'utilisateur puisse les arrêter lorsque la solution est \"suffisamment bonne\". Le serveur d'optimisation a donc été conçu en tenant compte des exigences suivantes:",
      '· Pouvoir être appelé par des appels HTTP à une API REST',
      "· Fournir des moyens simples d'écouter les événements d'un processus en cours d'exécution",
      "· Être évolutif pour supporter n'importe quelle charge de travail, en se répartissant sur différents serveurs",
      " · Fournir une interface utilisateur simple pour voir quels processus sont en cours d'exécution",
      "· Fournir une interface utilisateur simple pour voir les détails d'un processus, y compris l'évolution des indicateurs clés de performance de la meilleure solution trouvée au fil du temps",
      "· Récupérer les résultats d'un processus en cours lorsqu'il est terminé.",
      <br/>,
      "Pour que l'application puisse évoluer, nous avons opté pour une architecture maître-esclave avec les principaux composants suivants :",
      "· Maître : agit en tant que point d'entrée et orchestrateur",
      '· Worker : application esclave dédiée au traitement des tâches à forte intensité de CPU.',
      "· Client : applications utilisées pour interagir avec le maître. Elles envoient des demandes au maître et surveillent l'exécution des tâches.",
      <br/>,
      "La communication entre le maître et les «workers» est asynchrone, de sorte que chaque «worker» peut choisir une tâche lorsqu'elle est disponible. En outre, les «workers » déclarent leurs capacités, de sorte que plusieurs «workers » peuvent prendre le même type de tâches ou des tâches différentes. La communication entre le maître et les «workers» est mise en œuvre à l'aide d'une file d'attente de messages.",
      "Pour garantir la stabilité des résultats de l'optimisation, nous avons développé un outil d'analyse comparative qui permet d'effectuer des analyses comparatives par lots et qui peut être utilisé pour suivre de manière transparente l'évolution des performances algorithmiques sur différentes versions du logiciel, l'évolution de la qualité des données sur différentes versions des ensembles de données, ou les deux à la fois.",
      "La plateforme DecisionBrain fournit des widgets de visualisation de données qui peuvent être configurés et personnalisés pour créer des tableaux de bord interactifs. Les widgets prêts à l'emploi comprennent notamment",
      '· Graphiques (tendances, radar, camembert, Gantt)',
      '· Grilles de données tabulaires',
      '· Affichage des indicateurs de performance clés (KPI)',
      '· Cartes',
      '· Calendrier',
      '· Gestion et statut des tâches',
      '· Contrôle de la navigation',
      '· Liste des problèmes / alertes et détails des problèmes',
    ],
    demoTitle:'Comparaison de scenarii',
    demoDes: '',
    demoLink: "https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/DB%20Gene_%20Scenario%20Comparison_NO.mp4",
    publicationsTitles: [
      'Video comparaison de scenarii',
      'Configurable Widgets',
      'Optimization Server Documentation',
    ],
    publicationsLinks: [
      'https://www.youtube.com/watch?v=3vbJrJLLe3E',
      'https://gene-documentation-403fp2.public.decisionbrain.cloud/documentation/index.html#/customization/gene-configurable-widgets',
      'https://dbos-documentation.public.decisionbrain.cloud/index.html#/',
    ],
    publicationAuthors: [],
    perspectivesAuthor: '',
    perspectivesText: [ ],
    pointDecisionBrain: true,
  },
  {
    title: 'Optimisation des ressources humaines',
    texte: [
      "Grâce au projet AIDA, DecisionBrain a pu apporter des améliorations majeures à sa suite d'optimisation des ressources humaines afin de l'adapter aux tâches administratives dans des secteurs tels que l'assurance, les services bancaires, les centres d'appel et le secteur public.",
      "L'objectif de cette suite d'applications est d’aider les entreprises à améliorer la productivité de leur main-d'œuvre interne et externe (sous-traitants), à améliorer le niveau de service fourni à leurs clients, et à améliorer l’engagement et la satisfaction de leurs employés.",
      "DecisionBrain a mis en place des modèles d'optimisation mathématique innovants qui permettent aux clients de résoudre des problèmes métiers de façon optimale. Tout d’abord, avec la prise en compte de contraintes et de critères complexes pour faire face à des problèmes réels de planification de personnels. Ensuite, nous avons en particulier fait des avancées significatives dans la cohérence des décisions prises à différents niveaux de planification (du stratégique au temps réel), un aspect relativement peu exploré auparavant.",
      'Notre suite est capable de répondre aux questions suivantes :',
      "· Quelle est la bonne configuration de l'effectif pour maximiser la productivité et réduire les inefficiences ?",
      '· Comment améliorer la satisfaction professionnelle des employés ?',
      '· Quel ensemble de compétences les employés devraient-ils posséder afin de capter les synergies entre les différentes lignes de service ?',
      "· Quel est le chemin d’évolution des employés qui permet à la fois aux employés de croître dans leur carrière et d'améliorer l’efficacité et la productivité des services ?",
      '· Comment assurer la conformité du niveau de service auquel on est engagé contractuellement ?',
      '· Comment lisser la charge de travail dans le temps et entre différents employés ?',
      '· Quel est le juste équilibre entre la charge de travail internalisé et le travail sous-contracté ?',
      '· Comment affecter efficacement les taches aux employés ? Quelle est la séquence des taches la plus efficace ?',
      "· Comment gérer des événements inattendus en temps réel, tels que l'arrivée de nouvelles tâches urgentes, une augmentation ou une réduction de la durée de la tâche, du retard ou de l’avance dans l’exécution des taches ?",
      '· Comment gérer au mieux les absences des employés ?',
      <br/>,
      "DecisionBrain Workforce Suite fournit des solutions d'aide à la décision pour les décisions stratégiques, tactiques et opérationnelles. Les décisions stratégiques sont des décisions qui concernent le long terme, telles que le dimensionnement et la configuration des équipes et la stratégie d'acquisition des compétences. L'outil d'aide à la décision à long terme répond à ce niveau de la suite. Les décisions opérationnelles concernent les décisions prises pour un ou plusieurs jours, comme la création, la mise à jour ou la réparation des itinéraires quotidiens des employés à la suite d'événements tels que des tâches imprévues, l'absence d'un employé ou des tâches à reprogrammer. Entre ces deux niveaux de décision, le niveau de décision tactique vise à planifier les tâches sur plusieurs semaines ou mois et à les affecter aux employés de l'équipe donnée, en envisageant éventuellement des ajustements tels que les heures supplémentaires ou la sous-traitance, ainsi que des contraintes de planification à moyen terme entre les emplois ou des contraintes de coordination des services. Les trois niveaux de décision sont interdépendants : les décisions prises aux niveaux supérieurs ont des conséquences sur la qualité des décisions prises aux niveaux inférieurs.",
      <br/>,
      'En détail, les objectifs techniques suivants ont été traités :',
      '· Optimiser l’affectation de tâches aux employés et aux sous-traitants.',
      '· Planifier le volume total de tâches sur l’année.',
      '· Optimiser la configuration d’une équipe en termes de compétences, de localisations géographiques, d’heures travaillées, etc.',
      '· Utiliser la technologie de Machine Learning pour mieux prévoir la charge de travail, à la fois à court terme et à long terme.',
      '·  Appliquer des méthodes avancées d’analytique (Machine Learning et Optimisation) en réponse à des évènements en temps réel.',
      <br/>,
      'Nous avons développé plusieurs approches de résolution pour ces problèmes, tant exactes qu’heuristiques :',
      '· Modèle de programmation par contraintes',
      '· Modèle linéaire en nombres entiers',
      '· Génération de colonnes et approche « branch and price »',
      '· Approches heuristiques',
      '· Schémas de décomposition combinés à la recherche locale basée sur le voisinage',
      <br/>,
      "Cette suite est basée sur la plateforme de DecisionBrain qui fournit les fonctionnalités nécessaires aux solutions d'aide à la décision pour des utilisateurs métiers. Par exemple, l'application est livrée avec des interfaces homme-machines, basées, par exemple sur les vues suivantes :",
      '· Gestion des scénarios de planification («what-if»)',
      '· Gestion des actions',
      '· Tableau de bord',
      '· Vue de la planification',
      '· Vue des opérateurs',
      '· Cartographie',
      '· Gestion des données',
      '· Vérificateur de données',
      <br/>,
      "Les vues permettent à l'utilisateur de visualiser, d'éditer et de vérifier les données d'entrée, de lancer une optimisation, d'analyser le plan, d'apporter des modifications et de faire des simulations.",
      "Nous décrivons ici l'un de nos clients pilotes pour notre suite Workforce Optimization, au sein du gouvernement de Singapour.",
      "Notre application (nommée IOMS) est un outil essentiel pour la gestion et l'optimisation des audits environnementaux dans les rues et les zones de Singapour.",
      "Face à des plannings fluctuants, à des critères d'inspection variés et à des événements inattendus, IOMS illustre l'application pratique de ce qui suit:",
      "· l'intégration des règles métier et de l'optimisation pour améliorer la planification des ressources humaines",
      '· la planification à différents niveaux hiérarchiques (stratégique à opérationnel) et leurs interconnexions',
      <br/>,
      "IOMS est conçu pour relever les défis complexes auxquels est confrontée l'Agence nationale de l'environnement de Singapour, une agence publique chargée d'auditer les fournisseurs de services de nettoyage. IOMS coordonne et synchronise les prévisions (« machine learning »), la planification tactique, l'allocation quotidienne des taches et le re-ordonnancement des plans.",
      "Son objectif premier est de faciliter la planification et la programmation des audits de nettoyage public, une tâche complexe qui implique de nombreux prestataires de services sous-traités par l'entreprise.",

      "Les itinéraires à inspecter ont différentes priorités qui s'adaptent à divers facteurs, notamment les fréquences de nettoyage, les zones sensibles et les événements spéciaux. Ces priorités sont définies par des règles métier, traitées par un moteur de règles métier qui permet de calculer efficacement les priorités des itinéraires.",
      'IOMS fonctionne à deux niveaux décisionnels distincts, un module tactique et un module opérationnel.',
      <br/>,
      "Au niveau tactique, la solution engendre un plan de haut niveau qui s'étend sur un horizon de neuf mois. Par la suite, en s'appuyant sur les résultats de ce plan tactique, IOMS affine son approche, en disséquant le plan sur une base mensuelle et une maille quotidienne. Ce faisant, il génère un calendrier opérationnel optimal qui attribue systématiquement les responsabilités d'audit aux agents, tout en respectant les diverses contraintes opérationnelles.",

      "L’application implémente une approche globale permettant l’alignement des décisions stratégiques, de l'optimisation les opérations quotidiennes et des aléas opérationnels. L’application permet aussi de gérer des scénarios de simulation.",

      "L'approche met l'accent sur l'adaptabilité, en tirant parti de diverses techniques de prise de décision telles que les moteurs basés sur CPLEX et les routines d'optimisation heuristique. En outre, elle intègre des prévisions d'apprentissage automatique pour estimer les capacités des employés et les demandes de charge de travail, ainsi que des règles métier pour déterminer les priorités du plan.",
       <br/>,
      "La phase d'apprentissage automatique pour les prévisions sert de base, prédisant la disponibilité des agents et les demandes d'inspection. Cette perspective pilotée par les données informe les phases de planification suivantes.",
      'Les règles métier sont utilisées pour définir différentes priorités pour les itinéraires à inspecter, qui dépendent de divers facteurs, notamment les fréquences de nettoyage, les zones sensibles et les événements spéciaux.',
      <br/>,
      "La planification opérationnelle affine les données tactiques, en les traduisant en affectations quotidiennes exploitables qui tiennent compte des horaires des agents, de leur spécialisation, des contraintes et des priorités générées par les règles métier. Un moteur d'optimisation garantit des affectations optimales des agents d'audit, en tenant compte de variables telles que le temps de déplacement et la répartition de la charge de travail.",
      "La phase de planification mensuelle s'appuie sur les plans tactiques pour créer un programme mensuel cohérent, alignant les inspections sur les horaires des prestataires de services, les fréquences de nettoyage et les événements exceptionnels. Ce plan complet prépare le terrain pour l'exécution.",
      <br/>,
      "Ce cadre s'appuie sur un moteur de planification des ressources humaines, piloté par CPLEX. Ce moteur excelle dans l'optimisation des plannings tout en tenant compte de la disponibilité des employés, des fenêtres temporelles des ordres de travail et des préférences des employés. Il respecte les contraintes opérationnelles et maximise les objectifs métier.",
      "L'exécution entraîne souvent des déviations. La réparation du plan mensuel s'effectue par des ajustements manuels et des replanifications opérationnelles afin d'adapter rapidement le plan d'allocation aux perturbations imprévues.",
      <br/>,
      "Dans le domaine opérationnel, le moteur de routage et d'ordonnancement de DecisionBrain gère les affectations de tâches en temps réel et optimise les itinéraires en intégrant précisément les temps de déplacement. Dans ce moteur, diverses approches mathématiques sont utilisées, notamment la génération de colonnes, la programmation par contraintes, l'heuristique, la programmation en nombres entiers et la recherche locale.",
      "Le flux d'intégration des données de l'application IOMS assure une synchronisation en temps réel avec les systèmes externes, y compris la disponibilité des agents, les horaires des fournisseurs de services et les données de base. L'application reste ainsi alignée sur les informations les plus récentes.",
      <br/>,
      "La transition transparente entre ces niveaux de planification et les décisions de planification est une caractéristique essentielle. Une procédure facilite la conversion des modèles de données et des solutions du moteur tactique au moteur opérationnel, en garantissant la préservation des contraintes définies au niveau tactique. Cette intégration crée un système unifié et cohérent d'optimisation des effectifs.",
    ],
    demoTitle:"L'IA pour les RHs",
    demoDes: '',
    demoLink: "https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/L'IA%20pour%20les%20RH%20-%20Livre%20blanc%20e%CC%81pisode%207_FR.mp4",
    publicationsTitles: ["Video L'IA pour les RHs", 'The Complete Guide to Workforce Optimization'],
    publicationsLinks: [
      'https://www.youtube.com/watch?v=ouZRru9tC5Q',
      'https://decisionbrain.com/the-complete-guide-to-workforce-optimization-pdf/',
    ],
    publicationAuthors: [],
    perspectivesAuthor: '',
    perspectivesText: [ ],
    pointDecisionBrain: false,
  },
];

export const theme9_en = [
  {
    title: 'Data management for optimization solutions',
    texte: [
      'Mathematical optimization methods are extremely sensitive to data availability and quality. The data collection, preparation and cleansing stages are therefore essential if optimization methods are to deliver the right solutions. More and more today, we are seeing the benefits of exploiting data external to the company and real-time data. Thanks to AIDA, DecisionBrain has evolved the services needed to collect, monitor, prepare and orchestrate the data required to execute the optimization functions of our solutions',
      'The aim is therefore to work on the data supplied as input to the solutions developed by DecisionBrain, in order to improve its quality. This involves data anomaly detection and alert tools, data completion tools, connectors and data manipulation tools.',
      'We have developed a methodology and generic tools to address issues of data quality and anomaly detection. The analysis covers several aspects of the data lifecycle and gathers best practices for several issues that could compromise the success of a decision support application: data collection and cleansing, data model design, data availability, data quality analysis, data integration process, data integration maintenance.',
      'The tools developed include',
      '- Data integration logging tool',
      '- Data verification capabilities taking into account both structural errors and business rule validation',
      '- Data errors linked to conflicts due to simultaneous modifications',
      '- Benchmarking tool to transparently track algorithmic performance progress on different versions of the software, data quality progress on different versions of the dataset, or both.',
      <br/>,
      'We have developed a methodology for dealing with data completion, ranging from the simplest approaches such as deletion and simple imputation (where the missing value is replaced by a mean or other statistically relevant quantity) to more complex approaches such as maximum likelihood estimation and multiple imputation. Our methodology is based on classifying data into structural, business, execution and decision data',
      'As an example, we have developed a data completion component for offline travel time calculations, where several thousand distance and/or travel time calculations are required for a single optimization, often making the use of online services (such as Google Maps) prohibitively expensive.',
      'Another example is a pseudonymization library, software used to anonymize sensitive data (such as personal information) in datasets that may be exchanged via email or stored in a HelpDesk ticket, in compliance with RGPD regulations.',
      <br/>,
      'During the AIDA project, we also developed a data integration framework. This framework is designed around the concepts of "data sources", from which model entities can be read; and data source consumers, which are used to process the entities read. The framework is used at the heart of the platform to natively support the import and export of scenario data to Excel or DBRF files (raw or compressed with Gzip or Zip). Several extensions to this framework are also published as Java libraries to simplify the integration of other common data formats, such as SQL databases. Finally, the framework\'s API can also be used to implement other data sources and data source consumers, and support any data format with which integration is required. Among other things, we have integrated connectors to enable interoperability between our platform and other systems such as BI tools like Tableau',
    ],
    demoTitle: 'DBGene Data Checker',
    demoDes: "",
    demoLink: 'https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/DB%20Gene_%20Data%20checker_NO.mp4',
    publicationsTitles: ['Video DBGene 4.0.2', 'Video DBGene Data Checker','Data Integration API'],
    publicationsLinks: [
      'https://www.youtube.com/watch?v=rRHDT-EnPRs',
      'https://www.youtube.com/watch?v=-wsnuY1fa28',
      'https://gene-documentation-403fp2.public.decisionbrain.cloud/documentation/index.html#/data-integration/data-integration-api',
    ],
    publicationAuthors: ['', ''],
    perspectivesAuthor: '',
    perspectivesText: [ ],
    pointDecisionBrain: false,
  },
  {
    title: 'Optimization platform and machine learning for decision support',
    texte: [
      'Thanks to the AIDA project, we have been able to make major improvements to the DecisionBrain platform designed to create decision support solutions by combining data, analytics, optimization and machine learning technologies.',
      'Its key features are:',
      '- Reduce application implementation time and cost',
      '- Ready-to-use capabilities for creating business solutions',
      '- A modern web interface, favoring end-user adoption',
      '- Deployment on multiple clouds - private, hybrid, public',
      '- compliance with agile development methodologies',
      <br/>,
      'The DB platform is based on a microservices architecture to help users build, deploy and run fully scalable optimization solutions - quickly, easily and efficiently.',
      'The concept behind the DecisionBrain platform is to build a modular framework to easily develop decision optimization applications and extend it with modules that can meet specific needs. The platform is built around the following main components:',
      '- An optimization server that runs multiple computationally intensive tasks (e.g. optimization, machine learning, etc.) locally or remotely, and provides administrative tools to monitor and manage them.',
      '- A scenario service and a data management service that enable users to manage scenarios and test their decisions under uncertainty. Scenarios can be created from unified, consistent data and shared for collaborative decision-making. These components can be easily extended with new customized functionalities',
      <br/>,
      'Web-based user interfaces based on modern, intuitive and easy-to-use components, drawing on our experience in creating solutions used by global market leaders. They can also be extended with customized components',
      "Based on Docker technology, the platform can be easily integrated into customers' IT environments, whether in a private, public or hybrid cloud.",
      'As optimization tasks are compute-intensive, they are decoupled from the rest of the application and run asynchronously, allocating the necessary resources on demand. At the same time, they can be easily monitored and provide performance indicators so that the user can stop them when the solution is "good enough". The optimization server was therefore designed with the following requirements in mind:',
      '- Be able to be called by HTTP calls to a REST API',
      '- Provide simple means of listening for events from a running process',
      '- Be scalable to support any workload, by distributing across different servers',
      '- Provide a simple user interface to see which processes are running',
      '- Provide a simple user interface to see the details of a process, including the evolution of key performance indicators of the best solution found over time.',
      '- Retrieve the results of a running process when it has finished',
      <br/>,
      'To enable the application to evolve, we opted for a master-slave architecture with the following main components:',
      '- Master: acts as entry point and orchestrator',
      '- Worker: slave application dedicated to processing CPU-intensive tasks',
      '- Client: applications used to interact with the master. They send requests to the master and monitor task execution',
      <br/>,
      'Communication between the master and the "workers" is asynchronous, so that each "worker" can choose a task when it becomes available. In addition, the "workers" declare their capacities, so that several "workers" can take on the same type of task or different tasks. Communication between the master and the "workers" is implemented using a message queue.',
      'To guarantee the stability of optimization results, we have developed a benchmarking tool that enables batch benchmarking and can be used to transparently track the evolution of algorithmic performance over different software versions, the evolution of data quality over different versions of datasets, or both.',
      'The DecisionBrain platform provides data visualization widgets that can be configured and customized to create interactive dashboards. Ready-to-use widgets include',
      '- Graphics (trends, radar, pie chart, Gantt)',
      '- Tabular data grids',
      '- Display of key performance indicators (KPIs)',
      '- Maps',
      '- Calendar',
      '- Task management and status',
      '- Navigation control',
      '- Problem list / alerts and problem details',
    ],
    demoTitle:'Scenarii comparison',
    demoDes: '',
    demoLink: "https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/DB%20Gene_%20Scenario%20Comparison_NO.mp4",
    publicationsTitles: [
      'Video comparaison de scenarii',
      'Configurable Widgets',
      'Optimization Server Documentation',
    ],
    publicationsLinks: [
      'https://www.youtube.com/watch?v=3vbJrJLLe3E',
      'https://gene-documentation-403fp2.public.decisionbrain.cloud/documentation/index.html#/customization/gene-configurable-widgets',
      'https://dbos-documentation.public.decisionbrain.cloud/index.html#/',
    ],
    publicationAuthors: [],
    perspectivesAuthor: '',
    perspectivesText: [ ],
    pointDecisionBrain: false,
  },
  {
    title: 'Optimizing human resources',
    texte: [
      'Thanks to the AIDA project, DecisionBrain has been able to make major enhancements to its human resources optimization suite to adapt it to administrative tasks in sectors such as insurance, banking, call centers and the public sector.',
      'The aim of this suite of applications is to help companies improve the productivity of their internal and external (subcontracted) workforce, enhance the level of service provided to their customers, and improve employee engagement and satisfaction.',
      'DecisionBrain has implemented innovative mathematical optimization models that enable customers to solve business problems in an optimal way. Firstly, by taking into account complex constraints and criteria to deal with real personnel planning problems. Secondly, we have made significant advances in the consistency of decisions taken at different planning levels (from strategic to real-time), an aspect relatively unexplored before.',
      'Our suite is capable of answering the following questions:',
      '- What is the right workforce configuration to maximize productivity and reduce inefficiencies?',
      '- How can employee job satisfaction be improved?',
      '- What skill set should employees possess in order to capture synergies between different service lines?',
      '- What is the employee development path that both enables employees to grow in their careers and improves departmental efficiency and productivity?',
      '- How can we ensure compliance with the level of service to which we are contractually committed?',
      '- How to smooth out the workload over time and between different employees?',
      '- What is the right balance between in-house workload and sub-contracted work?',
      '- How can tasks be efficiently assigned to employees? What is the most efficient sequence of tasks?',
      '- How to manage unexpected events in real time, such as the arrival of urgent new tasks, an increase or reduction in task duration, or a delay or advance in task execution',
      '- How best to manage employee absences',
      <br/>,
      "DecisionBrain Workforce Suite provides decision support solutions for strategic, tactical and operational decisions. Strategic decisions are decisions that concern the long term, such as team sizing and configuration, and skills acquisition strategy. The long-term decision support tool addresses this level of the suite. Operational decisions concern decisions taken for one or more days, such as creating, updating or repairing employees' daily itineraries following events such as unforeseen tasks, employee absence or tasks to be rescheduled. Between these two decision levels, the tactical decision level aims to plan tasks over several weeks or months and assign them to employees in the given team, possibly considering adjustments such as overtime or subcontracting, as well as medium-term planning constraints between jobs or departmental coordination constraints. The three decision-making levels are interdependent: decisions taken at higher levels have consequences for the quality of decisions taken at lower levels",
      'In detail, the following technical objectives were addressed:',
      '- Optimize task allocation to employees and subcontractors',
      '- Plan the total volume of tasks for the year',
      '- Optimize team configuration in terms of skills, geographical locations, hours worked, etc.',
      '- Use Machine Learning technology to better forecast workloads, both in the short and long term.',
      '- Apply advanced analytical methods (Machine Learning and Optimization) in response to real-time events.',
      <br/>,
      'We have developed several approaches to solving these problems, both exact and heuristic:',
      '- Constraint programming model',
      '- Integer linear model',
      '- Column generation and branch-and-price approach',
      '- Heuristic approaches',
      '- Decomposition schemes combined with neighborhood-based local search',
      <br/>,
      'This suite is based on the DecisionBrain platform, which provides the necessary functionality for decision support solutions for business users. For example, the application is delivered with man-machine interfaces, based, for example, on the following views:',
      '- Management of planning scenarios ("what-if")',
      '- Action management',
      '- Dashboard',
      '- Planning view',
      '- Operator view',
      '- Mapping',
      '- Data management',
      '- Data checker',
      <br/>,
      'The views allow the user to view, edit and verify input data, run an optimization, analyze the plan, make changes and run simulations.',
      'Here we describe one of our pilot customers for our Workforce Optimization suite, within the government of Singapore.',
      "Our application (named IOMS) is an essential tool for managing and optimizing environmental audits in Singapore's streets and zones. Faced with fluctuating schedules, varied inspection criteria and unexpected events, IOMS illustrates the practical application of the following.",
      '- the integration of business rules and optimization to improve human resources planning',
      '- planning at different hierarchical levels (strategic to operational) and their interconnections',
      <br/>,
      'IOMS is designed to meet the complex challenges faced by Singapore\'s National Environment Agency, a public agency responsible for auditing cleaning service providers. IOMS coordinates and synchronizes forecasting ("machine learning"), tactical planning, daily task allocation and plan rescheduling.',
      'Its primary objective is to facilitate the planning and scheduling of public cleaning audits, a complex task involving numerous service providers subcontracted by the company.',
      'The routes to be inspected have different priorities that adapt to various factors, including cleaning frequencies, sensitive areas and special events. These priorities are defined by business rules, processed by a business rules engine that enables routes to be prioritized efficiently.',
      'IOMS operates on two distinct decision-making levels, a tactical module and an operational module',
      <br/>,
      'At the tactical level, the solution generates a high-level plan that extends over a nine-month horizon. Subsequently, based on the results of this tactical plan, IOMS refines its approach, dissecting the plan on a monthly basis and a daily mesh. In doing so, it generates an optimal operational schedule that systematically assigns audit responsibilities to agents, while respecting the various operational constraints.',
      'The application implements a global approach enabling the alignment of strategic decisions, optimization of daily operations and operational contingencies. The application also enables simulation scenarios to be managed',
      'The approach emphasizes adaptability, taking advantage of various decision-making techniques such as CPLEX-based engines and heuristic optimization routines. In addition, it incorporates machine learning predictions to estimate employee capabilities and workload demands, as well as business rules to determine plan priorities.',
      'The machine learning phase for forecasting serves as the foundation, predicting agent availability and inspection requests. This data-driven perspective informs the subsequent planning phases.',
      <br/>,
      'Business rules are used to define different priorities for routes to be inspected, which depend on various factors, including cleaning frequencies, sensitive areas and special events.',
      "Operational planning refines tactical data, translating it into actionable daily assignments that take into account agents' schedules, specialization, constraints and priorities generated by business rules. An optimization engine ensures optimal audit agent assignments, taking into account variables such as travel time and workload distribution.",
      'The monthly planning phase builds on the tactical plans to create a coherent monthly program, aligning inspections with service provider schedules, cleaning frequencies and exceptional events. This comprehensive plan sets the stage for execution.',
      'This framework is supported by a human resources planning engine, driven by CPLEX. This engine excels in optimizing schedules while taking into account employee availability, work order time windows and employee preferences. It respects operational constraints and maximizes business objectives',
      'Execution often leads to deviations. The monthly plan is repaired by manual adjustments and operational replanning, to quickly adapt the allocation plan to unforeseen disruptions.',
      <br/>,
      "In the operational domain, DecisionBrain's routing and scheduling engine manages task assignments in real time and optimizes routes by precisely integrating travel times. In this engine, various mathematical approaches are used, including column generation, constraint programming, heuristics, integer programming and local search.",
      <br/>,
      "The IOMS application's data integration flow ensures real-time synchronization with external systems, including agent availability, service provider schedules and master data. This keeps the application aligned with the most up-to-date information.",
      'The seamless transition between these planning levels and planning decisions is an essential feature. A procedure facilitates the conversion of data models and solutions from the tactical engine to the operational engine, guaranteeing the preservation of constraints defined at the tactical level. This integration creates a unified and coherent workforce optimization system',
    ],
    // Removing the EN subtitled entry as not available
    //demoTitle:'White paper for Human Resources optimization',
    //demoDes: '',
    //demoLink: "https://aida-video-demos.s3.eu-de.cloud-object-storage.appdomain.cloud/L'IA%20pour%20les%20RH%20-%20Livre%20blanc%20e%CC%81pisode%20#7%20_%20Te%CC%81moignage%20de%20DecisionBrain%20et%20Vivetic%20Group_EN.mp4",
    demoTitle:'',
    demoDes: '',
    demoLink: "",
    publicationsTitles: ["Video IA for HR", 'The Complete Guide to Workforce Optimization'],
    publicationsLinks: [
      'https://www.youtube.com/watch?v=ouZRru9tC5Q',
      'https://decisionbrain.com/the-complete-guide-to-workforce-optimization-pdf/',
    ],
    publicationAuthors: [],
    perspectivesAuthor: '',
    perspectivesText: [ ],
    pointDecisionBrain: false,
  },
];
